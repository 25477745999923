// Global packages
window._ = require('lodash')

try {
  window.Popper = require('popper.js').default

  window.$ = window.jQuery = require('jquery')

  require('bootstrap')
} catch (e) {
  console.log(e.message)
}

// Request segment count check
let requestSegments = window.location.pathname.replace(/\//, '').split('/')

if (requestSegments.length < 2) {
  window.location.href = process.env.VUE_APP_URL_MAKERSPACE
}

// Token extractor
if (
  window.location.hash &&
  window.location.hash.substring(1, 11) !== 'viewpreset'
) {
  let token = window.location.hash.replace('#', '')

  if (token.includes('|')) {
    let tokenParts = token.split('|')

    if (!isNaN(parseInt(tokenParts[0]))) {
      localStorage.setItem('token', token)

      window.location.hash = ''

      history.pushState('', document.title, window.location.pathname)
    }
  }
}
