<template>
  <div>
    <!-- Font Family -->
    <div class="form-group">
      <label>{{ Lang.get('sidepanel.labels.fontfamily') }}</label>
      <v-select
        class="custom-dropdown"
        v-model="fontFamily"
        :options="fonts"
        :clearable="false"
        label="family"
        @search:focus="setInputFocussed()"
        @search:blur="setInputBlurred()"
      >
        <template #option="{ family }">
          <span :style="{ 'font-family': family }">{{ family }}</span>
        </template>
      </v-select>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <!-- Font Size -->
        <div class="form-group">
          <label>{{ Lang.get('sidepanel.labels.fontsize') }}</label>
          <v-select
            class="custom-dropdown custom-dropdown-fontsize"
            v-model="fontSize"
            :options="sizes"
            :clearable="false"
            @search:focus="setInputFocussed()"
            @search:blur="setInputBlurred()"
          ></v-select>
        </div>
      </div>
      <div class="col-lg-6">
        <!-- Text Style -->
        <div class="form-group">
          <label>{{ Lang.get('sidepanel.labels.textstyle') }}</label>
          <div class="btn-group btn-group-toggle" role="group">
            <button type="button" class="btn" :class="{ active: boldActive }" @click="boldActive = !boldActive">
              <div class="icon icon-text-bold"></div>
            </button>
            <button type="button" class="btn" :class="{ active: italicActive }" @click="italicActive = !italicActive">
              <div class="icon icon-text-cursive"></div>
            </button>
            <button
              type="button"
              class="btn"
              :class="{ active: underlineActive }"
              @click="underlineActive = !underlineActive"
            >
              <div class="icon icon-text-headline"></div>
            </button>
            <button
              type="button"
              class="btn"
              :class="{ active: linethroughActive }"
              @click="linethroughActive = !linethroughActive"
            >
              <div class="icon icon-text-strikethrough"></div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <!-- Text Align -->
        <div class="form-group">
          <label>{{ Lang.get('sidepanel.labels.textalign') }}</label>
          <div class="btn-group btn-group-toggle" role="group">
            <button type="button" class="btn" :class="{ active: textAlign === 'left' }" @click="textAlign = 'left'">
              <div class="icon icon-align-right"></div>
            </button>
            <button type="button" class="btn" :class="{ active: textAlign === 'center' }" @click="textAlign = 'center'">
              <div class="icon icon-align-center"></div>
            </button>
            <button type="button" class="btn" :class="{ active: textAlign === 'right' }" @click="textAlign = 'right'">
              <div class="icon icon-align-left"></div>
            </button>
            <button
              type="button"
              class="btn"
              :class="{ active: textAlign === 'justify-left' }"
              @click="textAlign = 'justify-left'"
            >
              <div class="icon icon-align-justify"></div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <!-- Columns -->
        <div class="form-group">
          <label>{{ Lang.get('sidepanel.labels.columns') }}</label>
          <div class="btn-group btn-group-toggle" role="group">
            <button type="button" class="btn" :class="{ active: columns === 1 }" @click="columns = 1">
              <div class="icon icon-column-1"></div>
            </button>
            <button type="button" class="btn" :class="{ active: columns === 2 }" @click="columns = 2">
              <div class="icon icon-column-2"></div>
            </button>
            <button type="button" class="btn" :class="{ active: columns === 3 }" @click="columns = 3">
              <div class="icon icon-column-3"></div>
            </button>
            <button type="button" class="btn" :class="{ active: columns === 4 }" @click="columns = 4">
              <div class="icon icon-column-4"></div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Line Height -->
    <div class="form-group form-group-range-input">
      <label>{{ Lang.get('sidepanel.labels.lineHeight') }}</label>
      <div class="row">
        <div class="col">
          <input v-model="lineHeight" type="range" min="0.5" max="1.5" step="0.1" />
          <input
            type="number"
            class="form-control"
            v-model="lineHeight"
            min="0.5"
            max="1.5"
            step="0.1"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
    </div>

    <!-- Char Spacing -->
    <div class="form-group form-group-range-input">
      <label>{{ Lang.get('sidepanel.labels.charSpacing') }}</label>
      <div class="row">
        <div class="col">
          <input v-model="charSpacing" type="range" min="-200" max="400" step="10" />
          <input
            type="number"
            class="form-control"
            v-model="charSpacing"
            min="-200"
            max="400"
            step="10"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-lg-3 pr-0">
        <div class="form-group form-group-properties">
          <label class="mb-4"
            ><strong>{{ Lang.get('subheader.items.transform') }}</strong></label
          >
          <span class="label-inline">{{ Lang.get('subheader.labels.x') }}</span>
          <input
            v-model="left"
            class="form-control"
            type="number"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
      <div class="col-lg-3 pr-0">
        <div class="form-group form-group-properties">
          <label class="mb-4">&nbsp;</label>
          <span class="label-inline">{{ Lang.get('subheader.labels.y') }}</span>
          <input
            v-model="top"
            class="form-control"
            type="number"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group form-group-properties">
          <label class="mb-4">&nbsp;</label>
          <input
            v-model="angle"
            class="form-control"
            type="number"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
          <span class="label-rotation">{{ Lang.get('sidepanel.labels.rotation') }}</span>
        </div>
      </div>
    </div>

    <div class="form-group form-group-range-input">
      <label>{{ Lang.get('subheader.items.transparency') }}</label>
      <div class="row">
        <div class="col">
          <input v-model="transparency" type="range" min="0" max="100" step="1" />
          <input
            type="number"
            class="form-control"
            v-model="transparency"
            min="0"
            max="100"
            step="1"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import utilities from '../mixins/utilities.js'
import Lang from '../utils/lang'

var FontFaceObserver = require('fontfaceobserver')

export default {
  mixins: [utilities],
  data: function () {
    return {
      Lang: Lang,
      object: {},
      muteWatchers: false,
      fontFamily: '',
      fontSize: '',
      sizes: [],
      lineHeight: '',
      charSpacing: '',
      boldActive: false,
      italicActive: false,
      underlineActive: false,
      linethroughActive: false,
      textAlign: '',
      columns: 1,
      fill: null,
      stroke: null,
      strokeWidth: 0,
      left: 0,
      top: 0,
      angle: 0,
      opacity: 0,
      transparency: 0,
    }
  },
  watch: {
    object: function () {
      if (Object.keys(this.object).length > 0) {
        this.getProperties()
      }
    },
    fontFamily: function () {
      if (!this.muteWatchers) {
        this.setFontFamily()
      }
    },
    fontSize: function () {
      if (!this.muteWatchers) {
        this.setSelectionStyles('fontSize')
      }
    },
    lineHeight: function () {
      if (!this.muteWatchers) {
        this.setProperty('lineHeight')
      }
    },
    charSpacing: function () {
      if (!this.muteWatchers) {
        this.setProperty('charSpacing')
      }
    },
    boldActive: function () {
      if (!this.muteWatchers) {
        this.setSelectionStyles('fontWeight')
      }
    },
    italicActive: function () {
      if (!this.muteWatchers) {
        this.setSelectionStyles('fontStyle')
      }
    },
    underlineActive: function () {
      if (!this.muteWatchers) {
        this.setSelectionStyles('underline')
      }
    },
    linethroughActive: function () {
      if (!this.muteWatchers) {
        this.setSelectionStyles('linethrough')
      }
    },
    textAlign: function () {
      if (!this.muteWatchers) {
        this.setProperty('textAlign')
      }
    },
    columns: function () {
      if (!this.muteWatchers) {
        this.setColumns()
      }
    },
    strokeWidth: function () {
      if (!this.muteWatchers) {
        this.setStrokeWidth()
      }
    },
    left: function () {
      if (!this.muteWatchers) {
        this.setLeft()
      }
    },
    top: function () {
      if (!this.muteWatchers) {
        this.setTop()
      }
    },
    angle: function () {
      if (!this.muteWatchers) {
        this.setAngle()
      }
    },
    opacity: function () {
      this.setTransparency()
    },
    transparency() {
      if (!this.muteWatchers) {
        this.setOpacity()
      }
    },
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
    magazine() {
      return this.$store.getters.currentMagazine
    },
    fonts() {
      return Array.from(this.$store.getters.fonts)
    },
  },
  methods: {
    getProperties() {
      if (this.object.type === 'columntextbox') {
        if (this.magazine.canvasVersion === 'linda' && this.object.fontFamily === 'Open Sans') {
          this.object.fontFamily = 'Helvetica Roman'
        }

        if (this.object.isEditing === false) {
          this.selectAllText()
        }

        var selectionStart = this.object.selectionStart
        var selectionEnd = this.object.selectionEnd

        if (selectionStart === selectionEnd) {
          selectionStart = selectionStart > 0 ? selectionStart - 1 : selectionStart
          selectionEnd = selectionStart > 0 ? selectionEnd : 1
        }

        var selectionStyles = this.object.getSelectionStyles(selectionStart, selectionEnd, true)[0]

        if (selectionStyles) {
          this.fontFamily = selectionStyles.fontFamily
          this.fontSize = selectionStyles.fontSize
          this.boldActive = selectionStyles.fontWeight === 'bold'
          this.italicActive = selectionStyles.fontStyle === 'italic'
          this.underlineActive = selectionStyles.underline
          this.linethroughActive = selectionStyles.linethrough
          this.fill = selectionStyles.fill
          this.stroke = selectionStyles.stroke
          this.strokeWidth = selectionStyles.strokeWidth

          this.textAlign = this.object.textAlign
          this.lineHeight = this.object.lineHeight
          this.charSpacing = this.object.charSpacing
          this.columns = this.object.columns

          this.left = Math.round(this.object.left)
          this.top = Math.round(this.object.top)
          this.angle = Math.round(this.object.angle)
          this.opacity = this.object.opacity
        }
      }
    },
    setFontFamily() {
      if (typeof this.fontFamily === 'object') {
        this.fontFamily = this.fontFamily.family
      }
      this.setSelectionStyles('fontFamily')
    },
    setFill() {
      this.setSelectionStyles('fill')
      if (this.object.isEditing === false) {
        this.setProperty('fill')
      }
    },
    setStroke() {
      this.setSelectionStyles('stroke')
      if (this.object.isEditing === false) {
        this.setProperty('stroke')
      }
    },
    setStrokeWidth() {
      if (this.stroke === null) {
        this.stroke = '#747474'
        this.setStroke()
      }
      if (this.strokeWidth === 0) {
        this.stroke = null
        this.setStroke()
      }
      this.setSelectionStyles('strokeWidth')
      if (this.object.isEditing === false) {
        this.setProperty('strokeWidth')
      }
    },
    setColumns() {
      if (this.object.isEditing === true) {
        this.object.exitEditing()
      }
      this.object.columns = 4
      this.canvas.requestRenderAll()
      setTimeout(() => {
        this.object.columns = this.columns
        this.canvas.requestRenderAll()
      }, 200)
      setTimeout(() => {
        this.object.initDimensions()
        this.canvas.requestRenderAll()
      }, 400)
    },
    setProperty(property) {
      this.object.set(property, this[property])
      this.canvas.requestRenderAll()
    },
    setSelectionStyles(property) {
      if (this.object.isEditing === false) {
        this.selectAllText()
      }

      var selectionStyles = {}

      switch (property) {
        case 'fontWeight':
          selectionStyles.fontWeight = this.boldActive ? 'bold' : 'normal'
          break
        case 'fontStyle':
          selectionStyles.fontStyle = this.italicActive ? 'italic' : 'normal'
          break
        case 'underline':
          selectionStyles.underline = this.underlineActive
          break
        case 'linethrough':
          selectionStyles.linethrough = this.linethroughActive
          break
        default:
          selectionStyles[property] = this[property]
      }

      this.object.setSelectionStyles(selectionStyles)

      if (this.object.isEditing === false) {
        this.deselectAllText()
      }

      this.canvas.requestRenderAll()
    },
    selectAllText() {
      this.object.selectionStart = 0
      this.object.selectionEnd = this.object.text.length
    },
    deselectAllText() {
      this.object.selectionStart = 0
      this.object.selectionEnd = 0
    },
    setLeft() {
      this.object.set('left', isNaN(parseInt(this.left)) ? 0 : parseInt(this.left))
      this.canvas.requestRenderAll()
    },
    setTop() {
      this.object.set('top', isNaN(parseInt(this.top)) ? 0 : parseInt(this.top))
      this.canvas.requestRenderAll()
    },
    setAngle() {
      this.object.set('centeredRotation', true)
      this.object.rotate(this.angle)
      this.canvas.requestRenderAll()
    },
    setTransparency() {
      this.transparency = this.convertOpacityToTransparency(this.opacity)
    },
    setOpacity() {
      this.object.opacity = this.convertTransparencyToOpacity(this.transparency)
      this.canvas.requestRenderAll()
    },
    setInputFocussed: function () {
      bus.$emit('inputFocussed', true)
    },
    setInputBlurred: function () {
      bus.$emit('inputFocussed', false)
    },
  },
  mounted() {
    this.muteWatchers = true
    this.object = this.canvas.getActiveObject()
    setTimeout(() => (this.muteWatchers = false), 500)
    bus.$on('activeObjectTypeChanged', (activeObjectType) => {
      if (activeObjectType === 'text') {
        this.muteWatchers = true
        this.object = activeObjectType !== '' ? this.canvas.getActiveObject() : {}
        setTimeout(() => (this.muteWatchers = false), 500)
      }
    })
    bus.$on('canvas:object:moving', (e) => {
      this.muteWatchers = true
      this.getProperties()
    })
    bus.$on('canvas:object:scaling', (e) => {
      this.muteWatchers = true
      this.getProperties()
    })
    bus.$on('canvas:object:rotating', (e) => {
      this.muteWatchers = true
      this.getProperties()
    })
    bus.$on('canvas:object:modified', (e) => {
      setTimeout(() => (this.muteWatchers = false), 500)
      this.getProperties()
    })
  },
  created() {
    bus.$on('text:selection:changed', (e) => {
      this.muteWatchers = true
      this.getProperties()
      setTimeout(() => (this.muteWatchers = false), 500)
    })
    bus.$on('text:changed', (e) => {
      if (this.object.text.length === 0) {
        this.object.fontWeight = this.boldActive ? 'bold' : 'normal'
        this.object.fontStyle = this.italicActive ? 'italic' : 'normal'
        this.object.underline = this.underlineActive
        this.object.linethrough = this.linethroughActive
        this.setProperty('fontFamily')
        this.setProperty('fontSize')
        this.setProperty('lineHeight')
        this.setProperty('charSpacing')
        this.setProperty('fill')
        this.setProperty('stroke')
      }
    })
    for (let i = 6; i < 401; i++) {
      this.sizes.push(i)
    }
    bus.$on('textFillChanged', (color) => {
      if (!this.muteWatchers) {
        if (this.fill !== color) {
          this.fill = color
          this.setFill()
        }
      }
    })
    bus.$on('textStrokeChanged', (color) => {
      if (!this.muteWatchers) {
        if (this.stroke !== color) {
          this.stroke = color
          this.setStroke()
        }
      }
    })
    bus.$on('textStrokeWidthChanged', (width) => {
      this.strokeWidth = parseInt(width)
    })
  },
}
</script>
