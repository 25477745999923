<template>
  <nav id="sidepanel-nav">
    <ul class="nav">
      <li
        class="nav-item"
        v-cloak
        v-for="sidePanelItem in $parent.sidePanelItems"
        v-if="
          sidePanelItem.name !== 'spine' ||
          (sidePanelItem.name == 'spine' && $parent.spineActive)
        "
        :key="sidePanelItem.name"
        :class="{
          active:
            $parent.sidePanelActive &&
            $parent.sidePanelHighlighted == sidePanelItem.name,
          disabled:
            $parent.sidePanelLocked ||
            (sidePanelItem.name !== 'spine' && $parent.spineActive),
        }"
        v-on:click="$parent.activateSidePanel(sidePanelItem.index, true)"
      >
        <a class="nav-link">
          <div :class="'icon ' + sidePanelItem.navButton.icon"></div>
          {{ Lang.get(sidePanelItem.navButton.labelLangKey) }}
        </a>
      </li>
      <li
        class="nav-item"
        v-if="$parent.sidePanelActive"
        v-on:click="$parent.sidePanelActive = false"
        v-cloak
      >
        <a class="nav-link">
          <div class="icon icon-sidebar-close"></div>
        </a>
      </li>
      <li
        class="nav-item"
        v-if="!$parent.sidePanelActive && !$parent.sidePanelLocked"
        v-on:click="$parent.activateSidePanel()"
        v-cloak
      >
        <a class="nav-link">
          <div class="icon icon-sidebar-open"></div>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'SidePanelNavigation',
  data: function () {
    return { Lang: Lang }
  },
}
</script>
