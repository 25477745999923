var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner"},[_c('mdb-spinner',{attrs:{"small":"","multicolor":""}})],1),_c('div',{staticClass:"content content-with-tabs-and-select"},[_c('div',{staticClass:"sidepanel-tabs"},[_c('a',{staticClass:"active"},[_vm._v(_vm._s(_vm.Lang.get('sidepanel.tabs.overview')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.roleForUser < 3),expression:"roleForUser < 3"}],staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"btn-group btn-group-toggle",attrs:{"role":"group"}},[_c('button',{staticClass:"btn",class:{ active: _vm.overviewType === 'pages' },attrs:{"type":"button"},on:{"click":function($event){_vm.overviewType = 'pages'}}},[_c('div',{staticClass:"icon icon-page"})]),_c('button',{staticClass:"btn",class:{ active: _vm.overviewType === 'spreads' },attrs:{"type":"button"},on:{"click":function($event){_vm.overviewType = 'spreads'}}},[_c('div',{staticClass:"icon icon-spread"})])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.overviewType === 'pages'),expression:"overviewType === 'pages'"}],staticClass:"overview-type"},[_vm._v(" "+_vm._s(_vm.Lang.get('sidepanel.tabs.overview-pages'))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.overviewType === 'spreads'),expression:"overviewType === 'spreads'"}],staticClass:"overview-type"},[_vm._v(" "+_vm._s(_vm.Lang.get('sidepanel.tabs.overview-spreads'))+" ")])])])]),_c('transition',{attrs:{"mode":"out-in"}},[(_vm.overviewType === 'pages')?_c('div',{key:"pages",staticClass:"section-scrollable overview-pages",class:'overview-' + _vm.magazine.canvasVersion},_vm._l((_vm.magazinepages),function(magazinepage,i){return _c('div',{key:magazinepage.id,staticClass:"item",class:{
            'item-active': magazinepage.id === _vm.currentMagazinepage_id,
          },on:{"click":function($event){return _vm.loadPage($event, i)}}},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-inner-wrapper"},[_c('img',{staticClass:"fluid page-thumbnail",attrs:{"src":_vm.getPageThumbnail(magazinepage)},on:{"error":function($event){return _vm.getPlaceholderThumbnail($event)}}})]),(magazinepage.type === 2 || magazinepage.type === 3)?_c('span',[_vm._v(_vm._s(magazinepage.number))]):_vm._e(),(magazinepage.type === 1)?_c('span',[_vm._v(_vm._s(_vm.Lang.get('sidepanel.misc.cover-short')))]):_vm._e(),(magazinepage.type === 4)?_c('span',[_vm._v(_vm._s(_vm.Lang.get('sidepanel.misc.backcover-short')))]):_vm._e()])])}),0):_vm._e(),(_vm.overviewType === 'spreads')?_c('div',{key:"spreads",staticClass:"section-scrollable overview-spreads",class:'overview-' + _vm.magazine.canvasVersion},_vm._l((_vm.spreads),function(magazinepages,i){return _c('div',{key:i,staticClass:"item",class:{
            'item-active':
              magazinepages[0].id === _vm.currentMagazinepage_id || magazinepages[1].id === _vm.currentMagazinepage_id,
            'cover-spread': i === 0,
          },on:{"click":function($event){return _vm.loadSpread($event, i)}}},_vm._l((magazinepages),function(magazinepage,j){return _c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-inner-wrapper"},[_c('img',{staticClass:"fluid page-thumbnail",attrs:{"src":_vm.getPageThumbnail(magazinepage)},on:{"error":function($event){return _vm.getPlaceholderThumbnail($event)}}}),(i === 0 && j === 0)?_c('img',{staticClass:"spine-thumbnail",attrs:{"src":_vm.getSpineThumbnail()},on:{"error":function($event){return _vm.getPlaceholderSpine($event)}}}):_vm._e()]),(magazinepage.type === 2 || magazinepage.type === 3)?_c('span',[_vm._v(_vm._s(magazinepage.number))]):_vm._e(),(magazinepage.type === 1)?_c('span',[_vm._v(_vm._s(_vm.Lang.get('sidepanel.misc.cover-short')))]):_vm._e(),(magazinepage.type === 4)?_c('span',[_vm._v(_vm._s(_vm.Lang.get('sidepanel.misc.backcover-short')))]):_vm._e()])}),0)}),0):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }