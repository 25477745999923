<template>
  <div>
    <div class="row">
      <div class="col-6 crop-buttons">
        <a class="btn btn-pri btn-on-dark btn-med" @click="applyCrop()">
          <div class="icon icon-ok2"></div>
          {{ Lang.get('sidepanel.buttons.crop-apply') }}
        </a>
        <br />
        <a class="btn btn-sec btn-on-dark btn-med" @click="cancelCrop()">
          <div class="icon icon-close"></div>
          {{ Lang.get('sidepanel.buttons.crop-cancel') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import utilities from '../mixins/utilities.js'
import resources from '../mixins/resources.js'
import Lang from '../utils/lang'
import httpClient from '../utils/httpClient'

export default {
  mixins: [utilities, resources],
  data: function () {
    return {
      Lang: Lang,
      instanceId: 0,
      active: false,
      object: {},
      imageBeingCropped: {},
      cropRectangle: {},
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
      lastScaleX: 0,
      lastScaleY: 0,
      lastTop: 0,
      lastLeft: 0,
    }
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
  },
  methods: {
    activateCropper() {
      this.canvas.offHistory()
      this.canvas.showHelplines = false
      this.active = true
      this.imageBeingCropped = this.object
      this.object.selectable = false
      this.object
        .set({
          top: Math.round(this.object.top),
          left: Math.round(this.object.left),
        })
        .setCoords()
      this.addCropRectangle()
      this.minX = Math.round(this.object.oCoords.tl.x)
      this.maxX = Math.round(this.object.oCoords.br.x)
      this.minY = Math.round(this.object.oCoords.tl.y)
      this.maxY = Math.round(this.object.oCoords.br.y)
      this.canvas.requestRenderAll()
    },
    deactivateCropper() {
      this.active = false
      this.removeCropRectangle()
      this.imageBeingCropped.selectable = true
      this.imageBeingCropped = {}
      this.canvas.requestRenderAll()
      this.canvas.showHelplines = true
      this.canvas.onHistory()
    },
    addCropRectangle() {
      this.cropRectangle = new fabric.Rect({
        fill: 'rgba(0,0,0,0.3)',
        top: this.object.top + 1,
        left: this.object.left + 1,
        width: (this.object.width * this.object.scaleX) / 1.5,
        height: (this.object.height * this.object.scaleY) / 1.5,
        angle: this.object.angle,
        lockRotation: true,
        objectCaching: false,
        transparentCorners: false,
        cornerSize: 8,
        padding: 0,
        cornerStyle: 'circle',
        cornerColor: 'white',
        cornerStrokeColor: 'black',
        borderColor: 'black',
        borderDashArray: [5, 5],
        borderScaleFactor: 1.5,
        hasRotatingPoint: false,
        isCropRectangle: true,
      })
      if (this.cropRectangle.angle === 0) {
        this.cropRectangle.setControlsVisibility({
          tl: true,
          mt: true,
          tr: true,
          ml: true,
          mr: true,
          mtr: false,
          bl: true,
          mb: true,
          br: true,
          panning: false,
          panningZoomIn: false,
          panningZoomOut: false,
          swappableLock: false,
          swappableUnlock: false,
          delete: false,
        })
      } else {
        this.cropRectangle.setControlsVisibility({
          tl: false,
          mt: true,
          tr: false,
          ml: true,
          mr: true,
          mtr: false,
          bl: false,
          mb: true,
          br: false,
          panning: false,
          panningZoomIn: false,
          panningZoomOut: false,
          swappableLock: false,
          swappableUnlock: false,
          delete: false,
        })
      }
      this.canvas.add(this.cropRectangle)
      this.canvas.setActiveObject(this.cropRectangle)
      this.canvas.requestRenderAll()
      this.lastScaleX = this.cropRectangle.scaleX
      this.lastScaleY = this.cropRectangle.scaleY
      this.lastTop = this.cropRectangle.top
      this.lastLeft = this.cropRectangle.left
    },
    removeCropRectangle() {
      this.canvas.remove(this.cropRectangle)
      this.canvas.requestRenderAll()
      this.cropRectangle = {}
    },
    containCropRectangle(e, action) {
      e.target
        .set({
          top: Math.round(e.target.top),
          left: Math.round(e.target.left),
        })
        .setCoords()

      this.canvas.requestRenderAll()

      if (e.target.angle == 0) {
        var boundingRect = e.target.getBoundingRect()

        // X-axis
        if (boundingRect.left < this.minX || boundingRect.left + boundingRect.width > this.maxX) {
          // Exceed left
          if (boundingRect.left < this.minX) {
            if (action == 'moving') {
              e.target.set({ left: Math.round(this.minX / this.canvas.getZoom()) }).setCoords()
            } else if (action == 'scaling') {
              e.target
                .set({
                  left: this.lastLeft,
                  scaleX: this.lastScaleX,
                })
                .setCoords()
            }
          }

          // Exceed right
          if (boundingRect.left + boundingRect.width > this.maxX) {
            if (action == 'moving') {
              e.target
                .set({
                  left: Math.round((this.maxX - boundingRect.width) / this.canvas.getZoom()),
                })
                .setCoords()
            } else if (action == 'scaling') {
              e.target
                .set({
                  left: this.lastLeft,
                  scaleX: this.lastScaleX,
                })
                .setCoords()
            }
          }
        } else {
          this.lastScaleX = e.target.scaleX
          this.lastLeft = e.target.left
        }

        // Y-axis
        if (boundingRect.top < this.minY || boundingRect.top + boundingRect.height > this.maxY) {
          // Exceed top
          if (boundingRect.top < this.minY) {
            if (action == 'moving') {
              e.target.set({ top: Math.round(this.minY / this.canvas.getZoom()) }).setCoords()
            } else if (action == 'scaling') {
              e.target
                .set({
                  top: this.lastTop,
                  scaleY: this.lastScaleY,
                })
                .setCoords()
            }
          }

          // Exceed bottom
          if (boundingRect.top + boundingRect.height > this.maxY) {
            if (action == 'moving') {
              e.target
                .set({
                  top: Math.round((this.maxY - boundingRect.height) / this.canvas.getZoom()),
                })
                .setCoords()
            } else if (action == 'scaling') {
              e.target
                .set({
                  top: this.lastTop,
                  scaleY: this.lastScaleY,
                })
                .setCoords()
            }
          }
        } else {
          this.lastScaleY = e.target.scaleY
          this.lastTop = e.target.top
        }
      }
    },
    containAngledCropRectangle(e, action) {
      if (!e.target.isContainedWithinObject(this.object)) {
        this.setCropRectangleLock(true)

        let objectCoords = this.object.getCoords()
        let objectTL = objectCoords[0]
        let objectTR = objectCoords[1]
        let objectBL = objectCoords[2]
        let objectBR = objectCoords[3]

        let cropRectangleCoords = e.target.getCoords()
        let cropRectangleTL = cropRectangleCoords[0]
        let cropRectangleTR = cropRectangleCoords[1]
        let cropRectangleBL = cropRectangleCoords[2]
        let cropRectangleBR = cropRectangleCoords[3]

        let TLContained = this.object.containsPoint(cropRectangleTL)
        let TRContained = this.object.containsPoint(cropRectangleTR)
        let BLContained = this.object.containsPoint(cropRectangleBL)
        let BRContained = this.object.containsPoint(cropRectangleBR)

        let breach = ''
        if (!TLContained && !TRContained) {
          breach = 'top'
        } else if (!TRContained && !BLContained) {
          breach = 'right'
        } else if (!BLContained && !BRContained) {
          breach = 'bottom'
        } else if (!TLContained && !BRContained) {
          breach = 'left'
        }

        var degrees = this.cropRectangle.angle
        var radians = fabric.util.degreesToRadians(degrees)
        var canvasCenter = new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2)
        var objectOrigin = new fabric.Point(this.object.left, this.object.top)
        var cropRectangleOrigin = new fabric.Point(this.cropRectangle.left, this.cropRectangle.top)
        var newObjectLocation = fabric.util.rotatePoint(objectOrigin, canvasCenter, radians * -1)
        var newCropRectangleLocation = fabric.util.rotatePoint(cropRectangleOrigin, canvasCenter, radians * -1)

        var diff = this.getBreachDiff(newObjectLocation, newCropRectangleLocation, breach)

        var correctedCropRectangle = new fabric.Rect({
          top: newCropRectangleLocation.y,
          left: newCropRectangleLocation.x,
          width: this.cropRectangle.width,
          height: this.cropRectangle.height,
          scaleX: this.cropRectangle.scaleX,
          scaleY: this.cropRectangle.scaleY,
          opacity: 0,
        })

        this.canvas.add(correctedCropRectangle)

        if (breach === 'top') {
          correctedCropRectangle.top = correctedCropRectangle.top + diff
        }

        if (breach === 'bottom') {
          correctedCropRectangle.top = correctedCropRectangle.top - diff
        }

        if (breach === 'left') {
          correctedCropRectangle.left = correctedCropRectangle.left + diff
        }

        if (breach === 'right') {
          correctedCropRectangle.left = correctedCropRectangle.left - diff
        }

        var correctedCropRectangleOrigin = new fabric.Point(correctedCropRectangle.left, correctedCropRectangle.top)
        var correctedCropRectangleDegrees = 360 - degrees
        var correctedCropRectangleRadians = fabric.util.degreesToRadians(correctedCropRectangleDegrees)
        var newCorrectedCropRectangleLocation = fabric.util.rotatePoint(
          correctedCropRectangleOrigin,
          canvasCenter,
          correctedCropRectangleRadians * -1
        )

        this.cropRectangle.set({
          top: newCorrectedCropRectangleLocation.y,
          left: newCorrectedCropRectangleLocation.x,
        })

        this.canvas.remove(correctedCropRectangle)

        setTimeout(() => {
          this.setCropRectangleLock(false)
        }, 100)
      }
    },
    setCropRectangleLock(state) {
      this.cropRectangle.set({
        lockMovementX: state,
        lockMovementY: state,
        lockScalingX: state,
        lockScalingY: state,
      })
    },
    getBreachDiff(newObjectLocation, newCropRectangleLocation, breach) {
      if (breach === 'top') {
        return newObjectLocation.y - newCropRectangleLocation.y
      } else if (breach === 'bottom') {
        return (
          newCropRectangleLocation.y -
          newObjectLocation.y +
          this.cropRectangle.height -
          this.object.height * this.object.scaleY +
          2
        )
      } else if (breach === 'left') {
        return newObjectLocation.x - newCropRectangleLocation.x
      } else if (breach === 'right') {
        return (
          newCropRectangleLocation.x -
          newObjectLocation.x +
          this.cropRectangle.width -
          this.object.width * this.object.scaleX +
          2
        )
      }
    },
    applyCrop() {
      this.$parent.$parent.loading = true

      this.cropRectangle.visible = false
      var originalAngle = this.cropRectangle.angle

      if (originalAngle !== 0) {
        var radians = fabric.util.degreesToRadians(originalAngle)
        var canvasCenter = new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2)
        var cropRectanglePoint = new fabric.Point(this.cropRectangle.oCoords.tl.x, this.cropRectangle.oCoords.tl.y)
        var objectPoint = new fabric.Point(this.object.oCoords.tl.x, this.object.oCoords.tl.y)
        var cropRectanglePointCorrected = fabric.util.rotatePoint(cropRectanglePoint, canvasCenter, radians * -1)
        var objectPointCorrected = fabric.util.rotatePoint(objectPoint, canvasCenter, radians * -1)
        var cropLeft = Math.round(
          (cropRectanglePointCorrected.x - objectPointCorrected.x) / this.canvas.getZoom() / this.object.scaleX
        )
        var cropTop = Math.round(
          (cropRectanglePointCorrected.y - objectPointCorrected.y) / this.canvas.getZoom() / this.object.scaleY
        )
      } else {
        var cropLeft = Math.round(
          (this.cropRectangle.oCoords.tl.x - this.object.oCoords.tl.x) / this.canvas.getZoom() / this.object.scaleX
        )
        var cropTop = Math.round(
          (this.cropRectangle.oCoords.tl.y - this.object.oCoords.tl.y) / this.canvas.getZoom() / this.object.scaleY
        )
      }

      var cropWidth = Math.round((this.cropRectangle.width * this.cropRectangle.scaleX) / this.object.scaleX)
      var cropHeight = Math.round((this.cropRectangle.height * this.cropRectangle.scaleY) / this.object.scaleY)

      httpClient
        .post('/editor/image/crop', {
          magazine_id: this.$store.getters.constants.magazine_id,
          imageUrl: this.object.src,
          left: cropLeft,
          top: cropTop,
          width: cropWidth,
          height: cropHeight,
        })
        .then((response) => {
          var _this = this
          var url = response.data.data.url
          new fabric.Image.fromURL(
            url,
            function (canvasImage) {
              _this.canvas.remove(_this.object)
              _this.canvas.requestRenderAll()

              canvasImage.set({
                left: _this.cropRectangle.left,
                top: _this.cropRectangle.top,
                angle: originalAngle,
                centeredScaling: false,
                centeredRotation: true,
                strokeUniform: true,
                src: url,
              })

              canvasImage.scaleToWidth(_this.cropRectangle.width * _this.cropRectangle.scaleX)
              canvasImage.setCoords()

              _this.removeCropRectangle()
              _this.canvas.add(canvasImage)
              _this.canvas.requestRenderAll()
              _this.$parent.$parent.loading = false
            },
            { crossOrigin: 'anonymous' }
          )
        })
    },
    cancelCrop() {
      this.deactivateCropper()
    },
  },
  created() {
    bus.$on('imageCropperActiveChanged', (data) => {
      if (data.instanceId === this.instanceId) {
        if (data.active && !this.active) {
          this.activateCropper()
        } else {
          this.deactivateCropper()
        }
      }
    })
  },
  mounted() {
    this.instanceId = this.$data.__ob__.dep.id
    this.$parent.$parent.imageCropperInstanceId = this.instanceId
    this.object = this.canvas.getActiveObject()
    bus.$on('activeObjectTypeChanged', (activeObjectType) => {
      this.object = activeObjectType !== '' ? this.canvas.getActiveObject() : {}
    })
    bus.$on('canvas:object:moving', (e) => {
      if (this.active) {
        if (this.cropRectangle.angle === 0) {
          this.containCropRectangle(e, 'moving')
        } else {
          this.containAngledCropRectangle(e, 'moving')
        }
      }
    })
    bus.$on('canvas:object:scaling', (e) => {
      if (this.active) {
        if (this.cropRectangle.angle === 0) {
          this.containCropRectangle(e, 'scaling')
        } else {
          this.containAngledCropRectangle(e, 'scaling')
        }
      }
    })
  },
}
</script>
