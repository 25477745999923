<template>
  <div class="wrapper">
    <div class="spinner" v-show="loading">
      <mdb-spinner small multicolor />
    </div>
    <div class="content content-with-tabs-and-select">
      <div class="sidepanel-tabs">
        <a :class="{ active: activeTab === 'clipart' }" @click="activeTab = 'clipart'">{{
          Lang.get('sidepanel.tabs.clipart')
        }}</a>
        <a :class="{ active: activeTab === 'shapes' }" @click="activeTab = 'shapes'">{{
          Lang.get('sidepanel.tabs.shapes')
        }}</a>
        <a :class="{ active: activeTab === 'masks' }" @click="activeTab = 'masks'">{{
          Lang.get('sidepanel.tabs.masks')
        }}</a>
      </div>
      <transition mode="out-in">
        <div v-if="activeTab === 'clipart'" key="clipart" class="h-100">
          <v-select
            class="custom-dropdown"
            :value="currentClipartDirectory"
            :options="clipartDirectories"
            :clearable="false"
            :searchable="false"
            label="title"
            @input="loadClipartImages"
          >
          </v-select>
          <div class="section-scrollable">
            <masonry :cols="2" :gutter="8" class="section-masonry">
              <div
                v-for="(clipartImage, i) in clipartImages"
                class="item"
                @click="addClipartImageToCanvas(clipartImage, $event.target.clientWidth, $event.target.clientHeight)"
                draggable
                @dragstart="startDrag($event, i, 'clipart')"
              >
                <div class="clipart-container">
                  <img :src="getThumbnail(clipartImage)" />
                </div>
              </div>
            </masonry>
          </div>
        </div>
        <div v-if="activeTab === 'shapes'" key="shapes" class="h-100">
          <v-select
            class="custom-dropdown"
            :value="currentShapeDirectory"
            :options="shapeDirectories"
            :clearable="false"
            :searchable="false"
            label="title"
            @input="loadShapes"
          >
          </v-select>
          <div class="section-scrollable">
            <masonry :cols="3" :gutter="8" class="section-masonry">
              <div
                v-for="(shape, i) in shapes"
                class="item item-shape"
                @click="addShapeToCanvas(shape)"
                draggable
                @dragstart="startDrag($event, i, 'shape')"
              >
                <img :src="'/images/shapes/' + shape.fileName" />
              </div>
            </masonry>
          </div>
        </div>
        <div v-if="activeTab === 'masks'" key="masks" class="h-100">
          <div class="section-scrollable">
            <masonry :cols="3" :gutter="8" class="section-masonry">
              <div
                v-for="(mask, i) in masks"
                class="item item-mask"
                @click="addMaskToCanvas(mask)"
                draggable
                @dragstart="startDrag($event, i, 'mask')"
              >
                <img :src="'/images/masks/' + mask.fileName" />
              </div>
            </masonry>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import resources from '../mixins/resources.js'
import Lang from '../utils/lang'

export default {
  mixins: [resources],
  data: function () {
    return {
      Lang: Lang,
      loading: false,
      activeTab: 'clipart',
      currentClipartDirectory: [],
      currentShapeDirectory: [],
    }
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
    clipartDirectories() {
      return this.$store.getters.clipartDirectories
    },
    clipartImages() {
      return this.$store.getters.clipartImages
    },
    shapeDirectories() {
      return this.$store.getters.shapeDirectories
    },
    shapes() {
      return this.$store.getters.shapes
    },
    masks() {
      return this.$store.getters.masks
    },
  },
  methods: {
    loadClipartImages(clipartDirectory) {
      if (typeof clipartDirectory !== 'undefined') {
        this.currentClipartDirectory = clipartDirectory
        this.loading = true
        this.getImages('clipart', clipartDirectory.id)
      }
    },
    addClipartImageToCanvas: function (clipartImage, width, height, left, top) {
      let url = this.getUrl(clipartImage)
      let _this = this

      new fabric.Image.fromURL(
        url,
        function (canvasImage) {
          canvasImage.set('src', url)
          canvasImage.set('centeredScaling', false)
          canvasImage.set('centeredRotation', true)
          canvasImage.set('strokeUniform', true)

          if (typeof left !== 'undefined' && typeof top !== 'undefined') {
            canvasImage.set('left', left)
            canvasImage.set('top', top)
          } else {
            let fabric = _this.$parent.$refs.fabric
            canvasImage.set('left', fabric.fullCanvasWidth / 2 - width / 2)
            canvasImage.set('top', fabric.fullCanvasHeight / 2 - height / 2)
          }

          canvasImage.scaleToWidth(width, true)

          const scaleX = parseFloat(canvasImage.scaleX.toFixed(2))
          const scaleY = parseFloat(canvasImage.scaleY.toFixed(2))

          canvasImage.set('scaleX', scaleX)
          canvasImage.set('scaleY', scaleY)

          _this.canvas.add(canvasImage)

          _this.canvas.requestRenderAll()
        },
        { crossOrigin: 'anonymous' }
      )
    },
    loadShapes(shapeDirectory) {
      this.currentShapeDirectory = shapeDirectory
      this.loading = true
      this.getShapes(shapeDirectory.id)
    },
    addShapeToCanvas: function (shape, left, top) {
      switch (shape.type) {
        case 'line-solid':
          var line = new fabric.Line([0, 0, 200, 0], {
            stroke: '#7C8282',
            strokeWidth: 2,
            strokeUniform: false,
            left: 200,
            top: 200,
            centeredScaling: false,
            centeredRotation: true,
          })
          this.canvas.add(line).requestRenderAll()
          break
        case 'line-dotted':
          var line = new fabric.Line([0, 0, 200, 0], {
            stroke: '#7C8282',
            strokeWidth: 2,
            strokeUniform: false,
            strokeLineCap: 'round',
            strokeLineJoin: 'round',
            strokeDashArray: [2, 6],
            left: 200,
            top: 200,
            centeredScaling: false,
            centeredRotation: true,
          })
          this.canvas.add(line).requestRenderAll()
          break
        case 'line-dashed':
          var line = new fabric.Line([0, 0, 200, 0], {
            stroke: '#7C8282',
            strokeWidth: 2,
            strokeUniform: false,
            strokeDashArray: [10, 6],
            left: 200,
            top: 200,
            centeredScaling: false,
            centeredRotation: true,
          })
          this.canvas.add(line).requestRenderAll()
          break
        default:
          let _this = this
          let object = null
          fabric.loadSVGFromString(shape.content, function (objects, options) {
            if (objects.length === 1) {
              object = objects[0]
            } else {
              object = fabric.util.groupSVGElements(objects, options)
            }

            if (typeof left === 'undefined' || typeof top === 'undefined') {
              let fabric = _this.$parent.$refs.fabric
              left = fabric.fullCanvasWidth / 2 - 75
              top = fabric.fullCanvasHeight / 2 - 75
            }

            object
              .set({
                left: left,
                top: top,
                stroke: null,
                strokeWidth: 0,
                centeredScaling: false,
                centeredRotation: true,
              })
              .setCoords()
            _this.canvas.add(object).requestRenderAll()
          })
      }
    },
    addMaskToCanvas: function (mask, left, top) {
      if (typeof left === 'undefined' || typeof top === 'undefined') {
        left = this.canvas.width / this.canvas.getZoom() / 2 - 100
        top = this.canvas.height / this.canvas.getZoom() / 2 - 100
      }
      var placeholderProperties = {
        left: left,
        top: top,
        width: 200,
        height: 200,
        mask: mask.type,
        centeredRotation: true,
        type: 'placeholder',
        fill: 'rgba(0,0,0,0.005)',
      }
      var placeholder = new fabric.Placeholder(placeholderProperties)
      this.canvas.add(placeholder).requestRenderAll()
      placeholder.enlivenPlaceholder()
    },
    startDrag: (event, id, objectType) => {
      event.dataTransfer.setData('objectType', objectType)
      event.dataTransfer.setData('objectId', id)
      event.dataTransfer.setData('objectWidth', event.target.clientWidth)
      event.dataTransfer.setData('objectHeight', event.target.clientHeight)
      event.dataTransfer.setData('grabOffsetX', event.offsetX)
      event.dataTransfer.setData('grabOffsetY', event.offsetY)
      bus.$emit('canvas:drag:start', {
        objectType: objectType,
      })
    },
    getThumbnail(image) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_clipartimages/thumbnails/' +
        image.fileName
      )
    },
    getUrl(image) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_clipartimages/' +
        image.fileName
      )
    },
  },
  created() {
    bus.$on('clipartImagesRetrieved', () => {
      this.loading = false
    })
    bus.$on('canvas:object:dropped', (data) => {
      switch (data.objectType) {
        case 'clipart':
          this.addClipartImageToCanvas(
            this.clipartImages[data.objectId],
            parseInt(data.objectWidth),
            parseInt(data.objectHeight),
            parseInt(data.dropPositionLeft),
            parseInt(data.dropPositionTop)
          )
          break
        case 'shape':
          this.addShapeToCanvas(
            this.shapes[data.objectId],
            parseInt(data.dropPositionLeft),
            parseInt(data.dropPositionTop)
          )
          break
        case 'mask':
          this.addMaskToCanvas(
            this.masks[data.objectId],
            parseInt(data.dropPositionLeft),
            parseInt(data.dropPositionTop)
          )
          break
      }
    })
  },
  mounted() {
    this.loadClipartImages(this.clipartDirectories[0])
    this.loadShapes(this.shapeDirectories[0])
  },
}
</script>
