import axios from 'axios'
import axiosRetry from 'axios-retry'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: { 'Content-Type': 'application/json' },
})

// Retry 504's
axiosRetry(httpClient, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000
  },
  retryCondition: (error) => {
    return error.response.status === 504
  },
})

// Request interceptor
httpClient.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      window.location.href = process.env.VUE_APP_URL_MAKERSPACE
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default httpClient
