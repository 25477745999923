export default {
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
  },
  watch: {
    // These keyCodes need to be overruled because FabricJS intercepts them before they reach mousetrap
    keyCode: function () {
      // Backspace and delete
      if (this.keyCode == '8' || this.keyCode == '46') {
        if (this.shortcutsEnabled()) {
          if (
            this.activeObject.type === 'placeholder' &&
            this.activeObject.hasImage
          ) {
            this.activeObject.emptyPlaceholder()
          } else {
            this.$refs.fabric.deleteObject()
          }
        }
      }

      // Up arrow
      if (this.keyCode == '38') {
        if (this.shortcutsEnabled()) {
          this.move('up')
        }
      }

      // Down arrow
      if (this.keyCode == '40') {
        if (this.shortcutsEnabled()) {
          this.move('down')
        }
      }

      // Left arrow
      if (this.keyCode == '37') {
        if (this.shortcutsEnabled()) {
          this.move('left')
        }
      }

      // Right arrow
      if (this.keyCode == '39') {
        if (this.shortcutsEnabled()) {
          this.move('right')
        }
      }

      this.keyCode = ''
    },
  },
  methods: {
    bindShortcuts: function () {
      this.$mousetrap.bind(['command+x', 'ctrl+x'], this.cut)
      this.$mousetrap.bind(['command+c', 'ctrl+c'], this.copy)
      this.$mousetrap.bind(['command+v', 'ctrl+v'], this.paste)
      this.$mousetrap.bind(['command+z', 'ctrl+z'], this.undo)
      this.$mousetrap.bind(
        ['command+y', 'ctrl+y', 'command+shift+z', 'ctrl+shift+z'],
        this.redo
      )
    },
    shortcutsEnabled: function () {
      let enabled = true

      // No object selected
      if (!Object.keys(this.activeObject).length) {
        enabled = false
      }

      // Image being cropped
      if (this.imageCropperActive) {
        enabled = false
      }

      // Input field has focus
      if (this.inputFocussed) {
        enabled = false
      }

      // Text object in edit mode
      if (this.activeObjectType === 'text') {
        if (
          this.activeObject.hasOwnProperty('isEditing') &&
          this.activeObject.isEditing
        ) {
          enabled = false
        }
      }

      return enabled
    },
    cut: function () {
      if (Object.keys(this.activeObject).length) {
        this.$refs.fabric.cutObject()
      }
      return false
    },
    copy: function () {
      if (Object.keys(this.activeObject).length) {
        this.$refs.fabric.copyObject()
      }
      return false
    },
    paste: function () {
      this.$refs.fabric.pasteObject()
    },
    undo: function () {
      this.$refs.fabric.undo()
    },
    redo: function () {
      this.$refs.fabric.redo()
    },
    move: function (direction) {
      this.$refs.fabric.moveObject(direction)
    },
  },
}
