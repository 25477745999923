<template>
  <div class="wrapper">
    <div class="spinner" v-show="loading">
      <mdb-spinner small multicolor />
    </div>
    <div class="content content-with-select">
      <v-select
        class="custom-dropdown"
        :value="currentBackgroundDirectory"
        :options="backgroundDirectories"
        :clearable="false"
        :searchable="false"
        label="title"
        @input="loadBackgroundImages"
      >
      </v-select>
      <div class="section-scrollable">
        <masonry :cols="2" :gutter="8" class="section-masonry">
          <div
            v-for="(backgroundImage, i) in backgroundImages"
            class="item"
            @click="addBackgroundImageToCanvas(backgroundImage)"
            draggable
            @dragstart="startDrag($event, i)"
          >
            <img :src="getThumbnail(backgroundImage)" />
          </div>
        </masonry>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import resources from '../mixins/resources.js'

export default {
  mixins: [resources],
  data: function () {
    return {
      loading: false,
      currentBackgroundDirectory: [],
    }
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
    backgroundDirectories() {
      return this.$store.getters.backgroundDirectories
    },
    backgroundImages() {
      return this.$store.getters.backgroundImages
    },
  },
  methods: {
    loadBackgroundImages(backgroundDirectory) {
      this.currentBackgroundDirectory = backgroundDirectory
      this.loading = true
      this.getImages('background', backgroundDirectory.id)
    },
    addBackgroundImageToCanvas: function (backgroundImage) {
      let url = this.getUrl(backgroundImage)
      var _this = this
      bus.$emit('canvasLoading', true)
      new fabric.Image.fromURL(
        url,
        function (object) {
          object.scaleToWidth(_this.$parent.$refs.fabric.fullCanvasWidth)
          _this.canvas.backgroundColor = '#ffffff'
          _this.$parent.canvasBackgroundColor = '#ffffff'
          _this.canvas.setBackgroundImage(
            object,
            function () {
              _this.canvas.requestRenderAll()
              bus.$emit('canvasLoading', false)
            },
            {
              left: 0,
              top: 0,
              originX: 'left',
              originY: 'top',
            }
          )
        },
        { crossOrigin: 'anonymous' }
      )
    },
    startDrag: (event, id) => {
      event.dataTransfer.setData('objectType', 'background')
      event.dataTransfer.setData('objectId', id)
    },
    getThumbnail(image) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_backgroundimages/thumbnails/' +
        image.fileName
      )
    },
    getUrl(image) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_backgroundimages/' +
        image.fileName
      )
    },
  },
  created() {
    bus.$on('backgroundImagesRetrieved', () => {
      this.loading = false
    })
    bus.$on('canvas:object:dropped', (dataTransfer) => {
      var objectType = dataTransfer.getData('objectType')
      var objectId = parseInt(dataTransfer.getData('objectId'))
      if (objectType == 'background') {
        this.addBackgroundImageToCanvas(this.backgroundImages[objectId])
      }
    })
  },
  mounted() {
    this.loadBackgroundImages(this.backgroundDirectories[0])
  },
}
</script>
