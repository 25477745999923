<template>
  <div>
    <!-- Presets -->
    <div class="shadow-presets">
      <div class="shadow-preset shadow-preset-none" @click="usePreset('none')"></div>
      <div class="shadow-preset shadow-preset-top-left" @click="usePreset('topLeft')"></div>
      <div class="shadow-preset shadow-preset-top-right" @click="usePreset('topRight')"></div>
      <div class="shadow-preset shadow-preset-bottom-right" @click="usePreset('bottomRight')"></div>
      <div class="shadow-preset shadow-preset-bottom-left" @click="usePreset('bottomLeft')"></div>
      <div class="shadow-preset shadow-preset-glow" @click="usePreset('glow')"></div>
      <div class="shadow-preset shadow-preset-top" @click="usePreset('top')"></div>
      <div class="shadow-preset shadow-preset-right" @click="usePreset('right')"></div>
      <div class="shadow-preset shadow-preset-bottom" @click="usePreset('bottom')"></div>
      <div class="shadow-preset shadow-preset-left" @click="usePreset('left')"></div>
    </div>

    <!-- Transparency -->
    <div class="form-group">
      <label>{{ Lang.get('sidepanel.labels.transparency') }}</label>
      <input v-model="transparency" type="range" min="0" max="100" step="1" />
    </div>

    <!-- OffsetX -->
    <div class="form-group">
      <label>{{ Lang.get('sidepanel.labels.offset-x') }}</label>
      <input v-model="offsetX" type="range" min="-25" max="25" step="1" />
    </div>

    <!-- OffsetY -->
    <div class="form-group">
      <label>{{ Lang.get('sidepanel.labels.offset-y') }}</label>
      <input v-model="offsetY" type="range" min="-25" max="25" step="1" />
    </div>

    <!-- Blur -->
    <div class="form-group">
      <label>{{ Lang.get('sidepanel.labels.filters.blur') }}</label>
      <input v-model="blur" type="range" min="0" max="50" step="1" />
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import utilities from '../mixins/utilities.js'
import Lang from '../utils/lang'

export default {
  mixins: [utilities],
  data: function () {
    return {
      Lang: Lang,
      object: {},
      shadow: {
        affectStroke: false,
        includeDefaultValues: true,
        nonScaling: true,
        color: 'rgba(0,0,0,0.5)',
        blur: 10,
        offsetX: 5,
        offsetY: 5,
      },
      presets: {
        topLeft: { offsetX: -5, offsetY: -5 },
        topRight: { offsetX: 5, offsetY: -5 },
        bottomLeft: { offsetX: -5, offsetY: 5 },
        bottomRight: { offsetX: 5, offsetY: 5 },
        top: { offsetX: 0, offsetY: -5 },
        right: { offsetX: 5, offsetY: 0 },
        bottom: { offsetX: 0, offsetY: 5 },
        left: { offsetX: -5, offsetY: 0 },
        glow: { offsetX: 0, offsetY: 0 },
        none: { offsetX: 0, offsetY: 0 },
      },
      colorHex: '#000000',
      colorRgb: 'rgb(0,0,0)',
      colorRgba: 'rgb(0,0,0,0.5)',
      transparency: 50,
      opacity: 50,
      blur: 10,
      offsetX: 5,
      offsetY: 5,
    }
  },
  watch: {
    object: function () {
      if (Object.keys(this.object).length > 0) {
        if (this.object.hasOwnProperty('shadow')) {
          //this.getProperties();
        }
      }
    },
    colorHex: function () {
      this.setShadowColor()
    },
    transparency: function () {
      this.setShadowColor()
    },
    blur: function () {
      this.setShadowBlur()
    },
    offsetX: function () {
      this.setShadowOffset()
    },
    offsetY: function () {
      this.setShadowOffset()
    },
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
  },
  methods: {
    getProperties() {
      this.shadow.color = this.object.shadow.color
      this.colorRgba = this.shadow.color
      this.colorHex = this.convertRgbToHex(this.colorRgba)
      this.colorRgb = this.convertHexToRgb(this.colorHex)
      this.opacity = this.getRgbOpacity(this.colorRgba)
      this.transparency = this.convertOpacityToTransparency(this.opacity)
    },
    setShadow() {
      this.object.set('shadow', this.shadow)
      this.canvas.requestRenderAll()
      this.canvas.fire('object:modified')
    },
    setShadowColor() {
      this.opacity = this.convertTransparencyToOpacity(this.transparency)
      this.colorRgb = this.convertHexToRgb(this.colorHex)
      this.colorRgba = this.setRgbOpacity(this.colorRgb, this.opacity)
      this.shadow.color = this.colorRgba
      this.setShadow()
    },
    setShadowBlur() {
      this.shadow.blur = this.blur
      this.setShadow()
    },
    setShadowOffset() {
      this.shadow.offsetX = this.offsetX
      this.shadow.offsetY = this.offsetY
      this.setShadow()
    },
    usePreset(preset) {
      this.offsetX = this.presets[preset].offsetX
      this.offsetY = this.presets[preset].offsetY
      this.shadow.offsetX = this.offsetX
      this.shadow.offsetY = this.offsetY
      this.transparency = preset == 'none' ? 100 : 50
      this.setShadow()
    },
    setObject() {
      let activeObject = this.canvas.getActiveObject()

      if (activeObject && activeObject.type === 'placeholder' && activeObject.hasImage) {
        this.object = activeObject.image
      } else {
        this.object = activeObject
      }
    },
  },
  mounted() {
    this.setObject()
    bus.$on('activeObjectTypeChanged', (activeObjectType) => {
      if (activeObjectType !== '') {
        this.setObject()
      } else {
        this.object = {}
      }
    })
  },
}
</script>
