<template>
  <div>
    <div class="color-preset"
         :class="{ 'last-of-row': lastOfRow }"
         :style="$parent.getSwatchStyle(presetColor)"
         @click="$parent.usePreset(presetColor)"
         v-if="presetColor !== $parent.color"
    >
    </div>
    <div class="color-preset-active"
         :class="{ 'last-of-row': lastOfRow }"
         v-if="presetColor === $parent.color"
    >
      <div :style="$parent.getSwatchStyle(presetColor)"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    presetColor: String,
    lastOfRow: Boolean,
  },
};
</script>
