<template>
  <div>
    <b-modal
      ref="tour"
      centered
      size="lg"
      modal-class="modal-tour"
      no-close-on-backdrop
      no-close-on-escape
      hide-header
      hide-footer
    >
      <div class="icon icon-close" @click="closeModal()"></div>
      <div class="slide-intro" v-show="activeSlide === 'intro'">
        <div class="beam"></div>
        <h1>{{ Lang.get('modals.tour.intro-header') }}</h1>
        <img :src="'/images/tour/intro-' + canvasVersion + '.png'" />
        <p>
          {{ Lang.get('modals.tour.intro-text-' + canvasVersion) }}
          <a href="#" @click="openTourVideoModal()" v-show="canvasVersion === 'linda'">
            {{ Lang.get('modals.tour.intro-video-text-' + canvasVersion) }}
          </a>
        </p>
        <a class="btn btn-acc btn-on-light btn-lar" @click="goToSlide('step-1')">{{
          Lang.get('modals.tour.button-start')
        }}</a>
      </div>
      <div class="slide-step" v-show="activeSlide === 'step-1'">
        <div class="beam">
          <h2>{{ Lang.get('modals.tour.step-1-header') }}</h2>
          <p>{{ Lang.get('modals.tour.step-1-text') }}</p>
        </div>
        <img :src="'/images/tour/step-1-' + canvasVersion + '.gif'" />
        <div class="step-footer">
          <a class="btn btn-ter btn-ter-bordered btn-on-light btn-lar float-left invisible">{{
            Lang.get('modals.tour.button-previous')
          }}</a>
          <span>1 {{ Lang.get('modals.tour.step-of') }} {{ slideCount }}</span>
          <a class="btn btn-acc btn-on-light btn-lar float-right" @click="goToSlide('step-2')">{{
            Lang.get('modals.tour.button-next')
          }}</a>
        </div>
      </div>
      <div class="slide-step" v-show="activeSlide === 'step-2'">
        <div class="beam">
          <h2>{{ Lang.get('modals.tour.step-2-header') }}</h2>
          <p>{{ Lang.get('modals.tour.step-2-text') }}</p>
        </div>
        <img :src="'/images/tour/step-2-' + canvasVersion + '.gif'" />
        <div class="step-footer">
          <a class="btn btn-ter btn-ter-bordered btn-on-light btn-lar float-left" @click="goToSlide('step-1')">{{
            Lang.get('modals.tour.button-previous')
          }}</a>
          <span>2 {{ Lang.get('modals.tour.step-of') }} {{ slideCount }}</span>
          <a class="btn btn-acc btn-on-light btn-lar float-right" @click="goToSlide('step-3')">{{
            Lang.get('modals.tour.button-next')
          }}</a>
        </div>
      </div>
      <div class="slide-step" v-show="activeSlide === 'step-3'">
        <div class="beam">
          <h2>{{ Lang.get('modals.tour.step-3-header') }}</h2>
          <p>{{ Lang.get('modals.tour.step-3-text') }}</p>
        </div>
        <img :src="'/images/tour/step-3-' + canvasVersion + '.gif'" />
        <div class="step-footer">
          <a class="btn btn-ter btn-ter-bordered btn-on-light btn-lar float-left" @click="goToSlide('step-2')">{{
            Lang.get('modals.tour.button-previous')
          }}</a>
          <span>3 {{ Lang.get('modals.tour.step-of') }} {{ slideCount }}</span>
          <a class="btn btn-acc btn-on-light btn-lar float-right" @click="goToSlide('step-4')">{{
            Lang.get('modals.tour.button-next')
          }}</a>
        </div>
      </div>
      <div class="slide-step" v-show="activeSlide === 'step-4'">
        <div class="beam">
          <h2>{{ Lang.get('modals.tour.step-4-header') }}</h2>
          <p>{{ Lang.get('modals.tour.step-4-text') }}</p>
        </div>
        <img :src="'/images/tour/step-4-' + canvasVersion + '.gif'" />
        <div class="step-footer">
          <a class="btn btn-ter btn-ter-bordered btn-on-light btn-lar float-left" @click="goToSlide('step-3')">{{
            Lang.get('modals.tour.button-previous')
          }}</a>
          <span>4 {{ Lang.get('modals.tour.step-of') }} {{ slideCount }}</span>
          <a
            v-if="canvasVersion === 'default'"
            class="btn btn-acc btn-on-light btn-lar float-right"
            @click="closeModal()"
            >{{ Lang.get('modals.tour.button-finish') }}</a
          >
          <a
            v-if="canvasVersion === 'linda'"
            class="btn btn-acc btn-on-light btn-lar float-right"
            @click="goToSlide('step-5')"
            >{{ Lang.get('modals.tour.button-next') }}</a
          >
        </div>
      </div>
      <div class="slide-step" v-show="activeSlide === 'step-5'">
        <div class="beam">
          <h2>{{ Lang.get('modals.tour.step-5-header') }}</h2>
          <p>{{ Lang.get('modals.tour.step-5-text') }}</p>
        </div>
        <img :src="'/images/tour/step-5-' + canvasVersion + '.gif'" />
        <div class="step-footer">
          <a class="btn btn-ter btn-ter-bordered btn-on-light btn-lar float-left" @click="goToSlide('step-4')">{{
            Lang.get('modals.tour.button-previous')
          }}</a>
          <span>5 {{ Lang.get('modals.tour.step-of') }} {{ slideCount }}</span>
          <a class="btn btn-acc btn-on-light btn-lar float-right" @click="closeModal()">{{
            Lang.get('modals.tour.button-finish')
          }}</a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'TourModal',
  props: { canvasVersion: String },
  data: function () {
    return {
      Lang: Lang,
      activeSlide: 'intro',
      slideCount: 0,
      introImageSrc: '',
    }
  },
  methods: {
    closeModal() {
      localStorage.setItem('editor-tour-finished', 1)
      this.$refs.tour.hide()
      bus.$emit('tourFinished', true)
    },
    goToSlide(slide) {
      this.activeSlide = slide
    },
    openTourVideoModal() {
      this.$parent.$refs.tourVideoModal.openModal()
    },
  },
  mounted() {
    bus.$on('canvasInitialised', () => {
      let tourFinished = localStorage.getItem('editor-tour-finished')
      if (!tourFinished) {
        this.$refs.tour.show()
      }
      this.slideCount = this.canvasVersion === 'linda' ? 5 : 4
    })
  },
}
</script>
