<template>
  <div class="wrapper">
    <div class="spinner" v-show="loading">
      <mdb-spinner small multicolor />
    </div>
    <div class="content content-with-tabs-and-select">
      <div class="sidepanel-tabs">
        <a class="active">{{ Lang.get('sidepanel.tabs.overview') }}</a>
      </div>
      <div class="row" v-show="roleForUser < 3">
        <div class="col">
          <div class="form-group">
            <div class="btn-group btn-group-toggle" role="group">
              <button
                type="button"
                class="btn"
                :class="{ active: overviewType === 'pages' }"
                @click="overviewType = 'pages'"
              >
                <div class="icon icon-page"></div>
              </button>
              <button
                type="button"
                class="btn"
                :class="{ active: overviewType === 'spreads' }"
                @click="overviewType = 'spreads'"
              >
                <div class="icon icon-spread"></div>
              </button>
            </div>
            <span class="overview-type" v-show="overviewType === 'pages'">
              {{ Lang.get('sidepanel.tabs.overview-pages') }}
            </span>
            <span class="overview-type" v-show="overviewType === 'spreads'">
              {{ Lang.get('sidepanel.tabs.overview-spreads') }}
            </span>
          </div>
        </div>
      </div>
      <transition mode="out-in">
        <div
          class="section-scrollable overview-pages"
          :class="'overview-' + magazine.canvasVersion"
          v-if="overviewType === 'pages'"
          key="pages"
        >
          <div
            class="item"
            v-for="(magazinepage, i) in magazinepages"
            :key="magazinepage.id"
            @click="loadPage($event, i)"
            :class="{
              'item-active': magazinepage.id === currentMagazinepage_id,
            }"
          >
            <div class="item-inner">
              <div class="item-inner-wrapper">
                <img
                  class="fluid page-thumbnail"
                  :src="getPageThumbnail(magazinepage)"
                  @error="getPlaceholderThumbnail($event)"
                />
              </div>
              <span v-if="magazinepage.type === 2 || magazinepage.type === 3">{{ magazinepage.number }}</span>
              <span v-if="magazinepage.type === 1">{{ Lang.get('sidepanel.misc.cover-short') }}</span>
              <span v-if="magazinepage.type === 4">{{ Lang.get('sidepanel.misc.backcover-short') }}</span>
            </div>
          </div>
        </div>
        <div
          class="section-scrollable overview-spreads"
          :class="'overview-' + magazine.canvasVersion"
          v-if="overviewType === 'spreads'"
          key="spreads"
        >
          <div
            class="item"
            v-for="(magazinepages, i) in spreads"
            :key="i"
            @click="loadSpread($event, i)"
            :class="{
              'item-active':
                magazinepages[0].id === currentMagazinepage_id || magazinepages[1].id === currentMagazinepage_id,
              'cover-spread': i === 0,
            }"
          >
            <div class="item-inner" v-for="(magazinepage, j) in magazinepages">
              <div class="item-inner-wrapper">
                <img
                  class="fluid page-thumbnail"
                  :src="getPageThumbnail(magazinepage)"
                  @error="getPlaceholderThumbnail($event)"
                />
                <img
                  class="spine-thumbnail"
                  :src="getSpineThumbnail()"
                  @error="getPlaceholderSpine($event)"
                  v-if="i === 0 && j === 0"
                />
              </div>
              <span v-if="magazinepage.type === 2 || magazinepage.type === 3">{{ magazinepage.number }}</span>
              <span v-if="magazinepage.type === 1">{{ Lang.get('sidepanel.misc.cover-short') }}</span>
              <span v-if="magazinepage.type === 4">{{ Lang.get('sidepanel.misc.backcover-short') }}</span>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import resources from '../mixins/resources.js'
import Lang from '../utils/lang'

export default {
  mixins: [resources],
  data() {
    return {
      Lang: Lang,
      loading: false,
      overviewType: 'pages',
      currentMagazinepage_id: this.$store.getters.constants.magazinepage_id,
    }
  },
  computed: {
    magazinepages() {
      return this.$store.getters.currentMagazine.canvasses
    },
    magazine() {
      return this.$store.getters.currentMagazine
    },
    roleForUser() {
      return this.$store.getters.roleForUser
    },
    spreads() {
      var spreads = []
      var spread = []

      this.magazinepages.forEach((magazinepage, i) => {
        if (i % 2) {
          spread = []
          spread.push(magazinepage)
        } else {
          if (i === 0) {
            spread.push(this.magazinepages[this.magazinepages.length - 1])
          }
          spread.push(magazinepage)
          spreads.push(spread)
        }
      })

      return spreads
    },
  },
  methods: {
    loadPage(event, i) {
      let location =
        '/' + this.$store.getters.constants.magazine_id + '/' + this.magazinepages[i].id + '#viewpreset=overview'
      this.$parent.saveCanvasAndRedirect(location)
    },
    loadSpread(event, i) {
      let location =
        '/' +
        this.$store.getters.constants.magazine_id +
        '/' +
        this.spreads[i][0].id +
        '/' +
        this.spreads[i][1].id +
        '/left#viewpreset=overview-' +
        this.overviewType
      this.$parent.saveCanvasAndRedirect(location)
    },
    getPageThumbnail(magazinepage) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_magazinepages/' +
        this.magazine.id +
        '/png_thumbnail/' +
        magazinepage.id +
        '.png'
      )
    },
    getPlaceholderThumbnail(e) {
      e.target.src = '/images/page-placeholder.png'
      e.target.className = 'placeholder page-thumbnail'
    },
    getSpineThumbnail() {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_magazinepages/' +
        this.magazine.id +
        '/png_thumbnail/spine.png'
      )
    },
    getPlaceholderSpine(e) {
      e.target.src = '/images/spine-placeholder.png'
      e.target.className = 'placeholder spine-thumbnail'
    },
  },
  created() {
    bus.$on('overviewTypeChanged', (overviewType) => {
      this.overviewType = overviewType
    })
  },
}
</script>
