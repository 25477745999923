var FontFaceObserver = require('fontfaceobserver')

import { bus } from '../utils/bus'
import Lang from '../utils/lang'
import httpClient from '../utils/httpClient'

export default {
  methods: {
    getResources: function () {
      Promise.all([
        this.getUser(),
        this.getCurrentMagazine(),
        this.getCurrentMagazinepage(),
        this.getCurrentMagazinepageExtra(),
        this.getCurrentMagazinespine(),
        this.getFonts(),
        this.getImageDirectories('custom'),
        this.getShapeDirectories(),
        this.getMasks(),
        this.getPdfDocuments(),
      ])
        .then(() => {
          this.getMagazines().then(async () => {
            this.getTemplates()
            this.getImageDirectories('background')
            this.getImageDirectories('clipart')
            this.setLocale()
            this.getSettings()
            this.preloadFonts()
            this.$emit('resourcesLoaded', true)
          })
        })
        .catch((error) => {
          console.log(error.message)
        })
    },
    getUser: function () {
      return httpClient
        .get('/editor/canvas/' + this.$store.getters.constants.magazinepage_id + '/role')
        .then((response) => {
          this.$store.commit('setUser', response.data.data.maker)
          this.$store.commit('setRoleForUser', response.data.data.role_id)
        })
    },
    getCurrentMagazine: function () {
      return httpClient
        .get('/editor/creation/' + this.$store.getters.constants.magazine_id + '?withCanvasses=1')
        .then((response) => {
          this.$store.commit('setCurrentMagazine', response.data.data)
        })
    },
    getCurrentMagazinepage: function () {
      return httpClient.get('/editor/canvas/' + this.$store.getters.constants.magazinepage_id).then((response) => {
        this.$store.commit('setCurrentMagazinepage', response.data.data)
      })
    },
    getCurrentMagazinepageExtra: function () {
      if (this.editorType == 'spread') {
        return httpClient
          .get('/editor/canvas/' + this.$store.getters.constants.magazinepage_id_extra)
          .then((response) => {
            this.$store.commit('setCurrentMagazinepageExtra', response.data.data)
          })
      } else {
        return true
      }
    },
    getCurrentMagazinespine: function () {
      return httpClient
        .get('/editor/creation/' + this.$store.getters.constants.magazine_id + '/spine/')
        .then((response) => {
          this.$store.commit('setCurrentMagazinespine', response.data.data)
        })
    },
    getFonts: function () {
      return httpClient
        .get('/editor/font', {
          params: { magazine_id: this.$store.getters.constants.magazine_id },
        })
        .then((response) => {
          this.$store.commit('setFonts', response.data.data)
        })
    },
    setLocale: function () {
      Lang.setLocale(this.$store.getters.user.locale)
    },
    getSettings: function () {
      return httpClient
        .get('/system/settings/actual', {
          params: { token: process.env.VUE_APP_SETTINGS_KEY },
        })
        .then((response) => {
          this.$store.commit('setSettings', response.data.data)
          this.checkMaintenanceMode()
          //this.checkSystemMessage();
        })
    },
    preloadFonts: function () {
      return Promise.all([
        this.$store.getters.fonts.map(function (font) {
          if (font.hasRegularStyle) {
            return new FontFaceObserver(font.family, {
              weight: 'normal',
              style: 'normal',
            })
              .load(null, 10000)
              .catch((error) => {
                console.log('FontFaceObserver error:', error.message)
              })
          } else {
            Promise.resolve(true)
          }
        }),
        this.$store.getters.fonts.map(function (font) {
          if (font.hasBoldStyle) {
            return new FontFaceObserver(font.family, {
              weight: 'bold',
              style: 'normal',
            })
              .load(null, 10000)
              .catch((error) => {
                console.log('FontFaceObserver error:', error.message)
              })
          } else {
            Promise.resolve(true)
          }
        }),
        this.$store.getters.fonts.map(function (font) {
          if (font.hasItalicStyle) {
            return new FontFaceObserver(font.family, {
              weight: 'normal',
              style: 'italic',
            })
              .load(null, 10000)
              .catch((error) => {
                console.log('FontFaceObserver error:', error.message)
              })
          } else {
            Promise.resolve(true)
          }
        }),
        this.$store.getters.fonts.map(function (font) {
          if (font.hasBoldItalicStyle) {
            return new FontFaceObserver(font.family, {
              weight: 'bold',
              style: 'italic',
            })
              .load(null, 10000)
              .catch((error) => {
                console.log('FontFaceObserver error:', error.message)
              })
          } else {
            Promise.resolve(true)
          }
        }),
      ]).catch((error) => {
        console.log('FontFaceObserver error:', error.message)
      })
    },
    getMagazines: function () {
      return httpClient
        .get('/editor/creation', {
          params: {
            canvasVersion: this.$store.getters.currentMagazine.canvasVersion,
          },
        })
        .then((response) => {
          this.$store.commit('setMagazines', response.data.data)
        })
    },
    getTemplates: function () {
      return httpClient
        .get('/editor/template', {
          params: {
            canvasVersion: this.$store.getters.currentMagazine.canvasVersion,
          },
        })
        .then((response) => {
          this.$store.commit('setTemplates', response.data.data)
        })
    },
    getImageDirectories: function (type) {
      return httpClient
        .get('/editor/imagefolder', {
          params: {
            type: type,
            creationType: type !== 'custom' ? this.$store.getters.currentMagazine.canvasVersion : null,
            magazine_id: this.$store.getters.constants.magazine_id,
          },
        })
        .then((response) => {
          switch (type) {
            case 'custom':
              this.$store.commit('setImageDirectories', response.data.data)
              break
            case 'clipart':
              this.$store.commit('setClipartDirectories', response.data.data)
              break
            case 'background':
              this.$store.commit('setBackgroundDirectories', response.data.data)
              break
          }
        })
    },
    getShapeDirectories: function () {
      return httpClient.get('/editor/shapefolder').then((response) => {
        this.$store.commit('setShapeDirectories', response.data.data)
      })
    },
    getMasks: function () {
      return httpClient.get('/editor/mask').then((response) => {
        this.$store.commit('setMasks', response.data.data)
      })
    },
    getPdfDocuments: function () {
      return httpClient
        .get('/editor/document', {
          params: {
            type: 'pdf',
            magazine_id: this.$store.getters.constants.magazine_id,
          },
        })
        .then((response) => {
          let pdfDocuments = response.data.data
          if (Object.keys(pdfDocuments).length) {
            for (const [key, pdfDocument] of Object.entries(pdfDocuments)) {
              if (!Object.keys(pdfDocument.documentpages).length) {
                delete pdfDocuments[key]
              }
            }
          }
          this.$store.commit(
            'setPdfDocuments',
            pdfDocuments.filter((e) => e)
          )
        })
    },
    getImages: function (type, imageDirectory_id) {
      let params = { type: type }
      if (type === 'custom') {
        params = {
          ...params,
          ...{ magazine_id: this.$store.getters.constants.magazine_id },
        }
      }
      return httpClient
        .get('/editor/imagefolder/' + imageDirectory_id + '/images', {
          params: params,
        })
        .then((response) => {
          switch (type) {
            case 'custom':
              this.$store.commit('setImages', response.data.data)
              bus.$emit('imagesRetrieved', imageDirectory_id)
              break
            case 'clipart':
              this.$store.commit('setClipartImages', response.data.data)
              bus.$emit('clipartImagesRetrieved', imageDirectory_id)
              break
            case 'background':
              this.$store.commit('setBackgroundImages', response.data.data)
              bus.$emit('backgroundImagesRetrieved', imageDirectory_id)
              break
          }
        })
    },
    getShapes: function (shapeDirectory_id) {
      return httpClient.get('/editor/shapefolder/' + shapeDirectory_id + '/shapes').then((response) => {
        this.$store.commit('setShapes', response.data.data)
        bus.$emit('shapesRetrieved', shapeDirectory_id)
      })
    },
    getPdfDocumentPages: function (document_id) {
      return httpClient.get('/editor/document/' + document_id + '/pages').then((response) => {
        this.$store.commit('setPdfDocumentPages', response.data.data)
        bus.$emit('pdfDocumentPagesRetrieved', document_id)
      })
    },
    getTemplatepages: function (template_id) {
      return httpClient.get('/editor/template/' + template_id).then((response) => {
        this.$store.commit('setTemplatepages', response.data.data.canvasses)
        bus.$emit('templatepagesRetrieved', template_id)
      })
    },
    getMagazinepages: function (magazine_id) {
      return httpClient.get('/editor/creation/' + magazine_id + '?withCanvasses=1').then((response) => {
        this.$store.commit('setMagazinepages', response.data.data.canvasses)
        bus.$emit('magazinepagesRetrieved', magazine_id)
      })
    },
    getTemplatepageContent: function (template_id, templatepage_id) {
      return httpClient.get('/editor/template/' + template_id + '/canvas/' + templatepage_id).then((response) => {
        this.$store.commit('setTemplatepageContent', response.data.data.content)
        bus.$emit('templatepageContentRetrieved', templatepage_id)
      })
    },
    getMagazinepageContent: function (magazine_id, magazinepage_id) {
      return httpClient.get('/editor/canvas/' + magazinepage_id).then((response) => {
        this.$store.commit('setMagazinepageContent', response.data.data.content)
        bus.$emit('magazinepageContentRetrieved', magazinepage_id)
      })
    },
    resCreateImageDirectory: function () {
      httpClient
        .post('/editor/imagefolder', {
          type: 'custom',
          magazine_id: this.$store.getters.constants.magazine_id,
          title: Lang.get('sidepanel.misc.new-imagefolder'),
        })
        .then((response) => {
          this.$store.commit('addImageDirectory', response.data.data)
          setTimeout(() => bus.$emit('imageDirectoryCreated', response.data.data.id), 500)
        })
    },
    resUpdateImageDirectory: function (index, imageDirectory) {
      httpClient
        .put('/editor/imagefolder/' + imageDirectory.id, {
          type: 'custom',
          magazine_id: this.$store.getters.constants.magazine_id,
          title: imageDirectory.title,
        })
        .then((response) => {
          this.$store.commit('setImageDirectory', {
            index: index,
            imageDirectory: imageDirectory,
          })
          bus.$emit('imageDirectoryUpdated', imageDirectory.id)
        })
    },
    resDeleteImageDirectory: function (index) {
      var id = this.$store.getters.imageDirectories[index].id
      httpClient
        .delete('/editor/imagefolder/' + id, {
          data: {
            type: 'custom',
            magazine_id: this.$store.getters.constants.magazine_id,
          },
        })
        .then((response) => {
          this.$store.commit('removeImageDirectory', index)
          bus.$emit('imageDirectoryDeleted', id)
        })
    },
    resDeleteImage: function (index) {
      var id = this.$store.getters.images[index].id
      httpClient
        .delete('/editor/image/' + id)
        .then((response) => {
          this.$store.commit('removeImage', index)
          bus.$emit('imageDeleted', id)
        })
        .catch((error) => {
          bus.$emit('imageInUse', id)
        })
    },
    resDeletePdfDocument: function (index) {
      var id = this.$store.getters.pdfDocuments[index].id
      httpClient
        .delete('/editor/document/' + id)
        .then((response) => {
          this.$store.commit('removePdfDocument', index)
          bus.$emit('pdfDocumentDeleted', id)
        })
        .catch((error) => {
          bus.$emit('pdfDocumentInUse', id)
        })
    },
  },
}
