import lang from 'lang.js'
import messages from './messages'

const Lang = new lang({
  messages: messages,
  locale: 'nl',
  fallback: 'nl',
})

export default Lang
