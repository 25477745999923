import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    constants: {},
    fonts: {},
    canvas: {},
    canvasExtra: {},
    canvasSpine: {},
    user: {},
    currentMagazine: {},
    currentMagazinepage: {},
    currentMagazinepageExtra: {},
    currentMagazinespine: {},
    roleForUser: 0,
    templates: [],
    templatepages: [],
    templatepageContent: '',
    magazines: [],
    magazinepages: [],
    magazinepageContent: '',
    imageDirectories: [],
    images: [],
    clipartDirectories: [],
    clipartImages: [],
    backgroundDirectories: [],
    backgroundImages: [],
    shapeDirectories: [],
    shapes: [],
    masks: [],
    pdfDocuments: [],
    pdfDocumentPages: [],
    settings: [],
  },

  mutations: {
    setConstants(state, constants) {
      state.constants = constants
    },
    setFonts(state, fonts) {
      state.fonts = fonts
    },
    setCanvas(state, canvas) {
      state.canvas = canvas
    },
    setCanvasExtra(state, canvas) {
      state.canvasExtra = canvas
    },
    setCanvasSpine(state, canvas) {
      state.canvasSpine = canvas
    },
    setUser(state, user) {
      state.user = user
    },
    setCurrentMagazine(state, magazine) {
      state.currentMagazine = magazine
    },
    setCurrentMagazinepage(state, magazinepage) {
      state.currentMagazinepage = magazinepage
    },
    setCurrentMagazinepageExtra(state, magazinepage) {
      state.currentMagazinepageExtra = magazinepage
    },
    setCurrentMagazinespine(state, magazinespine) {
      state.currentMagazinespine = magazinespine
    },
    setRoleForUser(state, roleForUser) {
      state.roleForUser = roleForUser
    },
    setTemplates(state, templates) {
      state.templates = templates
    },
    setTemplatepages(state, templatepages) {
      state.templatepages = templatepages
    },
    setTemplatepageContent(state, templatepageContent) {
      state.templatepageContent = templatepageContent
    },
    setMagazines(state, magazines) {
      state.magazines = magazines
    },
    setMagazinepages(state, magazinepages) {
      state.magazinepages = magazinepages
    },
    setMagazinepageContent(state, magazinepageContent) {
      state.magazinepageContent = magazinepageContent
    },
    setImageDirectories(state, imageDirectories) {
      state.imageDirectories = imageDirectories
    },
    setImageDirectory(state, data) {
      state.imageDirectories[data.index] = data.imageDirectory
    },
    addImageDirectory(state, imageDirectory) {
      state.imageDirectories.push(imageDirectory)
    },
    removeImageDirectory(state, index) {
      Vue.delete(state.imageDirectories, index)
    },
    setImages(state, images) {
      state.images = images
    },
    addImage(state, image) {
      state.images.unshift(image)
    },
    removeImage(state, index) {
      Vue.delete(state.images, index)
    },
    removePdfDocument(state, index) {
      Vue.delete(state.pdfDocuments, index)
    },
    setClipartDirectories(state, clipartDirectories) {
      state.clipartDirectories = clipartDirectories
    },
    setClipartImages(state, clipartImages) {
      state.clipartImages = clipartImages
    },
    setBackgroundDirectories(state, backgroundDirectories) {
      state.backgroundDirectories = backgroundDirectories
    },
    setBackgroundImages(state, backgroundImages) {
      state.backgroundImages = backgroundImages
    },
    setShapeDirectories(state, shapeDirectories) {
      state.shapeDirectories = shapeDirectories
    },
    setShapes(state, shapes) {
      state.shapes = shapes
    },
    setMasks(state, masks) {
      state.masks = masks
    },
    setPdfDocuments(state, pdfDocuments) {
      state.pdfDocuments = pdfDocuments
    },
    addPdfDocument(state, pdfDocument) {
      state.pdfDocuments.unshift(pdfDocument)
    },
    setPdfDocumentPages(state, pdfDocumentPages) {
      state.pdfDocumentPages = pdfDocumentPages
    },
    setSettings(state, settings) {
      state.settings = settings
    },
  },

  getters: {
    constants: (state) => state.constants,
    fonts: (state) => state.fonts,
    canvas: (state) => state.canvas,
    canvasExtra: (state) => state.canvasExtra,
    canvasSpine: (state) => state.canvasSpine,
    user: (state) => state.user,
    currentMagazine: (state) => state.currentMagazine,
    currentMagazinepage: (state) => state.currentMagazinepage,
    currentMagazinepageExtra: (state) => state.currentMagazinepageExtra,
    currentMagazinespine: (state) => state.currentMagazinespine,
    roleForUser: (state) => state.roleForUser,
    templates: (state) => state.templates,
    templatepages: (state) => state.templatepages,
    templatepageContent: (state) => state.templatepageContent,
    magazines: (state) => state.magazines,
    magazinepages: (state) => state.magazinepages,
    magazinepageContent: (state) => state.magazinepageContent,
    imageDirectories: (state) => state.imageDirectories,
    images: (state) => state.images,
    clipartDirectories: (state) => state.clipartDirectories,
    clipartImages: (state) => state.clipartImages,
    backgroundDirectories: (state) => state.backgroundDirectories,
    backgroundImages: (state) => state.backgroundImages,
    shapeDirectories: (state) => state.shapeDirectories,
    shapes: (state) => state.shapes,
    masks: (state) => state.masks,
    pdfDocuments: (state) => state.pdfDocuments,
    pdfDocumentPages: (state) => state.pdfDocumentPages,
    settings: (state) => state.settings,
  },
})
