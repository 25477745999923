<template>
  <div class="wrapper">
    <div class="content content-with-header">
      <div class="sidepanel-header">
        {{ Lang.get('sidepanel.items.' + $parent.currentWorkspacebarItem) }}
        <div
          class="icon icon-close"
          @click="$parent.$refs.fabric.deselectObject()"
        ></div>
      </div>
      <div class="section-scrollable">
        <div v-show="$parent.currentWorkspacebarItem == 'properties'">
          <edit-properties></edit-properties>
        </div>
        <div
          v-show="$parent.currentWorkspacebarItem == 'filters'"
          v-if="objectType === 'placeholder' && object.hasImage"
        >
          <edit-filters></edit-filters>
        </div>
        <div v-show="$parent.currentWorkspacebarItem == 'border'">
          <edit-border></edit-border>
          <edit-color
            color-subject="object"
            color-property="stroke"
            color-canvas-type="page"
            show-remove-button="true"
            show-preview-swatch="true"
          >
          </edit-color>
        </div>
        <div v-show="$parent.currentWorkspacebarItem == 'shadow'">
          <edit-shadow></edit-shadow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  data: function () {
    return {
      Lang: Lang,
      object: {},
      objectType: '',
    }
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
  },
  mounted() {
    this.object = this.$parent.activeObject
    this.objectType = this.$parent.activeObjectType
    bus.$on('activeObjectTypeChanged', (activeObjectType) => {
      this.objectType = activeObjectType
      this.object = activeObjectType !== '' ? this.canvas.getActiveObject() : {}
    })
  },
}
</script>
