<template>
  <div class="wrapper">
    <div class="content content-with-header">
      <div class="sidepanel-header">
        {{ Lang.get('sidepanel.items.' + $parent.currentWorkspacebarItem) }}
        <div
          class="icon icon-close"
          @click="$parent.activateSidePanel(1)"
        ></div>
      </div>
      <div class="section-scrollable">
        <div v-show="$parent.currentWorkspacebarItem == 'color'">
          <edit-color
            color-subject="canvas"
            color-property="backgroundColor"
            color-canvas-type="page"
            show-remove-button="false"
            show-preview-swatch="true"
          >
          </edit-color>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'
import EditColor from './EditColor.vue'

export default {
  data: function () {
    return { Lang: Lang }
  },
}
</script>
