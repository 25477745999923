var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner"},[_c('mdb-spinner',{attrs:{"small":"","multicolor":""}})],1),_c('div',{staticClass:"content content-with-tabs"},[_c('div',{staticClass:"sidepanel-tabs"},[_c('a',{class:{ active: _vm.activeTab == 'folders' },on:{"click":function($event){_vm.activeTab = 'folders'}}},[_vm._v(_vm._s(_vm.Lang.get('sidepanel.tabs.folders')))]),_c('a',{class:{ active: _vm.activeTab == 'documents' },on:{"click":function($event){_vm.activeTab = 'documents'}}},[_vm._v(_vm._s(_vm.Lang.get('sidepanel.tabs.documents')))])]),_c('image-directories-list',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeTab == 'folders' &&
        _vm.activeTabFoldersContent == 'imageDirectories'
      ),expression:"\n        activeTab == 'folders' &&\n        activeTabFoldersContent == 'imageDirectories'\n      "}],key:"imageDirectories"}),_c('image-directory',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeTab == 'folders' && _vm.activeTabFoldersContent == 'imageDirectory'
      ),expression:"\n        activeTab == 'folders' && activeTabFoldersContent == 'imageDirectory'\n      "}],key:"imageDirectory",attrs:{"image-directory":_vm.currentImageDirectory}}),_c('pdf-documents-list',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeTab == 'documents' &&
        _vm.activeTabDocumentsContent == 'pdfDocuments'
      ),expression:"\n        activeTab == 'documents' &&\n        activeTabDocumentsContent == 'pdfDocuments'\n      "}],key:"pdfDocuments"}),_c('pdf-document',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeTab == 'documents' && _vm.activeTabDocumentsContent == 'pdfDocument'
      ),expression:"\n        activeTab == 'documents' && activeTabDocumentsContent == 'pdfDocument'\n      "}],key:"pdfDocument",attrs:{"pdf-document":_vm.currentPdfDocument}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }