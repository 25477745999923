import Vue from 'vue'
import App from './App.vue'
import store from './store'

// Styles
require('./assets/sass/app.scss')

// Scaffolding
require('./utils/scaffolding')

import VueCookies from 'vue-cookies'
import VueMasonry from 'vue-masonry-css'
import vSelect from 'vue-select'
import * as mdbvue from 'mdbvue'
import { BootstrapVue } from 'bootstrap-vue'
import VueMousetrapPlugin from 'vue-mousetrap/vue2'
import Pusher from 'pusher-js'

// Pusher
Pusher.logToConsole = process.env.VUE_APP_PUSHER_DEBUG === 'true'

const getAuthToken = () => localStorage.getItem('token')

let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: 'eu',
  authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
  auth: { headers: { Authorization: `Bearer ${getAuthToken()}` } },
})

Vue.prototype.$pusher = pusher

// Components
const components = require.context('./components', true, /\.vue$/i)

components.keys().map((key) => Vue.component(key.split('/').pop().split('.')[0], components(key).default))

Vue.use(VueCookies)
Vue.use(VueMasonry)
Vue.component('VSelect', vSelect)
Vue.use(BootstrapVue)
Vue.use(VueMousetrapPlugin)

// MDBootstrap components
for (const component in mdbvue) {
  Vue.component(component, mdbvue[component])
}

Vue.config.productionTip = false

// App
new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app')
