/*
    JIL | Compatible with fabric.js v5.2.1 (2022-02-21)
*/

;(function () {
  'use strict'

  /**
   * Placeholder class, based on Object.
   * @class fabric.Placeholder
   * @extends fabric.Rect
   * @mixes fabric.Observable
   * @return {fabric.Placeholder} thisArg
   */
  fabric.Placeholder = fabric.util.createClass(fabric.Rect, fabric.Observable, {
    type: 'placeholder',
    mask: '',
    hasImage: false,
    imageId: null,
    image: null,
    hasIcon: false,
    iconId: null,
    icon: null,
    swappable: true,
    panX: 0,
    tiltY: 0,
    initialPanX: 0,
    initialTiltY: 0,
    zoomPercentage: 0,
    _canvas: null,
    ghost: null,

    initialize: function (options) {
      this._canvas = fabric._canvas
      this.callSuper('initialize', options)
      this.setControlVisible('panningZoomIn', this.hasImage)
      this.setControlVisible('panningZoomOut', this.hasImage)
      this.setControlVisible('delete', this.hasImage)
      this.setControlVisible('swappableLock', this.hasImage && this.swappable)
      this.setControlVisible('swappableUnlock', this.hasImage && !this.swappable)
      this.setOriginalStroke()

      if (this.mask === 'circle') {
        this.set({
          rx: this.width / 2,
          ry: this.height / 2,
        })
      }

      if (this.mask === 'rectangle-rounded') {
        this.set({
          rx: 25,
          ry: 25,
        })
      }
    },

    enlivenPlaceholder: function () {
      this.setHoveredState(false)
      this.linkPlaceholderObjects()
      this.startListeners()
    },

    linkPlaceholderObjects: function () {
      if (!this.hasIcon && !this.hasImage) {
        this.createIcon()
      } else {
        if (this.hasIcon) {
          this.linkIcon()
        }
        if (this.hasImage) {
          this.linkImage()
        }
      }
    },

    unlinkPlaceholderObjects: function () {
      if (this.hasIcon) {
        this.unlinkIcon()
      }
      if (this.hasImage) {
        this.unlinkImage()
      }
    },

    emptyPlaceholder: function () {
      this.unlinkImage()
      this.createIcon()
      this.setHoveredState(false)
    },

    startListeners: function () {
      this.on('removed', function (e) {
        this.unlinkPlaceholderObjects()
      })
      this.on('rotating', function (e) {
        this.handlePlaceholderRotating()
      })
      this.on('scaling', function (e) {
        this.handlePlaceholderScaling()
      })
      this.on('moving', function (e) {
        this.handlePlaceholderMoving()
      })
      this.on('modified', function (e) {
        this.handlePlaceholderModified()
      })
      this.on('panning:started', function (e) {
        this.handlePlaceholderPanningStarted()
      })
      this.on('panning:finished', function (e) {
        this.handlePlaceholderPanningFinished()
      })
      this.on('panning', function (e) {
        this.handlePlaceholderPanning(e)
      })
      this.on('panning:zoom:in', function (e) {
        this.handlePlaceholderPanningZoom('in')
      })
      this.on('panning:zoom:out', function (e) {
        this.handlePlaceholderPanningZoom('out')
      })
    },

    setOriginalStroke: function () {
      this.set({
        originalStroke: this.stroke,
        originalStrokeWidth: this.strokeWidth,
      })

      if (this.image || this.icon) {
        this.positionImage()
      }
    },

    setHoveredState: function (isHovered) {
      if (isHovered) {
        if (this.hasImage) {
          this.set({
            fill: 'rgba(0,0,0,0.005)',
            stroke: '#2A65C4',
            strokeWidth: 2,
            strokeUniform: true,
          })
        } else {
          this.set({
            fill: '#CFCDC7',
            stroke: '#2A65C4',
            strokeWidth: 2,
            strokeUniform: true,
          })
        }
      } else {
        if (this.hasImage) {
          this.set({
            fill: 'rgba(0,0,0,0.005)',
            stroke: this.originalStroke,
            strokeWidth: this.originalStrokeWidth,
            strokeUniform: true,
          })
        } else {
          this.set({
            fill: '#F2F0ED',
            stroke: this.originalStroke,
            strokeWidth: this.originalStrokeWidth,
            strokeUniform: true,
          })
        }
      }
      this._canvas.requestRenderAll()
    },

    createUuid: function () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      )
    },

    createIcon: function () {
      this.hasIcon = true
      this.iconId = this.createUuid()
      this.icon = new fabric.PlaceholderIcon(
        'M52.9927 0.286133C55.5522 0.286133 57.7384 1.19262 59.5514 3.00561C61.3644 4.81859 62.2709 7.00483 62.2709 9.56433V52.436C62.2709 54.9955 61.3644 57.1818 59.5514 58.9948C57.7384 60.8077 55.5522 61.7142 52.9927 61.7142H10.121C7.56147 61.7142 5.37523 60.8077 3.56225 58.9948C1.74926 57.1818 0.842773 54.9955 0.842773 52.436V9.56433C0.842773 7.00483 1.74926 4.81859 3.56225 3.00561C5.37523 1.19262 7.56147 0.286133 10.121 0.286133H52.9927ZM43.5545 29.0806L11.7207 55.6354H52.9927C54.8056 55.6354 55.8188 54.7289 56.0321 52.9159L56.192 52.436V41.5581C56.0854 41.5581 55.9254 41.5048 55.7121 41.3982C55.6055 41.2915 55.4988 41.1849 55.3922 41.0782L55.2322 40.7583L43.5545 29.0806ZM52.9927 6.36495H10.121C9.26781 6.36495 8.52129 6.68489 7.88141 7.32477C7.24153 7.96464 6.9216 8.71117 6.9216 9.56433V51.6362L41.9548 22.5218C43.2346 21.4554 44.5143 21.4554 45.7941 22.5218L46.114 22.6818L56.192 33.0798V9.56433C56.192 7.75135 55.2856 6.73822 53.4726 6.52492L52.9927 6.36495ZM14.92 14.3634C16.0932 13.1903 17.5329 12.6037 19.2392 12.6037C20.9455 12.6037 22.3853 13.1903 23.5584 14.3634C24.8381 15.5365 25.478 16.9762 25.478 18.6826C25.478 20.3889 24.8381 21.8819 23.5584 23.1617C22.3853 24.3348 20.9455 24.9214 19.2392 24.9214C17.5329 24.9214 16.0932 24.3348 14.92 23.1617C13.7469 21.8819 13.1604 20.3889 13.1604 18.6826C13.1604 16.9762 13.7469 15.5365 14.92 14.3634Z'
      )
      this.icon.set({
        iconId: this.iconId,
        height: 60,
        width: 60,
        fill: '#CDC7C2',
        centeredRotation: true,
      })

      if (this.mask === 'circle') {
        let clipPath = new fabric.Circle({
          radius: 100,
          left: -100,
          top: -100,
        })
        this.clipPath = clipPath
      }

      if (this.mask === 'rectangle-rounded') {
        let clipPath = new fabric.Rect({
          width: 200,
          height: 200,
          left: -100,
          top: -100,
          rx: 25,
          ry: 25,
        })
        this.clipPath = clipPath
      }

      this._canvas.add(this.icon)
      this._canvas.setActiveObject && this._canvas.setActiveObject(this.icon)

      this.centerIcon()

      setTimeout(() => {
        this.icon.bringForward(true)
        this._canvas.discardActiveObject && this._canvas.discardActiveObject()
        this._canvas.requestRenderAll()
      }, 0)
    },

    setImage: function (url, shadow) {
      if (this.hasIcon) {
        this.setInitialImage(url, shadow)
      } else if (this.hasImage) {
        this.setReplacementImage(url, shadow)
      }
      this.setControlVisible('panningZoomIn', true)
      this.setControlVisible('panningZoomOut', true)
      this.setControlVisible('swappableLock', this.swappable)
      this.setControlVisible('swappableUnlock', !this.swappable)
    },

    setInitialImage: function (url, shadow) {
      let index = this._canvas.getObjects().indexOf(this.icon)

      let _this = this
      new fabric.PlaceholderImage.fromURL(
        url,
        function (canvasImage) {
          _this.hasImage = true
          _this.imageId = _this.createUuid()
          _this.image = canvasImage
          _this.image.set({
            src: url,
            imageId: _this.imageId,
            centeredRotation: true,
          })

          _this.setHoveredState(false)
          _this.unlinkIcon()

          _this.removeGhost()
          _this.image.opacity = 0
          _this.image.clone((ghost) => {
            _this.image.opacity = 1
            _this.ghost = ghost
            _this.ghost.set({
              isGhost: true,
              selectable: false,
              clipPath: null,
            })
            _this._canvas.add(_this.ghost)

            _this.rotateImage()
            _this.scaleImage()
            _this.positionImage()
            _this.clipImage()

            _this.image.shadow = shadow

            setTimeout(() => {
              _this.setLayerOrder()
              _this._canvas.fire('placeholder:filled')
            }, 250)
          })
        },
        { crossOrigin: 'anonymous' }
      )
    },

    setReplacementImage: function (url, shadow) {
      let index = this._canvas.getObjects().indexOf(this.image)

      this.unlinkImage()

      setTimeout(() => {
        let _this = this
        new fabric.PlaceholderImage.fromURL(
          url,
          function (canvasImage) {
            _this.hasImage = true
            _this.imageId = _this.createUuid()
            _this.image = canvasImage
            _this.image.set({
              src: url,
              imageId: _this.imageId,
              centeredRotation: true,
            })

            _this.setHoveredState(false)

            _this._canvas.add(_this.image)

            _this.removeGhost()
            _this.image.opacity = 0
            _this.image.clone((ghost) => {
              _this.image.opacity = 1
              _this.ghost = ghost
              _this.ghost.set({
                isGhost: true,
                selectable: false,
                clipPath: null,
              })
              _this._canvas.add(_this.ghost)

              _this.rotateImage()
              _this.scaleImage()
              _this.positionImage()
              _this.clipImage()

              _this.image.shadow = shadow

              setTimeout(() => {
                _this.setLayerOrder()
                _this._canvas.fire('placeholder:filled', false)
              }, 250)
            })
          },
          { crossOrigin: 'anonymous' }
        )
      }, 500)
    },

    linkIcon: function () {
      let _this = this
      this._canvas.getObjects().forEach(function (object) {
        if (object.type === 'placeholder-icon') {
          if (object.iconId === _this.iconId) {
            _this.icon = object
            _this.centerIcon()
          }
        }
      })
    },

    unlinkIcon: function () {
      let _this = this
      this._canvas.getObjects().forEach(function (object) {
        if (object.type === 'placeholder-icon') {
          if (object.iconId === _this.iconId) {
            _this._canvas.remove(object).requestRenderAll()
          }
        }
      })
      this.hasIcon = false
      this.iconId = null
      this.icon = null
    },

    linkImage: function () {
      let _this = this
      this._canvas.getObjects().forEach(function (object) {
        if (object.type === 'placeholder-image' && !object.isGhost) {
          if (object.imageId === _this.imageId) {
            _this.image = object
            _this.positionImage()
            _this.linkGhost()
          }
        }
      })
    },

    linkGhost: function () {
      let _this = this
      this._canvas.getObjects().forEach(function (object) {
        if (object.type === 'placeholder-image' && object.isGhost) {
          if (object.imageId === _this.imageId) {
            _this.ghost = object
          }
        }
      })
    },

    unlinkImage: function () {
      let _this = this
      this._canvas.getObjects().forEach(function (object) {
        if (object.type === 'placeholder-image') {
          if (object.imageId === _this.imageId) {
            _this._canvas.remove(object).requestRenderAll()
          }
        }
      })
      this.hasImage = false
      this.imageId = null
      this.image = null
      this.removeGhost()
    },

    centerIcon: function () {
      if (this.icon) {
        this.icon.setPositionByOrigin(this.getCenterPoint(), 'center', 'center')
        this.icon.angle = this.angle
        this._canvas.requestRenderAll()
      }
    },

    setIconVisibility: function () {
      if (this.width * this.scaleX <= this.icon.width || this.height * this.scaleY <= this.icon.height) {
        this.icon.visible = false
      } else {
        this.icon.visible = true
      }
      this._canvas.requestRenderAll()
    },

    scaleImage: function () {
      let scaledPlaceholderWidth = this.width * this.scaleX
      let scaledPlaceholderHeight = this.height * this.scaleY
      let originalImageSize = this.image.getOriginalSize()

      let minimumScaleX = (scaledPlaceholderWidth / originalImageSize.width / 100) * (100 + this.zoomPercentage)
      let minimumScaleY = (scaledPlaceholderHeight / originalImageSize.height / 100) * (100 + this.zoomPercentage)

      let scaleFactor = 1
      if (minimumScaleX > minimumScaleY) {
        scaleFactor = minimumScaleX
      } else {
        scaleFactor = minimumScaleY
      }

      this.image.set({
        scaleX: scaleFactor,
        scaleY: scaleFactor,
      })

      if (this.ghost !== null) {
        this.ghost.set({
          scaleX: scaleFactor,
          scaleY: scaleFactor,
        })
      }

      this.positionImage()
      this.clipImage()
      this._canvas.requestRenderAll()
    },

    positionImage: function () {
      let centerPoint = this.getCenterPoint()

      if (this.angle !== 0) {
        let canvasCenterPoint = new fabric.Point(this._canvas.getWidth() / 2, this._canvas.getHeight() / 2)
        let radians = fabric.util.degreesToRadians(this.angle)
        let revertedRadians = fabric.util.degreesToRadians(360 - this.angle)
        let correctedCenterPoint = fabric.util.rotatePoint(centerPoint, canvasCenterPoint, radians * -1)
        correctedCenterPoint.x = correctedCenterPoint.x + this.panX
        correctedCenterPoint.y = correctedCenterPoint.y + this.tiltY
        centerPoint = fabric.util.rotatePoint(correctedCenterPoint, canvasCenterPoint, revertedRadians * -1)
      } else {
        centerPoint.x = centerPoint.x + this.panX
        centerPoint.y = centerPoint.y + this.tiltY
      }

      if (this.image) {
        this.image.setPositionByOrigin(centerPoint, 'center', 'center')
      }

      if (this.ghost !== null) {
        this.ghost.setPositionByOrigin(centerPoint, 'center', 'center')
      }

      this._canvas.requestRenderAll()
    },

    rotateImage() {
      this.image.rotate(this.angle)

      if (this.ghost !== null) {
        this.ghost.rotate(this.angle)
      }

      this._canvas.requestRenderAll()
    },

    clipImage: function () {
      let placeholderScaledWidth = (this.width * this.scaleX) / this.image.scaleX
      let placeholderScaledHeight = (this.height * this.scaleY) / this.image.scaleY

      let clipPathLeft = -(placeholderScaledWidth / 2) - this.panX / this.image.scaleX
      if (this.image.flipX) {
        if (clipPathLeft !== 0) {
          clipPathLeft = clipPathLeft * -1 - placeholderScaledWidth
        }
      }

      let clipPathTop = -(placeholderScaledHeight / 2) - this.tiltY / this.image.scaleY
      if (this.image.flipY) {
        if (clipPathTop !== 0) {
          clipPathTop = clipPathTop * -1 - placeholderScaledHeight
        }
      }

      let clipPath = null

      switch (this.mask) {
        case 'rectangle':
          clipPath = new fabric.Polygon(
            [
              { x: 0, y: 0 },
              { x: placeholderScaledWidth, y: 0 },
              { x: placeholderScaledWidth, y: placeholderScaledHeight },
              { x: 0, y: placeholderScaledHeight },
            ],
            { left: clipPathLeft, top: clipPathTop }
          )
          break
        case 'rectangle-rounded':
          clipPath = new fabric.Rect({
            width: placeholderScaledWidth,
            height: placeholderScaledHeight,
            left: clipPathLeft,
            top: clipPathTop,
            rx: 250,
            ry: 250,
          })
          break
        case 'circle':
          clipPath = new fabric.Circle({
            radius: Math.floor(placeholderScaledWidth / 2),
            left: clipPathLeft,
            top: clipPathTop,
          })
          break
      }

      this.image.clipPath = clipPath
      this._canvas.requestRenderAll()
    },

    panImage: function (moveX, moveY) {
      moveX += this.initialPanX
      moveY += this.initialTiltY

      let placeholderScaledWidth = this.width * this.scaleX
      let placeholderScaledHeight = this.height * this.scaleY
      let imageWidth = this.image.width * this.image.scaleX
      let imageHeight = this.image.height * this.image.scaleY

      let spareX = (imageWidth - placeholderScaledWidth) / 2
      let minPanX = 0 - spareX
      let maxPanX = spareX

      if (moveX < minPanX) {
        this.panX = minPanX
      } else if (moveX > maxPanX) {
        this.panX = maxPanX
      } else {
        this.panX = moveX
      }

      let spareY = (imageHeight - placeholderScaledHeight) / 2
      let mintiltY = 0 - spareY
      let maxtiltY = spareY

      if (moveY < mintiltY) {
        this.tiltY = mintiltY
      } else if (moveY > maxtiltY) {
        this.tiltY = maxtiltY
      } else {
        this.tiltY = moveY
      }
    },

    zoomImage: function () {
      this.scaleImage()
      this.panImage(-0, -0)
      this.scaleImage()
      this.fixPlaceholderScale()
      this.positionImage()
      this.clipImage()
      this._canvas.requestRenderAll()
      this._canvas.fire('placeholder:zoomed')
    },

    handlePlaceholderMoving: function () {
      if (this.hasIcon) {
        this.centerIcon()
      }
      if (this.hasImage) {
        this.positionImage()
      }
    },

    handlePlaceholderScaling: function () {
      if (this.hasIcon) {
        this.centerIcon()
        this.setIconVisibility()
      }
      if (this.hasImage) {
        this.scaleImage()
        this.panImage(this.panX, this.tiltY)
        this.fixPlaceholderScale()
      }
    },

    handlePlaceholderRotating: function () {
      if (this.hasIcon) {
        this.centerIcon()
      }
      if (this.hasImage) {
        this.rotateImage()
        this.positionImage()
      }
    },

    handlePlaceholderModified: function () {
      if (this.hasIcon) {
        this.centerIcon()
        this.setIconVisibility()
      }
      if (this.hasImage) {
        this.scaleImage()
        this.fixPlaceholderScale()
        this.positionImage()
        this.clipImage()
      }
    },

    handlePlaceholderPanningStarted: function () {
      this.setControlVisible('panning', false)
      this.initialPanX = this.panX
      this.initialTiltY = this.tiltY
      this.ghost.opacity = 0.4
      this._canvas.requestRenderAll()
    },

    handlePlaceholderPanningFinished: function () {
      this.setControlVisible('panning', true)
      this.initialPanX = this.panX
      this.initialTiltY = this.tiltY
      this.ghost.opacity = 0
      this._canvas.requestRenderAll()
    },

    handlePlaceholderPanning: function (e) {
      this.panImage(e.moveX, e.moveY)
      this.scaleImage()
      this.fixPlaceholderScale()
      this.positionImage()
      this.clipImage()
    },

    handlePlaceholderPanningZoom: function (direction) {
      if (direction === 'in' && this.zoomPercentage < 200) {
        this.zoomPercentage += 10
        this.zoomImage()
      }
      if (direction === 'out' && this.zoomPercentage > 0) {
        this.zoomPercentage -= 10
        this.zoomImage()
      }
    },

    fixPlaceholderScale: function () {
      setTimeout(() => {
        this.scaleX = this.scaleX + 0.0000000000001
        this.scaleY = this.scaleY + 0.0000000000001
        this._canvas.requestRenderAll()
      }, 0)
    },

    setLayerOrder: function () {
      let index = this._canvas.getObjects().indexOf(this)
      if (this.hasImage) {
        this._canvas.moveTo(this.image, index)
        this._canvas.moveTo(this.ghost, index)
      } else if (this.hasIcon) {
        this._canvas.moveTo(this.icon, index + 1)
      }
    },

    sendBackwards: function (intersecting) {
      if (this.hasImage) {
        const index = this._canvas.getObjects().indexOf(this.ghost)
        const imageIndex = this._canvas.getObjects().indexOf(this.image)
        const ghostIndex = this._canvas.getObjects().indexOf(this)
        const prevObjectIndex = index - 1
        const prevObject = this._canvas.getObjects()[prevObjectIndex]

        if (prevObject && index > 0) {
          this._canvas.moveTo(this.ghost, ghostIndex - 1)
          this._canvas.moveTo(this.image, imageIndex - 1)
          this._canvas.moveTo(this, prevObjectIndex)
          this._canvas.moveTo(prevObject, ghostIndex)
          this.linkImage()
        }
      }

      if (this.hasIcon) {
        const index = this._canvas.getObjects().indexOf(this)
        const iconIndex = this._canvas.getObjects().indexOf(this.icon)
        const prevObjectIndex = index - 1
        const prevObject = this._canvas.getObjects()[prevObjectIndex]

        if (prevObject && index > 0) {
          this._canvas.moveTo(this.icon, iconIndex - 1)
          this._canvas.moveTo(this, prevObjectIndex)
          this._canvas.moveTo(prevObject, iconIndex)
          this.linkIcon()
        }
      }
    },

    sendToBack: function (intersecting) {
      if (this.hasImage) {
        this.ghost.sendToBack(true)
        let index = this._canvas.getObjects().indexOf(this.ghost)
        this._canvas.moveTo(this.image, index + 1)
        this._canvas.moveTo(this, index + 2)
      }
      if (this.hasIcon) {
        this.callSuper('sendToBack', intersecting)
        let index = this._canvas.getObjects().indexOf(this)
        this._canvas.moveTo(this.icon, index + 1)
      }
    },

    bringForward: function () {
      if (this.hasImage) {
        const index = this._canvas.getObjects().indexOf(this)
        const imageIndex = this._canvas.getObjects().indexOf(this.image)
        const ghostIndex = this._canvas.getObjects().indexOf(this.ghost)
        const nextObjectIndex = imageIndex + 1
        const nextObject = this._canvas.getObjects()[nextObjectIndex]

        if (nextObject) {
          this._canvas.moveTo(nextObject, index)
          this._canvas.moveTo(this, index + 1)
          this._canvas.moveTo(this.ghost, ghostIndex + 1)
          this._canvas.moveTo(this.image, imageIndex + 1)
          this.linkImage()
        }
      }

      if (this.hasIcon) {
        const index = this._canvas.getObjects().indexOf(this)
        const iconIndex = this._canvas.getObjects().indexOf(this.icon)
        const nextObjectIndex = iconIndex + 1
        const nextObject = this._canvas.getObjects()[nextObjectIndex]

        if (nextObject) {
          this._canvas.moveTo(nextObject, index)
          this._canvas.moveTo(this.icon, iconIndex + 1)
          this._canvas.moveTo(this, index + 1)
          this.linkIcon()
        }
      }
    },

    bringToFront: function (intersecting) {
      if (this.hasImage) {
        this.callSuper('bringToFront', intersecting)
        let index = this._canvas.getObjects().indexOf(this)
        this._canvas.moveTo(this.image, index - 1)
        this._canvas.moveTo(this.ghost, index - 2)
      }
      if (this.hasIcon) {
        this.icon.bringToFront(true)
        let index = this._canvas.getObjects().indexOf(this.icon)
        this._canvas.moveTo(this, index - 1)
      }
    },

    flipPlaceholder: function (axis) {
      if (this.hasImage) {
        if (axis === 'x') {
          this.image.flipX = !this.image.flipX
          this.ghost.flipX = this.image.flipX
        } else if (axis === 'y') {
          this.image.flipY = !this.image.flipY
          this.ghost.flipY = this.image.flipY
        }
        setTimeout(() => {
          this.panImage(-0, -0)
          this.scaleImage()
          this.fixPlaceholderScale()
          this.positionImage()
          this.clipImage()
        }, 0)
      }
    },

    removeGhost: function () {
      if (this.ghost !== null) {
        this._canvas.remove(this.ghost)
        this.ghost = null
      }
    },

    duplicatePlaceholder: function (original) {
      let left = this.left + 20
      let top = this.top + 20
      let swappable = this.swappable

      original.clone((clone) => {
        clone.set({
          left: left,
          top: top,
          hasImage: false,
          imageId: null,
          image: null,
          hasIcon: false,
          iconId: null,
          icon: null,
          swappable: swappable,
          panX: 0,
          tiltY: 0,
          initialPanX: 0,
          initialTiltY: 0,
          zoomPercentage: 0,
          ghost: null,
        })

        this._canvas.add(clone).requestRenderAll()
        clone.createIcon()

        setTimeout(() => {
          this._canvas.fire(
            'placeholder:duplicated',
            original.hasImage ? { url: original.image.src, iconId: clone.iconId, shadow: original.image.shadow } : {}
          )
        }, 250)
      })
    },

    toObject: function (propertiesToInclude) {
      return this.callSuper(
        'toObject',
        [
          'type',
          'mask',
          'hasIcon',
          'iconId',
          'hasImage',
          'imageId',
          'swappable',
          'panX',
          'tiltY',
          'zoomPercentage',
          'originalStroke',
          'originalStrokeWidth',
        ].concat(propertiesToInclude)
      )
    },
  })

  /**
   * Returns fabric.Placeholder instance from an object representation
   * @static
   * @memberOf fabric.Placeholder
   * @param {Object} object Object to create an instance from
   * @param {Function} [callback] Callback to invoke when an fabric.Placeholder instance is created
   */
  fabric.Placeholder.fromObject = function (object, callback) {
    return fabric.Object._fromObject('Placeholder', object, callback)
  }

  /**
   * Placeholder class, based on Object.
   * @class fabric.PlaceholderIcon
   * @extends fabric.Path
   * @mixes fabric.Observable
   * @return {fabric.Placeholder} thisArg
   */
  fabric.PlaceholderIcon = fabric.util.createClass(fabric.Path, fabric.Observable, {
    type: 'placeholder-icon',
    iconId: null,
    placeholder: null,
    _canvas: null,

    initialize: function (path, options) {
      this._canvas = fabric._canvas
      this.callSuper('initialize', path, options)
    },

    getPlaceholder: function () {
      let _this = this
      this._canvas.getObjects().forEach(function (object) {
        if (object.hasOwnProperty('type') && object.type === 'placeholder') {
          if (object.iconId === _this.iconId) {
            _this.placeholder = object
          }
        }
      })
    },

    forwardSelection: function () {
      this.getPlaceholder()
      if (typeof this.placeholder === 'object') {
        this._canvas.setActiveObject(this.placeholder)
      }
    },

    toObject: function (propertiesToInclude) {
      return this.callSuper('toObject', ['type', 'iconId'].concat(propertiesToInclude))
    },
  })

  /**
   * Returns fabric.PlaceholderIcon instance from an object representation
   * @static
   * @memberOf fabric.PlaceholderIcon
   * @param {Object} object Object to create an instance from
   * @param {Function} [callback] Callback to invoke when an fabric.PlaceholderIcon instance is created
   */
  fabric.PlaceholderIcon.fromObject = function (object, callback) {
    return fabric.Object._fromObject('PlaceholderIcon', object, callback, 'path')
  }

  /**
   * Placeholder class, based on Object.
   * @class fabric.PlaceholderImage
   * @extends fabric.Image
   * @mixes fabric.Observable
   * @return {fabric.Placeholder} thisArg
   */
  fabric.PlaceholderImage = fabric.util.createClass(fabric.Image, fabric.Observable, {
    type: 'placeholder-image',
    isGhost: false,
    imageId: null,
    placeholder: null,
    _canvas: null,

    initialize: function (element, options, callback) {
      this._canvas = fabric._canvas
      this.callSuper('initialize', element, options, callback)
    },

    getPlaceholder: function () {
      let _this = this
      this._canvas.getObjects().forEach(function (object) {
        if (object.hasOwnProperty('type') && object.type === 'placeholder') {
          if (object.imageId === _this.imageId) {
            _this.placeholder = object
          }
        }
      })
    },

    forwardSelection: function () {
      this.getPlaceholder()
      if (this.placeholder && typeof this.placeholder === 'object') {
        this._canvas.setActiveObject(this.placeholder)
      }
    },

    toObject: function (propertiesToInclude) {
      return this.callSuper('toObject', ['type', 'isGhost', 'imageId'].concat(propertiesToInclude))
    },
  })

  /**
   * Returns fabric.PlaceholderImage instance from an object representation
   * @static
   * @memberOf fabric.PlaceholderImage
   * @param {Object} _object Object to create an instance from
   * @param {Function} [callback] Callback to invoke when an fabric.PlaceholderImage instance is created
   */
  fabric.PlaceholderImage.fromObject = function (_object, callback) {
    var object = fabric.util.object.clone(_object)
    fabric.util.loadImage(
      object.src,
      function (img, isError) {
        if (isError) {
          callback && callback(null, true)
          return
        }
        fabric.PlaceholderImage.prototype._initFilters.call(object, object.filters, function (filters) {
          object.filters = filters || []
          fabric.PlaceholderImage.prototype._initFilters.call(object, [object.resizeFilter], function (resizeFilters) {
            object.resizeFilter = resizeFilters[0]
            fabric.util.enlivenObjectEnlivables(object, object, function () {
              var image = new fabric.PlaceholderImage(img, object)
              callback(image, false)
            })
          })
        })
      },
      null,
      object.crossOrigin
    )
  }

  /**
   * Creates an instance of fabric.PlaceholderImage from an URL string
   * @static
   * @param {String} url URL to create an image from
   * @param {Function} [callback] Callback to invoke when image is created (newly created image is passed as a first argument). Second argument is a boolean indicating if an error occurred or not.
   * @param {Object} [imgOptions] Options object
   */
  fabric.PlaceholderImage.fromURL = function (url, callback, imgOptions) {
    fabric.util.loadImage(
      url,
      function (img, isError) {
        callback && callback(new fabric.PlaceholderImage(img, imgOptions), isError)
      },
      null,
      imgOptions && imgOptions.crossOrigin
    )
  }
})(typeof exports !== 'undefined' ? exports : this)
