<template>
  <div class="wrapper">
    <div class="content content-non-scrollable">
      <div class="add-text add-text-title" @click="addTextToCanvas('title')">
        {{ Lang.get('sidepanel.buttons.add-text-title') }}
      </div>
      <div
        class="add-text add-text-subtitle"
        @click="addTextToCanvas('subtitle')"
      >
        {{ Lang.get('sidepanel.buttons.add-text-subtitle') }}
      </div>
      <div
        class="add-text add-text-subsection"
        @click="addTextToCanvas('subsection')"
      >
        {{ Lang.get('sidepanel.buttons.add-text-subsection') }}
      </div>
      <div class="add-text add-text-main" @click="addTextToCanvas('main')">
        {{ Lang.get('sidepanel.buttons.add-text-main') }}
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  data: function () {
    return {
      Lang: Lang,
      canvas: this.$store.getters.canvas,
    }
  },
  computed: {
    magazine() {
      return this.$store.getters.currentMagazine
    },
  },
  methods: {
    addTextToCanvas: function (type) {
      var textProperties = {
        left: 100,
        top: 100,
        width: 100,
        fontFamily:
          this.magazine.canvasVersion == 'linda'
            ? 'Helvetica Roman'
            : 'Open Sans',
        lineHeight: 1.1,
        charSpacing: 0,
        columns: 1,
        columnGap: 15,
        columnWidth: 100,
        fill: '#000000',
        strokeWidth: 0,
        centeredScaling: false,
        centeredRotation: true,
        perPixelTargetFind: false,
      }

      switch (type) {
        case 'title':
          textProperties.fontSize = 40
          break
        case 'subtitle':
          textProperties.fontSize = 30
          break
        case 'subsection':
          textProperties.fontSize = 20
          break
        case 'main':
          textProperties.fontSize = 10
          break
      }

      this.canvas.add(
        new fabric.Columntextbox(
          Lang.get('canvas.misc.default-text-content'),
          textProperties
        )
      )
    },
  },
}
</script>
