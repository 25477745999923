<template>
  <div>
    <b-modal
      ref="approve"
      centered
      size="lg"
      modal-class="modal-approve"
      no-close-on-backdrop
      no-close-on-escape
      hide-header
      hide-footer
    >
      <div class="icon icon-close" @click="closeModal()"></div>
      <h1>{{ Lang.get('modals.approve.header') }}</h1>
      <h2>{{ Lang.get('modals.approve.subheader') }}</h2>
      <div class="row">
        <div class="step-1-image">
          <div class="step-number float-left">1</div>
          <a class="btn btn-acc btn-on-dark btn-med float-right mt-2 mr-4">
            <div class="icon icon-ok"></div>
            {{ Lang.get('header.buttons.approve') }}
          </a>
          <a class="btn btn-pri btn-on-dark btn-med float-right mt-2">
            <div class="icon icon-ok2"></div>
            {{ Lang.get('header.buttons.save') }}
          </a>
        </div>
        <div class="step-1-text">
          <p>
            {{ Lang.get('modals.approve.step-1-part-1') }}
            <strong>{{ Lang.get('modals.approve.step-1-part-2') }}</strong>
            {{ Lang.get('modals.approve.step-1-part-3') }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="step-2-image">
          <div class="step-number float-left">2</div>
          <div class="page-1">
            <img class="page" src="/images/approve/page-1.png" />
            <img class="page-icon" src="/images/approve/page-1-icon.png" />
            <span>{{ Lang.get('modals.approve.approved') }}</span>
          </div>
          <div class="page-2">
            <img class="page" src="/images/approve/page-2.png" />
            <img class="page-icon" src="/images/approve/page-2-icon.png" />
            <span>{{ Lang.get('modals.approve.submitted') }}</span>
          </div>
          <img class="icon-approved" src="/images/approve/icon-approved.png" />
        </div>
        <div class="step-2-text">
          <p>
            {{ Lang.get('modals.approve.step-2') }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="step-3-image">
          <div class="step-number float-left">3</div>
        </div>
        <div class="step-3-text">
          <p>
            {{ Lang.get('modals.approve.step-3') }}
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'ApproveModal',
  data: function () {
    return { Lang: Lang }
  },
  methods: {
    closeModal() {
      this.$refs.approve.hide()
    },
  },
}
</script>
