export default {
  methods: {
    convertRgbToHex(rgb) {
      rgb = rgb.match(
        /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
      )
      return rgb && rgb.length === 4
        ? '#' +
            ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
        : ''
    },
    convertHexToRgb(hex) {
      hex = hex.replace('#', '')
      var r = parseInt(hex.substring(0, 2), 16)
      var g = parseInt(hex.substring(2, 4), 16)
      var b = parseInt(hex.substring(4, 6), 16)
      var result = 'rgb(' + r + ',' + g + ',' + b + ')'
      return result
    },
    getRgbOpacity(rgb) {
      return Math.round(parseFloat(rgb.split(',')[3].slice(0, -1)))
    },
    setRgbOpacity(rgb, opacity) {
      return rgb.replace('rgb', 'rgba').slice(0, -1) + ',' + opacity + ')'
    },
    isHex(value) {
      return /^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i.test(value)
    },
    isRgb(value) {
      return (
        /^rgb\(\s*(0|[1-9]\d?|1\d\d|2[0-4]\d|25[0-5])\s*,\s*(0|[1-9]\d?|1\d\d?|2[0-4]\d|25[0-5])\s*,\s*(0|[1-9]\d?|1\d\d?|2[0-4]\d|25[0-5])\s*\)$/i.test(
          value
        ) ||
        /^rgba\(\s*(0|[1-9]\d?|1\d\d?|2[0-4]\d|25[0-5])\s*,\s*(0|[1-9]\d?|1\d\d?|2[0-4]\d|25[0-5])\s*,\s*(0|[1-9]\d?|1\d\d?|2[0-4]\d|25[0-5])\s*,\s*((0.[0-9]{1,2})|[01])\s*\)$/i.test(
          value
        )
      )
    },
    getSwatchStyle(color) {
      if (color == null) {
        return ''
      }
      return { 'background-color': color }
    },
    getTextFillSwatchStyle(activeObject) {
      if (activeObject.isEditing === false) {
        this.selectAllText(activeObject)
      }

      var selectionStart = activeObject.selectionStart
      var selectionEnd = activeObject.selectionEnd

      if (selectionStart === selectionEnd) {
        selectionStart =
          selectionStart > 0 ? selectionStart - 1 : selectionStart
        selectionEnd = selectionStart > 0 ? selectionEnd : 1
      }

      var selectionStyles = activeObject.getSelectionStyles(
        selectionStart,
        selectionEnd,
        true
      )[0]

      let color = '#000000'
      if (this.isRgb(selectionStyles.fill)) {
        color = this.convertRgbToHex(selectionStyles.fill)
      } else {
        color = selectionStyles.fill
      }

      if (activeObject.isEditing === false) {
        this.deselectAllText(activeObject)
      }
      return this.getSwatchStyle(color)
    },
    getBackgroundColorSwatchStyle() {
      return this.getSwatchStyle(this.canvasBackgroundColor)
    },
    selectAllText(activeObject) {
      activeObject.selectionStart = 0
      activeObject.selectionEnd = activeObject.text.length
    },
    deselectAllText(activeObject) {
      activeObject.selectionStart = 0
      activeObject.selectionEnd = 0
    },
    convertPercentageToFraction(value) {
      return parseFloat(value / 100)
    },
    convertFractionToPercentage(value) {
      return parseInt(value * 100)
    },
    convertOpacityToTransparency(value) {
      return parseInt(100 - 100 * value)
    },
    convertTransparencyToOpacity(value) {
      return parseFloat((100 - value) / 100)
    },
    translateObjectType(objectType) {
      switch (objectType) {
        case 'columntextbox':
          return 'text'
        case 'path':
        case 'rect':
        case 'polygon':
        case 'line':
        case 'group':
        case 'circle':
        case 'ellipse':
          return 'shape'
        default:
          return objectType
      }
    },
    convertPxToMm(px) {
      return px / (870 / 307)
    },
    convertMmToPx(mm) {
      return mm * (870 / 307)
    },
    normaliseStroke(object) {
      if (object.hasOwnProperty('version') && object.version !== '') {
        var version = object.version.replace(/\./g, '')
        if (parseInt(version) <= 420) {
          if (object.stroke === '') {
            object.strokeWidth = 0
          } else {
            object.strokeWidth = Math.round(
              object.strokeWidth * Math.max(object.scaleX, object.scaleY)
            )
          }
          object.version = ''
        }
      }
      return object
    },
    createUuid() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      )
    },
  },
}
