<template>
  <div class="h-100">
    <VueDropzone
      class="dropzone"
      id="dropzone-pdf-documents"
      ref="dropzonePdfDocuments"
      :options="dropzoneOptions"
      :useCustomSlot="true"
      :include-styling="false"
      @vdropzone-files-added="filesAdded"
      v-show="!processing"
    >
      <div class="dropzone-custom-content">
        <span class="dropzone-custom-title">{{
          Lang.get('sidepanel.controls.pdf-dropzone-drop')
        }}</span>
        <div class="dropzone-custom-subtitle">
          {{ Lang.get('sidepanel.controls.pdf-dropzone-click') }}
        </div>
      </div>
    </VueDropzone>
    <div
      class="alarm-container alarm-container-images"
      v-show="showInUseWarning"
    >
      <div class="alarm alarm-tiny alarm-error alarm-on-dark">
        <div class="alarm-left">
          <div class="alarm-icon-wrapper">
            <div class="icon icon-alert-filled"></div>
          </div>
        </div>
        <div class="alarm-right">
          {{ Lang.get('alarms.image-in-use') }}
        </div>
      </div>
    </div>
    <div id="upload-progress" v-show="processing">
      {{ Lang.get('sidepanel.controls.pdf-uploading') }}
      {{ uploadfilesProcessed + 1 }} of {{ uploadfilesTotal }} ({{
        uploadProgress
      }}%)
    </div>
    <div class="alarm-container mb-3" v-show="awaitingImport">
      <div class="alarm alarm-tiny alarm-information alarm-on-dark">
        <div class="alarm-left">
          <div class="alarm-icon-wrapper">
            <div class="icon icon-info-filled"></div>
          </div>
        </div>
        <div class="alarm-right">
          {{ Lang.get('alarms.pdf-importing') }}
        </div>
      </div>
    </div>
    <div class="alarm-container mb-3">
      <div class="alarm alarm-tiny alarm-information alarm-on-dark">
        <div class="alarm-left">
          <div class="alarm-icon-wrapper">
            <div class="icon icon-info-filled"></div>
          </div>
        </div>
        <div class="alarm-right">
          {{ Lang.get('alarms.pdf-info') }}
        </div>
      </div>
    </div>
    <div class="section-scrollable pdfdocuments-container">
      <div
        class="item"
        v-for="(pdfDocument, i) in pdfDocuments"
        :key="pdfDocument.id"
        @click="showPdfDocument($event, i)"
      >
        <img class="fluid" :src="getThumbnail(pdfDocument)" />
        <div class="pagecount">
          {{ pdfDocument.documentpages.length }}
          {{ Lang.get('sidepanel.misc.pagecount') }}
        </div>
        <div class="delete" @click.stop="deletePdfDocument($event, i)">
          <div class="icon icon-delete"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import resources from '../mixins/resources.js'
import Lang from '../utils/lang'
import vue2Dropzone from 'vue2-dropzone'
import httpClient from '../utils/httpClient'

export default {
  components: { VueDropzone: vue2Dropzone },
  mixins: [resources],
  data() {
    return {
      Lang: Lang,
      processing: false,
      uploadfilesTotal: 0,
      uploadfilesSuccess: 0,
      uploadfilesError: 0,
      uploadfilesProcessed: 0,
      uploadProgress: 0,
      uploadQueue: [],
      dropzoneOptions: {
        url: process.env.VUE_APP_URL_API + '/editor/document/',
        maxFilesize: 100,
        maxFiles: 1,
        parallelUploads: 1,
        acceptedFiles: 'application/pdf',
        autoProcessQueue: false,
      },
      awaitingImport: false,
      showInUseWarning: false,
    }
  },
  watch: {
    showInUseWarning(value) {
      if (value) {
        setTimeout(() => {
          this.showInUseWarning = false
        }, 1200)
      }
    },
  },
  computed: {
    pdfDocuments() {
      return this.$store.getters.pdfDocuments
    },
    magazine() {
      return this.$store.getters.currentMagazine
    },
  },
  methods: {
    showPdfDocument(event, i) {
      this.$parent.currentPdfDocument = this.pdfDocuments[i]
      this.$parent.activeTabDocumentsContent = 'pdfDocument'
    },
    deletePdfDocument(event, i) {
      this.$parent.loading = true
      this.resDeletePdfDocument(i)
    },
    filesAdded(files) {
      setTimeout(() => {
        var acceptedFiles = this.$refs.dropzonePdfDocuments.getAcceptedFiles()
        var rejectedFiles = this.$refs.dropzonePdfDocuments.getRejectedFiles()

        this.uploadfilesTotal = files.length
        this.uploadfilesSuccess = 0
        this.uploadfilesError = rejectedFiles.length
        this.uploadfilesProcessed =
          this.uploadfilesSuccess + this.uploadfilesError
        this.uploadProgress = 0

        this.processing = true
        this.$parent.loading = true

        this.uploadQueue = acceptedFiles
        this.processUploadQueue()
      }, 0)
    },
    processUploadQueue() {
      if (this.uploadQueue.length) {
        var file = this.uploadQueue.splice(0, 1)
        this.uploadFile(file[0])
      }
      if (this.uploadfilesProcessed == this.uploadfilesTotal) {
        this.$refs.dropzonePdfDocuments.removeAllFiles()
        this.processing = false
        this.$parent.loading = false
      }
    },
    async uploadFile(file) {
      var data = new FormData()
      data.append('type', 'pdf')
      data.append('magazine_id', this.magazine.id)
      data.append('file', file)

      await httpClient
        .post(process.env.VUE_APP_URL_API + '/editor/document/', data, {
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            )
          },
        })
        .then((response) => {
          this.uploadfilesSuccess++
          this.uploadfilesProcessed++
          this.awaitingImport = true
          this.processUploadQueue()
        })
        .catch((error) => {
          this.uploadfilesError++
          this.uploadfilesProcessed++
          this.processUploadQueue()
        })
    },
    getThumbnail(pdfDocument) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_pdfdocuments/' +
        this.magazine.id +
        '/thumbnails/' +
        pdfDocument.documentpages[0].fileName
      )
    },
  },
  mounted() {
    bus.$on('CreationDocumentImported', (document) => {
      httpClient
        .get('/editor/document/' + document.id + '/pages')
        .then((response) => {
          document.documentpages = response.data.data
          this.$store.commit('addPdfDocument', document)
          this.awaitingImport = false
        })
    })
    bus.$on('pdfDocumentInUse', (id) => {
      this.$parent.loading = false
      this.showInUseWarning = true
    })
    bus.$on('pdfDocumentDeleted', (id) => {
      this.$parent.loading = false
    })
  },
}
</script>
