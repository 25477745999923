<template>
  <div>
    <!-- Width -->
    <div class="form-group">
      <label>{{ Lang.get('sidepanel.labels.border-width') }}</label>
      <input v-model="width" type="range" min="0" max="20" step="1" />
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import utilities from '../mixins/utilities.js'
import Lang from '../utils/lang'

export default {
  mixins: [utilities],
  data: function () {
    return {
      Lang: Lang,
      object: {},
      uniform: true,
      width: 0,
    }
  },
  watch: {
    object: function () {
      if (Object.keys(this.object).length > 0) {
        if (this.object.stroke === null && this.object.strokeWidth > 0 && this.object.type !== 'columntextbox') {
          this.removeStroke()
        } else {
          this.object = this.normaliseStroke(this.object)
        }
        this.getProperties()
      }
    },
    width: function () {
      if (typeof this.width === 'string') {
        this.width = parseInt(this.width)
      } else {
        this.width > 0 ? this.setStroke() : this.removeStroke()
      }
    },
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
  },
  methods: {
    getProperties() {
      this.width = this.object.strokeWidth
      if (this.width == 0) {
        this.removeStroke()
      }
    },
    setStroke() {
      if (this.$parent.$parent.activeObjectType == 'text') {
        bus.$emit('textStrokeWidthChanged', this.width)
      } else {
        this.object.set('strokeUniform', this.uniform)
        this.object.set('strokeWidth', this.width)
        if (this.object.stroke == null) {
          this.object.stroke = '#747474'
        }
        this.canvas.requestRenderAll()
        if (this.object.type === 'placeholder') {
          this.object.setOriginalStroke()
        }
        bus.$emit('strokeChanged')
        this.canvas.fire('object:modified')
      }
    },
    removeStroke() {
      if (this.$parent.$parent.activeObjectType == 'text') {
        bus.$emit('textStrokeWidthChanged', this.width)
      } else {
        this.object.set('stroke', null)
        this.object.set('strokeWidth', 0)
        this.canvas.requestRenderAll()
        if (this.object.type === 'placeholder') {
          this.object.setOriginalStroke()
        }
      }
    },
  },
  mounted() {
    this.object = this.canvas.getActiveObject()
    bus.$on('activeObjectTypeChanged', (activeObjectType) => {
      this.object = activeObjectType !== '' ? this.canvas.getActiveObject() : {}
    })
    bus.$on('strokeWidthChanged', () => {
      this.getProperties()
    })
  },
}
</script>
