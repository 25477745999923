<template>
  <div>
    <b-modal
      ref="tourVideo"
      centered
      size="lg"
      modal-class="modal-tour-video"
      hide-header
      hide-footer
    >
      <div>
        <b-embed
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/ysWhaUaoVdE?rel=0"
          allowfullscreen
        ></b-embed>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'TourVideoModal',
  data: function () {
    return {}
  },
  methods: {
    openModal() {
      this.$refs.tourVideo.show()
    },
    closeModal() {
      this.$refs.tourVideo.hide()
    },
  },
}
</script>
