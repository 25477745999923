fabric.Image.prototype.strokeWidth = 0;
fabric.enableGLFiltering = true;
fabric.textureSize = 4096;
fabric.Object.NUM_FRACTION_DIGITS = 10;
fabric.Object.prototype.objectCaching = true;
fabric.Object.prototype.perPixelTargetFind = true;
fabric.Object.prototype.set({
  transparentCorners: false,
  cornerSize: 16,
  cornerStyle: 'circle',
  borderColor: '#0A6AD8',
  cornerColor: '#FFFFFF',
  cornerStrokeColor: '#0A6AD8',
  borderScaleFactor: 2,
});
fabric._canvas = null;
