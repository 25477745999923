<template>
  <div class="wrapper">
    <div class="content content-with-header">
      <div class="sidepanel-header">
        {{ Lang.get('sidepanel.items.' + $parent.currentWorkspacebarItem) }}
        <div
          class="icon icon-close"
          @click="$parent.$refs.fabric.deselectObject()"
        ></div>
      </div>
      <div class="section-scrollable">
        <div v-show="$parent.currentWorkspacebarItem == 'properties'">
          <edit-properties></edit-properties>
        </div>
        <div v-show="$parent.currentWorkspacebarItem == 'color'">
          <edit-color
            color-subject="object"
            color-property="fill"
            color-canvas-type="page"
            show-remove-button="true"
            show-preview-swatch="true"
          >
          </edit-color>
        </div>
        <div v-show="$parent.currentWorkspacebarItem == 'border'">
          <edit-border></edit-border>
          <edit-color
            color-subject="object"
            color-property="stroke"
            color-canvas-type="page"
            show-remove-button="true"
            show-preview-swatch="true"
          >
          </edit-color>
        </div>
        <div v-show="$parent.currentWorkspacebarItem == 'shadow'">
          <edit-shadow></edit-shadow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'
import EditColor from './EditColor.vue'
import EditBorder from './EditBorder.vue'
import EditShadow from './EditShadow.vue'

export default {
  data: function () {
    return { Lang: Lang }
  },
}
</script>
