<template>
  <div class="h-100">
    <a class="pdfdocument-back" @click="backToPdfDocuments()"
      ><div class="icon icon-chevron-left"></div>
      Back</a
    >
    <div class="section-scrollable pdfdocumentpages-container">
      <div
        class="item"
        v-for="(pdfDocumentPage, i) in pdfDocumentPages"
        :key="pdfDocumentPage.id"
        @click="confirmUseDocumentPage(pdfDocumentPage)"
      >
        <img class="fluid" :src="getThumbnail(pdfDocumentPage)" />
      </div>
    </div>
    <mdb-modal
      size="md"
      centered
      :show="confirmUseDocumentPageVisible"
      class="modal-legacy"
    >
      <mdb-modal-header>
        <mdb-modal-title>Use this PDF page</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body
        >Your current design will be overwritten by the content of this PDF
        page.</mdb-modal-body
      >
      <mdb-modal-footer>
        <mdb-btn
          color="sec btn-med"
          @click.native="confirmUseDocumentPageVisible = false"
          >Cancel</mdb-btn
        >
        <mdb-btn color="pri btn-med" @click.native="useDocumentPage()"
          >Change</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import resources from '../mixins/resources.js'

export default {
  mixins: [resources],
  props: { pdfDocument: Object },
  data() {
    return {
      selectedPdfDocumentPage: {},
      confirmUseDocumentPageVisible: false,
    }
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
    magazine() {
      return this.$store.getters.currentMagazine
    },
    pdfDocumentPages() {
      return this.$store.getters.pdfDocumentPages
    },
  },
  methods: {
    backToPdfDocuments() {
      this.$parent.currentPdfDocument = {}
      this.$parent.activeTabDocumentsContent = 'pdfDocuments'
    },
    confirmUseDocumentPage(pdfDocumentPage) {
      this.selectedPdfDocumentPage = pdfDocumentPage
      this.confirmUseDocumentPageVisible = true
    },
    useDocumentPage() {
      var _this = this
      bus.$emit('canvasLoading', true)
      this.confirmUseDocumentPageVisible = false
      this.$parent.$parent.$refs.fabric.clearCanvas()
      new fabric.Image.fromURL(
        _this.getUrl(),
        function (object) {
          object.scaleToWidth(
            _this.$parent.$parent.$refs.fabric.fullCanvasWidth
          )
          _this.canvas.setBackgroundImage(
            object,
            function () {
              _this.canvas.requestRenderAll()
              _this.selectedPdfDocumentPage = {}
              bus.$emit('canvasLoading', false)
            },
            {
              left: 0,
              top: 0,
              originX: 'left',
              originY: 'top',
            }
          )
        },
        { crossOrigin: 'anonymous' }
      )
    },
    getThumbnail(pdfDocumentPage) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_pdfdocuments/' +
        this.magazine.id +
        '/thumbnails/' +
        pdfDocumentPage.fileName
      )
    },
    getUrl() {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_pdfdocuments/' +
        this.magazine.id +
        '/fullsize/' +
        this.selectedPdfDocumentPage.fileName
      )
    },
  },
  created() {},
}
</script>
