<template>
  <div class="row">
    <div class="col p-0">
      <div id="header">
        <div id="header-left" v-cloak>
          <img class="jilster-logo" src="/images/jilster-logo.png" v-on:click="logoClicked()" />
          <span
            class="page-number"
            v-show="
              $parent.canvasInitialised &&
              $store.getters.currentMagazinepage.type !== 1 &&
              $store.getters.currentMagazinepage.type !== 4
            "
          >
            {{ Lang.get('canvas.controls.page') }}
            {{ $store.getters.currentMagazinepage.number }}
          </span>
          <span class="page-number" v-show="$parent.canvasInitialised && $store.getters.currentMagazinepage.type === 1">
            {{ Lang.get('sidepanel.misc.cover') }}
          </span>
          <span class="page-number" v-show="$parent.canvasInitialised && $store.getters.currentMagazinepage.type === 4">
            {{ Lang.get('sidepanel.misc.backcover') }}
          </span>
          <span class="magazine-title text-uppercase">
            {{ $store.getters.currentMagazine.title }}
          </span>
        </div>
        <div id="header-right" v-cloak>
          <div class="icon icon-undo float-left" v-on:click="$parent.undo()" v-if="!$parent.locked"></div>
          <div class="icon icon-redo float-left" v-on:click="$parent.redo()" v-if="!$parent.locked"></div>
          <a
            class="btn btn-acc btn-on-dark btn-med float-right"
            :class="{ disabled: $parent.canvasLoading }"
            v-show="$parent.btnEditVisible && $parent.canvasInitialised"
            v-on:click.prevent="$parent.releaseCanvas()"
          >
            <div class="icon icon-lock"></div>
            {{ Lang.get('header.buttons.edit') }}
          </a>
          <a
            class="btn btn-acc btn-on-dark btn-med float-right"
            :class="{ disabled: $parent.canvasLoading }"
            v-show="$parent.btnApproveVisible && $parent.canvasInitialised"
            v-on:click.prevent="$parent.confirmApproveCanvasVisible = true"
          >
            <div class="icon icon-ok"></div>
            {{ Lang.get('header.buttons.approve') }}
          </a>
          <a
            class="btn btn-war btn-on-dark btn-med float-right"
            :class="{ disabled: $parent.canvasLoading }"
            v-show="$parent.btnReviewVisible && $parent.canvasInitialised"
            v-on:click.prevent="$parent.confirmReviewCanvasVisible = true"
          >
            <div class="icon icon-warning"></div>
            {{ Lang.get('header.buttons.review') }}
          </a>
          <a
            class="btn btn-acc btn-on-dark btn-med float-right"
            :class="{ disabled: $parent.canvasLoading }"
            v-show="$parent.btnSubmitVisible && $parent.canvasInitialised"
            v-on:click.prevent="$parent.confirmSubmitCanvasVisible = true"
          >
            <div class="icon icon-lock"></div>
            {{ Lang.get('header.buttons.submit') }}
          </a>
          <a
            class="btn btn-pri btn-on-dark btn-med float-right"
            :class="{ disabled: $parent.canvasLoading }"
            v-show="$parent.btnSaveVisible && $parent.canvasInitialised"
            v-on:click.prevent="$parent.saveCanvas()"
          >
            <div class="icon icon-ok2"></div>
            {{ Lang.get('header.buttons.save') }}
          </a>
          <alarms></alarms>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'TopBar',
  data: function () {
    return { Lang: Lang }
  },
  methods: {
    logoClicked: function () {
      window.location.href = process.env.VUE_APP_URL_MAKERSPACE + '/document/' + this.$parent.magazine.id
    },
  },
}
</script>
