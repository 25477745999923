<template>
  <div class="h-100">
    <div class="sidepanel-info">
      {{ Lang.get('sidepanel.misc.info-imagedirectories') }}
    </div>
    <div class="alarm-container mb-3">
      <div class="alarm alarm-tiny alarm-information alarm-on-dark">
        <div class="alarm-left">
          <div class="alarm-icon-wrapper">
            <div class="icon icon-info-filled"></div>
          </div>
        </div>
        <div class="alarm-right">
          {{ Lang.get('alarms.image-info') }}
        </div>
      </div>
    </div>
    <div class="row row-buttons">
      <div class="col-12">
        <a
          class="btn btn-dashed btn-lar"
          @click.prevent="createImageDirectory()"
        >
          <div class="icon icon-plus"></div>
          {{ Lang.get('sidepanel.buttons.add-folder') }}
        </a>
      </div>
    </div>
    <ul class="imagedirectories section-scrollable">
      <image-directories-list-item
        v-for="(imageDirectory, i) in imageDirectories"
        :imageDirectory="imageDirectory"
        :index="i"
        :key="imageDirectory.id"
      >
      </image-directories-list-item>
    </ul>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import resources from '../mixins/resources.js'
import Lang from '../utils/lang'

export default {
  mixins: [resources],
  data() {
    return { Lang: Lang }
  },
  computed: {
    imageDirectories() {
      return this.$store.getters.imageDirectories
    },
  },
  methods: {
    createImageDirectory() {
      this.$parent.loading = true
      this.resCreateImageDirectory()
    },
    updateImageDirectory(index, title) {
      this.imageDirectories[index].title = title
      this.resUpdateImageDirectory(index, this.imageDirectories[index])
    },
    deleteImageDirectory(index) {
      this.resDeleteImageDirectory(index)
    },
    openImageDirectory(index) {
      this.$parent.currentImageDirectory = this.imageDirectories[index]
      this.$parent.activeTabFoldersContent = 'imageDirectory'
    },
  },
  created() {
    bus.$on('imageDirectoryCreated', (id) => {
      this.$parent.loading = false
    })
  },
}
</script>
