<template>
  <div class="image" @click="addImageToCanvas()" draggable @dragstart="startDrag($event, image.id)">
    <div class="spinner-wrapper" v-show="loading">
      <mdb-spinner small multicolor />
    </div>
    <div class="delete" @click.stop="deleteImage()">
      <div class="icon icon-delete"></div>
    </div>
    <img :src="getThumbnail(image)" />
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  props: {
    image: Object,
    index: Number,
  },
  data() {
    return {
      Lang: Lang,
      canvas: this.$store.getters.canvas,
      loading: false,
    }
  },
  computed: {
    magazine() {
      return this.$store.getters.currentMagazine
    },
  },
  methods: {
    deleteImage() {
      this.loading = true
      this.$parent.$parent.deleteImage(this.index)
    },
    addImageToCanvas: function (left, top) {
      let url = this.getUrl(this.image)
      var _this = this
      new fabric.Image.fromURL(
        url,
        function (canvasImage) {
          canvasImage.set('src', url)
          canvasImage.set('centeredScaling', false)
          canvasImage.set('centeredRotation', true)
          canvasImage.set('strokeUniform', true)

          if (typeof left !== 'undefined' && typeof top !== 'undefined') {
            canvasImage.set('left', left)
            canvasImage.set('top', top)
          } else {
            let fabric = _this.$parent.$parent.$parent.$parent.$refs.fabric
            canvasImage.set('left', fabric.fullCanvasWidth / 2 - _this.$el.clientWidth / 2)
            canvasImage.set('top', fabric.fullCanvasHeight / 2 - _this.$el.clientHeight / 2)
          }

          canvasImage.scaleToWidth(_this.$el.clientWidth, true)

          const scaleX = parseFloat(canvasImage.scaleX.toFixed(2))
          const scaleY = parseFloat(canvasImage.scaleY.toFixed(2))

          canvasImage.set('scaleX', scaleX)
          canvasImage.set('scaleY', scaleY)

          _this.canvas.add(canvasImage)

          _this.canvas.requestRenderAll()
        },
        { crossOrigin: 'anonymous' }
      )
    },
    startDrag: (event, id) => {
      event.dataTransfer.setData('objectType', 'image')
      event.dataTransfer.setData('objectId', id)
      event.dataTransfer.setData('grabOffsetX', event.offsetX)
      event.dataTransfer.setData('grabOffsetY', event.offsetY)
      bus.$emit('canvas:drag:start', {
        objectType: 'image',
      })
    },
    getThumbnail(image) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_customimages/' +
        this.magazine.id +
        '/thumbnails/' +
        image.fileName
      )
    },
    getUrl(image) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_customimages/' +
        this.magazine.id +
        '/' +
        image.fileName
      )
    },
  },
  created() {
    bus.$on('canvas:object:dropped', (data) => {
      if (data.objectType == 'image' && data.objectId == this.image.id) {
        let fabric = this.$parent.$parent.$parent.$parent.$refs.fabric
        if (fabric.hoveredPlaceholder !== null) {
          let url = this.getUrl(this.image)
          fabric.hoveredPlaceholder.setImage(url, null)
        } else {
          this.addImageToCanvas(data.dropPositionLeft, data.dropPositionTop)
        }
      }
    })
    bus.$on('imageInUse', (id) => {
      if (this.image.id === id) {
        this.loading = false
        this.$parent.$parent.showInUseWarning = true
      }
    })
  },
}
</script>
