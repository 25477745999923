<template>
  <div class="h-100">
    <a class="imagedirectory-back" @click="backToImageDirectories()"
      ><div class="icon icon-chevron-left"></div>
      {{ Lang.get('sidepanel.buttons.back') }}</a
    >
    <VueDropzone
      class="dropzone"
      id="dropzone-images"
      ref="dropzoneImages"
      :options="dropzoneOptions"
      :useCustomSlot="true"
      :include-styling="false"
      @vdropzone-files-added="filesAdded"
      v-show="!processing"
    >
      <div class="dropzone-custom-content">
        <span class="dropzone-custom-title">{{ Lang.get('sidepanel.controls.image-dropzone-drop') }}</span>
        <div class="dropzone-custom-subtitle">
          {{ Lang.get('sidepanel.controls.image-dropzone-click') }}
        </div>
      </div>
    </VueDropzone>
    <div class="alarm-container alarm-container-images" v-show="showInUseWarning">
      <div class="alarm alarm-tiny alarm-error alarm-on-dark">
        <div class="alarm-left">
          <div class="alarm-icon-wrapper">
            <div class="icon icon-alert-filled"></div>
          </div>
        </div>
        <div class="alarm-right">
          {{ Lang.get('alarms.image-in-use') }}
        </div>
      </div>
    </div>
    <div id="upload-progress" v-show="processing">
      {{ uploadfilesProcessed }}
      {{ Lang.get('sidepanel.controls.image-uploading-of') }}
      {{ uploadfilesTotal }}
      {{ Lang.get('sidepanel.controls.image-uploading-uploaded') }}
      <b-progress :value="uploadProgressTotal" :max="uploadProgressTotalMax"></b-progress>
    </div>
    <div class="section-scrollable imagedirectory-images-container">
      <masonry :cols="2" :gutter="8" class="section-masonry imagedirectory-images">
        <image-directory-image v-for="(image, i) in images" :image="image" :index="i" :key="image.id">
        </image-directory-image>
      </masonry>
      <div class="empty-directory" v-show="images.length === 0">
        <img src="/images/empty-directory.png" />
        <div>{{ Lang.get('sidepanel.misc.empty-directory') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import resources from '../mixins/resources.js'
import Lang from '../utils/lang'
import vue2Dropzone from 'vue2-dropzone'
import httpClient from '../utils/httpClient'

export default {
  components: { VueDropzone: vue2Dropzone },
  mixins: [resources],
  props: { imageDirectory: Object },
  data() {
    return {
      Lang: Lang,
      processing: false,
      uploadfilesTotal: 0,
      uploadfilesSuccess: 0,
      uploadfilesError: 0,
      uploadfilesProcessed: 0,
      uploadProgress: 0,
      uploadProgressTotal: 0,
      uploadProgressTotalMax: 0,
      uploadQueue: [],
      dropzoneOptions: {
        url: process.env.VUE_APP_URL_API + '/editor/image',
        maxFilesize: 25,
        maxFiles: 20,
        parallelUploads: 1,
        acceptedFiles: 'image/jpeg,image/png,image/gif,image/bmp,image/jfif',
        autoProcessQueue: false,
      },
      showInUseWarning: false,
    }
  },
  watch: {
    showInUseWarning(value) {
      if (value) {
        setTimeout(() => {
          this.showInUseWarning = false
        }, 1200)
      }
    },
  },
  computed: {
    images() {
      return this.$store.getters.images
    },
    magazine() {
      return this.$store.getters.currentMagazine
    },
  },
  methods: {
    filesAdded(files) {
      setTimeout(() => {
        var acceptedFiles = this.$refs.dropzoneImages.getAcceptedFiles()
        var rejectedFiles = this.$refs.dropzoneImages.getRejectedFiles()

        this.uploadfilesTotal = files.length
        this.uploadfilesSuccess = 0
        this.uploadfilesError = rejectedFiles.length
        this.uploadfilesProcessed = this.uploadfilesSuccess + this.uploadfilesError
        this.uploadProgress = 0
        this.uploadProgressTotal = 0
        this.uploadProgressTotalMax = this.uploadfilesTotal * 100
        this.processing = true

        this.uploadQueue = acceptedFiles
        this.processUploadQueue()
      }, 0)
    },
    processUploadQueue() {
      if (this.uploadQueue.length) {
        var file = this.uploadQueue.splice(0, 1)
        this.uploadFile(file[0])
      }
      if (this.uploadfilesProcessed == this.uploadfilesTotal) {
        this.$refs.dropzoneImages.removeAllFiles()
        this.processing = false
        this.$parent.loading = false
      }
    },
    async uploadFile(file) {
      var data = new FormData()
      data.append('magazine_id', this.magazine.id)
      data.append('imagedirectory_id', this.imageDirectory.id)
      data.append('type', this.imageDirectory.type)
      data.append('file', file)

      await httpClient
        .post(process.env.VUE_APP_URL_API + '/editor/image', data, {
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
            this.uploadProgressTotal = this.uploadfilesProcessed * 100 + this.uploadProgress
          },
        })
        .then((response) => {
          setTimeout(() => {
            this.uploadfilesSuccess++
            this.uploadfilesProcessed++
            this.uploadProgress = 0
            this.uploadProgressTotal = this.uploadfilesProcessed * 100
            this.$store.commit('addImage', response.data.data)
            this.processUploadQueue()
          }, 2000)
        })
        .catch((error) => {
          this.uploadfilesError++
          this.uploadfilesProcessed++
          this.uploadProgress = 0
          this.uploadProgressTotal = this.uploadfilesProcessed * 100
          this.processUploadQueue()
        })
    },
    backToImageDirectories() {
      this.$parent.currentImageDirectory = {}
      this.$store.commit('setImages', [])
      this.$parent.activeTabFoldersContent = 'imageDirectories'
    },
    deleteImage(index) {
      this.resDeleteImage(index)
    },
  },
}
</script>
