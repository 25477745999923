/*
    JIL | Compatible with fabric.js v5.2.1 (2022-02-21)
*/

import { fabric } from 'fabric';

function initObjectCenteringHelplines(canvas, canvasScale) {

  if (!canvas.showHelplines) {
    return false;
  }

  var canvasWidth = canvas.getWidth() / canvasScale;
  var canvasHeight = canvas.getHeight() / canvasScale;
  var canvasWidthCenter = canvasWidth / 2;
  var canvasHeightCenter = canvasHeight / 2;
  var canvasWidthCenterMap = {};
  var canvasHeightCenterMap = {};
  var centerLineMargin = 4;
  var centerLineColor = 'rgb(239,85,51)';
  var centerLineWidth = 2;
  var ctx = canvas.getSelectionContext();
  var viewportTransform;

  for (let i = canvasWidthCenter - centerLineMargin, len = canvasWidthCenter + centerLineMargin; i <= len; i++) {
    canvasWidthCenterMap[Math.round(i)] = true;
  }

  for (let i = canvasHeightCenter - centerLineMargin, len = canvasHeightCenter + centerLineMargin; i <= len; i++) {
    canvasHeightCenterMap[Math.round(i)] = true;
  }

  function showVerticalCenterLine() {
    showCenterLine(canvasWidthCenter + 0.5, 0, canvasWidthCenter + 0.5, canvasHeight);
  }

  function showHorizontalCenterLine() {
    showCenterLine(0, canvasHeightCenter + 0.5, canvasWidth, canvasHeightCenter + 0.5);
  }

  function showCenterLine(x1, y1, x2, y2) {
    ctx.save();
    ctx.strokeStyle = centerLineColor;
    ctx.lineWidth = centerLineWidth;
    ctx.beginPath();
    ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
    ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);
    ctx.stroke();
    ctx.restore();
  }

  var isInVerticalCenter, isInHorizontalCenter;

  canvas.on('mouse:down', function () {
    viewportTransform = canvas.viewportTransform;
  });

  canvas.on('object:moving', function(e) {
    var activeObject = e.target;
    let renderHelpLines = !(activeObject.hasOwnProperty('iconId'));
    var objectCenter = activeObject.getCenterPoint();
    var transform = canvas._currentTransform;

    if (!transform || !renderHelpLines) return;

    isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap,
    isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap;

    if (isInHorizontalCenter || isInVerticalCenter) {
      activeObject.setPositionByOrigin(new fabric.Point(
        (isInVerticalCenter ? canvasWidthCenter : objectCenter.x),
        (isInHorizontalCenter ? canvasHeightCenter : objectCenter.y),
      ), 'center', 'center');
    }
  });

  canvas.on('before:render', function() {
    canvas.clearContext(ctx);
  });

  canvas.on('after:render', function() {
    if (canvas.showHelplines) {
      if (isInVerticalCenter) {
        showVerticalCenterLine();
      }
      if (isInHorizontalCenter) {
        showHorizontalCenterLine();
      }
    }
  });

  canvas.on('mouse:up', function() {
    isInVerticalCenter = isInHorizontalCenter = null;
    canvas.requestRenderAll();
  });
}

export { initObjectCenteringHelplines };
