var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:{ editing: _vm.editing }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner-wrapper"},[_c('mdb-spinner',{attrs:{"small":"","multicolor":""}})],1),_c('div',{staticClass:"imagedirectory-icon",on:{"click":function($event){return _vm.openImageDirectory()}}},[_c('div',{staticClass:"icon icon-folder"})]),_c('div',{staticClass:"imagedirectory-title"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editing),expression:"!editing"}],on:{"click":function($event){return _vm.openImageDirectory()}}},[_vm._v(_vm._s(_vm.imageDirectory.title))]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing),expression:"editing"},{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],ref:"imageDirectoryTitle",attrs:{"type":"text"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing)return;_vm.title=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editing),expression:"!editing"}],staticClass:"imagedirectory-actions"},[(_vm.isDeletable)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-secondary",value:({
        title: _vm.Lang.get('sidepanel.controls.imagedirectory-delete'),
      }),expression:"{\n        title: Lang.get('sidepanel.controls.imagedirectory-delete'),\n      }",modifiers:{"hover":true,"top":true,"v-secondary":true}}],staticClass:"icon icon-delete",on:{"click":function($event){return _vm.deleteImageDirectory()}}}):_vm._e(),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-secondary",value:({
        title: _vm.Lang.get('sidepanel.controls.imagedirectory-rename'),
      }),expression:"{\n        title: Lang.get('sidepanel.controls.imagedirectory-rename'),\n      }",modifiers:{"hover":true,"top":true,"v-secondary":true}}],staticClass:"icon icon-edit2",on:{"click":function($event){_vm.editing = true}}}),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-secondary",value:({
        title: _vm.Lang.get('sidepanel.controls.imagedirectory-open'),
      }),expression:"{\n        title: Lang.get('sidepanel.controls.imagedirectory-open'),\n      }",modifiers:{"hover":true,"top":true,"v-secondary":true}}],staticClass:"icon icon-upload",on:{"click":function($event){return _vm.openImageDirectory()}}})]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing),expression:"editing"}],staticClass:"btn",on:{"click":function($event){return _vm.updateImageDirectory()}}},[_vm._v(_vm._s(_vm.Lang.get('sidepanel.controls.imagedirectory-save')))])])
}
var staticRenderFns = []

export { render, staticRenderFns }