<template>
  <div>
    <maintenance-mode v-if="maintenanceModeActive"></maintenance-mode>
    <div
      class="system-message"
      :class="'system-message-' + systemMessageType"
      v-if="systemMessageVisible && !maintenanceModeActive"
      v-cloak
    >
      <span>{{ systemMessage }}</span>
      <div class="icon icon-close" v-on:click="hideSystemMessage()"></div>
    </div>
    <div class="container-fluid" v-if="!maintenanceModeActive">
      <top-bar></top-bar>
      <div class="row">
        <div class="col p-0">
          <side-panel-navigation></side-panel-navigation>
          <nav id="sidepanel" :class="[{ open: sidePanelActive }, 'sidepanel-' + currentSidePanelItem]">
            <keep-alive exclude="SidePanelImagesEdit,SidePanelShapesEdit,SidePanelTextEdit">
              <component :is="currentSidePanelComponent"></component>
            </keep-alive>
          </nav>
          <div id="workspace" :class="{ 'sidepanel-open': sidePanelActive }" ref="workspace">
            <sub-header-bar></sub-header-bar>
            <div id="canvas-spinner" v-if="canvasLoading">
              <mdb-spinner small multicolor />
            </div>
            <fabric ref="fabric" :resources-loaded="resourcesLoaded"></fabric>
          </div>
        </div>
      </div>
      <div id="overlay" v-show="loading">
        <div class="spinner-wrapper">
          <mdb-spinner multicolor />
        </div>
      </div>
    </div>
    <tour-modal ref="tourModal" :canvas-version="$store.getters.currentMagazine.canvasVersion"></tour-modal>
    <tour-video-modal ref="tourVideoModal"></tour-video-modal>
    <approve-modal ref="approveModal"></approve-modal>
    <choose-page-modal ref="choosePageModal"></choose-page-modal>
    <mdb-modal size="md" centered :show="confirmSubmitCanvasVisible" class="modal-legacy" v-cloak>
      <mdb-modal-header>
        <mdb-modal-title>
          {{ Lang.get('modals.page.submit_magazinepage_header') }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        {{ Lang.get('modals.page.submit_magazinepage_body') }}
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="sec btn-med" v-on:click.native="confirmSubmitCanvasVisible = false">{{
          Lang.get('modals.buttons.cancel')
        }}</mdb-btn>
        <mdb-btn
          color="pri btn-med"
          v-on:click.native="
            confirmSubmitCanvasVisible = false
            submitCanvas()
          "
          >{{ Lang.get('modals.buttons.submit') }}</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal size="md" centered :show="confirmApproveCanvasVisible" class="modal-legacy" v-cloak>
      <mdb-modal-header>
        <mdb-modal-title>
          {{ Lang.get('modals.page.approve_magazinepage_header') }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        {{ Lang.get('modals.page.approve_magazinepage_body') }}
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="sec btn-med" v-on:click.native="confirmApproveCanvasVisible = false">{{
          Lang.get('modals.buttons.cancel')
        }}</mdb-btn>
        <mdb-btn
          color="pri btn-med"
          v-on:click.native="
            confirmApproveCanvasVisible = false
            approveCanvas()
          "
          >{{ Lang.get('modals.buttons.approve') }}</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal size="md" centered :show="confirmReviewCanvasVisible" class="modal-legacy" v-cloak>
      <mdb-modal-header>
        <mdb-modal-title>
          {{ Lang.get('modals.page.review_magazinepage_header') }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        {{ Lang.get('modals.page.review_magazinepage_body') }}
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="sec btn-med"
          v-on:click.native="
            confirmReviewCanvasVisible = false
            releaseCanvas()
          "
          >{{ Lang.get('modals.buttons.release') }}</mdb-btn
        >
        <mdb-btn
          color="pri btn-med"
          v-on:click.native="
            confirmReviewCanvasVisible = false
            approveCanvas()
          "
          >{{ Lang.get('modals.buttons.approve') }}</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
// Mixins
import content from './mixins/content.js'
import resources from './mixins/resources.js'
import shortcuts from './mixins/shortcuts.js'
import utilities from './mixins/utilities.js'

// Event Bus
import { bus } from './utils/bus'

// Sections
import sections from './utils/sections'

// Translations
import Lang from './utils/lang'

export default {
  name: 'App',

  mixins: [content, resources, shortcuts, utilities],

  data: () => ({
    resourcesLoaded: false,
    Lang: Lang,
    loading: true,
    locked: true,
    lockedReason: '',
    sections: sections,
    currentSectionIndex: 0,
    editorType: '',
    activePagePosition: '',
    spineActive: false,
    spineVisible: false,
    sidePanelActive: true,
    sidePanelLocked: false,
    sidePanelHighlighted: '',
    currentSidePanelItem: '',
    workspaceToolsActive: false,
    currentWorkspacebarItem: '',
    activeObjectType: '',
    activeObject: {},
    imageCropperInstanceId: 0,
    imageCropperAvailable: false,
    imageCropperActive: false,
    canvasLoading: false,
    canvasInitialised: false,
    canvasBackgroundColor: '#ffffff',
    btnSaveVisible: false,
    btnSubmitVisible: false,
    btnEditVisible: false,
    btnApproveVisible: false,
    btnReviewVisible: false,
    confirmSubmitCanvasVisible: false,
    confirmApproveCanvasVisible: false,
    confirmReviewCanvasVisible: false,
    keyCode: '',
    viewPresetSidepanelItem: '',
    viewPresetOverviewType: '',
    ignoreDeselect: false,
    maintenanceModeActive: false,
    systemMessage: '',
    systemMessageType: '',
    systemMessageVisible: false,
    inputFocussed: false,
    currentTime: '',
  }),

  watch: {
    currentWorkspacebarItem: function () {
      this.imageCropperActive = this.currentWorkspacebarItem === 'crop'
      if (this.currentSidePanelItem === 'background' && this.currentWorkspacebarItem === 'color') {
        let sectionIndex = this.getSectionIndexByName('background-edit')
        this.activateSidePanel(sectionIndex)
      }
    },
    imageCropperActive: function () {
      bus.$emit('imageCropperActiveChanged', {
        instanceId: this.imageCropperInstanceId,
        active: this.imageCropperActive,
      })
    },
    currentTime: function (val) {
      console.log(Lang.locale)

      if (val === '1 AM') {
        this.systemMessageVisible = true
        this.systemMessage = Lang.get('system-messages.oneAMMessage')
        this.systemMessageType = 'info'
      } else if (val === '2 AM') {
        this.systemMessageVisible = true
        this.systemMessage = Lang.get('system-messages.twoAMMessage')
        this.systemMessageType = 'info'
      } else {
        this.systemMessageVisible = false
        this.systemMessage = ''
      }
    },
  },

  computed: {
    subheaderBarItems() {
      return this.sections[this.currentSectionIndex].subheaderBarItems
    },
    currentSidePanelComponent: function () {
      return 'side-panel-' + this.sections[this.currentSectionIndex].name
    },
    user() {
      return this.$store.getters.user
    },
    canvas() {
      return this.$store.getters.canvas
    },
    canvasExtra() {
      return this.$store.getters.canvasExtra
    },
    canvasSpine() {
      return this.$store.getters.canvasSpine
    },
    settings() {
      return this.$store.getters.settings
    },
    magazine() {
      return this.$store.getters.currentMagazine
    },
    magazinepage() {
      return this.$store.getters.currentMagazinepage
    },
    roleForUser() {
      return this.$store.getters.roleForUser
    },
    sidePanelItems() {
      return this.sections.filter((section) => {
        if (section.hasOwnProperty('navButton')) {
          if (section.hasOwnProperty('canvasVersion')) {
            return section.canvasVersion === this.magazine.canvasVersion
          } else {
            return true
          }
        } else {
          return false
        }
      })
    },
  },

  methods: {
    getConstantsFromRequest() {
      let requestSegments = window.location.pathname.replace(/\//, '').split('/')

      let constants = {}
      constants.magazine_id = requestSegments[0]
      constants.magazinepage_id = requestSegments[1]

      console.log(requestSegments)

      if (requestSegments.length === 4) {
        this.editorType = 'spread'
        this.activePagePosition = requestSegments[3] === 'spine' ? 'left' : requestSegments[3]
        this.spineActive = requestSegments[3] === 'spine'
        constants.magazinepage_id_extra = requestSegments[2]
      } else {
        this.editorType = 'page'
        this.activePagePosition = 'left'
        this.spineActive = false
      }
      this.$store.commit('setConstants', constants)
    },
    setDefaultSidePanelItem() {
      this.currentSidePanelItem = this.spineActive ? 'spine' : 'templates'
      this.sidePanelHighlighted = this.currentSidePanelItem
      if (this.currentSidePanelItem === 'spine') {
        this.activateSidePanel(this.getSectionIndexByName('spine'))
      }
    },
    activateSidePanel(sectionIndex, manual) {
      sectionIndex = typeof sectionIndex === 'undefined' ? this.currentSectionIndex : sectionIndex
      manual = typeof manual === 'undefined' ? false : manual
      this.currentSectionIndex = sectionIndex
      this.currentSidePanelItem = this.sections[sectionIndex].name
      if (!this.currentSidePanelItem.includes('-edit') && !this.spineActive) {
        if (manual && this.activeObjectType !== '') {
          this.ignoreDeselect = true
          this.$refs.fabric.deselectObject()
        }
      }
      this.sidePanelActive = true
      if (this.currentSidePanelItem === 'background') {
        this.currentWorkspacebarItem = ''
        this.workspaceToolsActive = true
      }
      this.sidePanelHighlighted = this.sections[sectionIndex].hasOwnProperty('activeNavButton')
        ? this.sections[sectionIndex].activeNavButton
        : this.sections[sectionIndex].name
      if (this.currentSidePanelItem.includes('-edit')) {
        this.sidePanelHighlighted = ''
      }
    },
    activateWorkspacebarItem(name, event) {
      if (name === 'crop' && !this.imageCropperAvailable) {
        return false
      } else {
        this.currentWorkspacebarItem = name
      }
    },
    getSectionByName(name) {
      let section = {}
      $.each(this.sections, function (key, item) {
        if (item.name === name) {
          section = item
        }
      })
      return section
    },
    getSectionIndexByName(name) {
      var sectionIndex = this.currentSectionIndex
      $.each(this.sections, function (key, section) {
        if (section.name === name) {
          sectionIndex = section.index
        }
      })
      return sectionIndex
    },
    getSectionIndexForObject(activeObjectType) {
      let sectionIndex = this.currentSectionIndex
      $.each(this.sections, function (key, section) {
        if (section.objectType === activeObjectType) {
          sectionIndex = section.index
        }
      })
      return sectionIndex
    },
    getWorkspacebarItemForSection(sectionIndex) {
      let name = ''
      if (this.sections[sectionIndex].hasOwnProperty('subheaderBarItems')) {
        name = this.sections[sectionIndex].subheaderBarItems[0].name
      }
      return name
    },
    setPageStatus() {
      switch (this.magazinepage.status) {
        case 1: // open
          this.btnSaveVisible = true
          this.btnSubmitVisible = this.roleForUser === 3
          this.btnEditVisible = false
          this.btnApproveVisible = this.roleForUser !== 3
          this.btnReviewVisible = false
          this.locked = false
          break
        case 2: // submitted
          this.btnSaveVisible = this.roleForUser !== 3
          this.btnSubmitVisible = false
          this.btnEditVisible = false
          this.btnApproveVisible = false
          this.btnReviewVisible = this.roleForUser !== 3
          this.locked = this.roleForUser === 3
          if (this.locked) {
            this.lockedReason = 'submitted'
          }
          break
        case 3: // approved
          this.btnSaveVisible = false
          this.btnSubmitVisible = false
          this.btnEditVisible = this.roleForUser !== 3
          this.btnApproveVisible = false
          this.btnReviewVisible = false
          this.locked = true
          this.lockedReason = 'approved'
          break
        case 4: // initialising
        case 5: // pending
          this.btnSaveVisible = false
          this.btnSubmitVisible = false
          this.btnEditVisible = false
          this.btnApproveVisible = false
          this.btnReviewVisible = false
          this.locked = true
          this.lockedReason = 'initialising'
          break
      }
      if (this.locked) {
        this.sidePanelActive = false
        this.sidePanelLocked = true
      }
    },
    handleGlobalKeyDown(e) {
      this.keyCode = e.keyCode
    },
    checkViewPreset() {
      if (window.location.hash.substring(1, 11) === 'viewpreset' && !this.spineActive) {
        let viewPreset = window.location.hash.replace('#viewpreset=', '').split('-')
        this.viewPresetSidepanelItem = viewPreset[0]
        if (viewPreset.length > 1) {
          this.viewPresetOverviewType = viewPreset[1]
        }
      }
      window.location.hash = ''
      history.pushState('', document.title, window.location.pathname)
    },
    redirectToSidepanelItem() {
      if (this.viewPresetSidepanelItem !== '') {
        this.activateSidePanel(this.getSectionIndexByName(this.viewPresetSidepanelItem))
      }
    },
    setOverviewType() {
      if (this.viewPresetSidepanelItem === 'overview' && this.viewPresetOverviewType !== '') {
        bus.$emit('overviewTypeChanged', this.viewPresetOverviewType)
      }
    },
    getSubheaderBarItemClass(subheaderBarItem) {
      if (subheaderBarItem.name === 'crop' && this.activeObjectType === 'image' && !this.imageCropperAvailable) {
        return 'disabled btn-disabled-light'
      }
      if (
        subheaderBarItem.name === 'filters' &&
        this.activeObjectType === 'placeholder' &&
        !this.activeObject.hasImage
      ) {
        return 'disabled btn-disabled-light'
      }
      if (this.currentWorkspacebarItem === subheaderBarItem.name) {
        return 'active'
      }
      return ''
    },
    bindPusher() {
      let creationChannel = this.$pusher.subscribe('private-creation.' + this.magazine.id)
      creationChannel.bind('CreationCanvasUpdated', function (data) {
        bus.$emit('CreationCanvasUpdated', data.canvas)
      })
      creationChannel.bind('CreationDocumentImported', function (data) {
        bus.$emit('CreationDocumentImported', data.document)
      })

      let settingsChannel = this.$pusher.subscribe('system')
      settingsChannel.bind('SystemSettingUpdated', (data) => {
        this.updateSetting(data.systemSetting)
      })
    },
    updateSetting(setting) {
      let settings = this.settings
      let index = settings.findIndex((item) => item.name === setting.name)
      settings.splice(index, 1)
      settings.push(setting)
      this.$store.commit('setSettings', settings)
      if (setting.name === 'SYSTEM_URGENT_MESSAGE') {
        if (!this.maintenanceModeActive) {
          //this.checkSystemMessage();
        }
      }
      if (setting.name === 'SYSTEM_IN_MAINTENANCE') {
        if (this.maintenanceModeActive && !setting.active) {
          window.location.href = window.location.href
        } else {
          this.checkMaintenanceMode()
        }
      }
    },
    checkMaintenanceMode() {
      let index = this.settings.findIndex((item) => item.name === 'SYSTEM_IN_MAINTENANCE')
      this.maintenanceModeActive = this.settings[index].active
    },
    checkSystemMessage() {
      let index = this.settings.findIndex((item) => item.name === 'SYSTEM_URGENT_MESSAGE')
      this.systemMessage = this.settings[index]['content_' + this.user.locale]
      this.systemMessageType = this.settings[index].content
      this.systemMessageVisible = this.settings[index].active
      this.$refs.fabric.resizeWorkspace(this.systemMessageVisible)
    },
    hideSystemMessage() {
      this.systemMessageVisible = false
      this.$refs.fabric.resizeWorkspace(this.systemMessageVisible)
    },
  },

  created() {
    this.$on('loading', function (boolean) {
      this.loading = boolean
    })
    this.$on('resourcesLoaded', function () {
      this.loading = false
      this.setPageStatus()

      this.$refs.fabric.initialise()

      this.resourcesLoaded = true

      if (this.locked) {
        this.sidePanelActive = false
        this.sidePanelLocked = true
      } else {
        this.checkViewPreset()
        this.redirectToSidepanelItem()
        this.bindShortcuts()
      }
      this.bindPusher()
    })
    bus.$on('activeObjectTypeChanged', (activeObjectType) => {
      this.activeObjectType = activeObjectType
      this.activeObject = activeObjectType !== '' ? this.canvas.getActiveObject() : {}
      let sectionIndex = activeObjectType !== '' ? this.getSectionIndexForObject(activeObjectType) : 0
      let subheaderBarItem = activeObjectType !== '' ? this.getWorkspacebarItemForSection(sectionIndex) : ''
      if (activeObjectType === '') {
        if (this.ignoreDeselect) {
          this.ignoreDeselect = false
        } else {
          let currentSection = this.getSectionByName(this.currentSidePanelItem)
          if (currentSection.hasOwnProperty('activeNavButton')) {
            sectionIndex = this.getSectionIndexByName(currentSection.activeNavButton)
          }
          this.activateSidePanel(sectionIndex)
          this.currentWorkspacebarItem = ''
          this.workspaceToolsActive = false
        }
      } else {
        if (this.activeObject.hasOwnProperty('iconId') && this.activeObjectType !== 'placeholder') {
          this.ignoreDeselect = true
        } else {
          this.activateSidePanel(sectionIndex)
          this.activateWorkspacebarItem(subheaderBarItem)
          this.workspaceToolsActive = true
        }
      }
    })
    bus.$on('canvasLoading', (boolean) => {
      this.canvasLoading = boolean
    })
    bus.$on('canvasInitialised', (boolean) => {
      this.canvasInitialised = boolean
      this.canvasBackgroundColor = this.canvas.backgroundColor
      this.setOverviewType()
    })
    bus.$on('objectFullyOnCanvas', (isFullyOnCanvas) => {
      this.imageCropperAvailable = this.activeObjectType === 'image' && isFullyOnCanvas
    })
    bus.$on('canvasBackgroundColorChanged', (color) => {
      this.canvasBackgroundColor = color
    })
    bus.$on('inputFocussed', (boolean) => {
      this.inputFocussed = boolean
    })

    // When it is 1AM to 2AM NL time, show a system message. At 2 AM show a different message until 3AM. At 3AM remove the message. This should happen every day.
    // this.systemMessageVisible = true
    // this.systemMessage = 'This is a system message'
    // this.systemMessageType = 'info'

    setTimeout(() => {
      this.currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Amsterdam', hour: 'numeric' })
    }, 1000 * 5)

    setInterval(() => {
      this.currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Amsterdam', hour: 'numeric' })
    }, 1000 * 60)
  },

  mounted() {
    this.getConstantsFromRequest()
    this.setDefaultSidePanelItem()
    this.getResources()

    const that = this

    window.addEventListener('keydown', function (e) {
      that.handleGlobalKeyDown(e)
    })
  },
}
</script>

<style></style>
