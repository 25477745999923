<template>
  <div class="wrapper">
    <div class="spinner" v-show="loading">
      <mdb-spinner small multicolor />
    </div>
    <div class="content content-with-tabs">
      <div class="sidepanel-tabs">
        <a
          :class="{ active: activeTab == 'folders' }"
          @click="activeTab = 'folders'"
          >{{ Lang.get('sidepanel.tabs.folders') }}</a
        >
        <a
          :class="{ active: activeTab == 'documents' }"
          @click="activeTab = 'documents'"
          >{{ Lang.get('sidepanel.tabs.documents') }}</a
        >
      </div>
      <image-directories-list
        key="imageDirectories"
        v-show="
          activeTab == 'folders' &&
          activeTabFoldersContent == 'imageDirectories'
        "
      >
      </image-directories-list>
      <image-directory
        key="imageDirectory"
        v-show="
          activeTab == 'folders' && activeTabFoldersContent == 'imageDirectory'
        "
        :image-directory="currentImageDirectory"
      >
      </image-directory>
      <pdf-documents-list
        key="pdfDocuments"
        v-show="
          activeTab == 'documents' &&
          activeTabDocumentsContent == 'pdfDocuments'
        "
      >
      </pdf-documents-list>
      <pdf-document
        key="pdfDocument"
        v-show="
          activeTab == 'documents' && activeTabDocumentsContent == 'pdfDocument'
        "
        :pdf-document="currentPdfDocument"
      >
      </pdf-document>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import resources from '../mixins/resources.js'
import Lang from '../utils/lang'

export default {
  mixins: [resources],
  data() {
    return {
      Lang: Lang,
      loading: false,
      activeTab: 'folders',
      activeTabFoldersContent: 'imageDirectories',
      activeTabDocumentsContent: 'pdfDocuments',
      currentImageDirectory: {},
      currentPdfDocument: {},
    }
  },
  watch: {
    currentImageDirectory: function () {
      if (this.currentImageDirectory.hasOwnProperty('id')) {
        this.loading = true
        this.getImages('custom', this.currentImageDirectory.id)
      }
    },
    currentPdfDocument: function () {
      if (this.currentPdfDocument.hasOwnProperty('id')) {
        this.loading = true
        this.getPdfDocumentPages(this.currentPdfDocument.id)
      }
    },
  },
  created() {
    bus.$on('imagesRetrieved', (id) => {
      this.loading = false
    })
    bus.$on('pdfDocumentPagesRetrieved', (id) => {
      this.loading = false
    })
  },
}
</script>
