/*
    JIL | Compatible with fabric.js v5.2.1 (2022-02-21)
*/

import { fabric } from 'fabric';

function initObjectAlignmentHelplines(canvas) {

  if (!canvas.showHelplines) {
    return false;
  }

  var ctx = canvas.getSelectionContext();
  var aligningLineOffset = 5;
  var aligningLineMargin = 4;
  var aligningLineWidth = 2;
  var aligningLineColor = 'rgb(239,85,51)';
  var viewportTransform;
  var zoom = 1;

  function drawVerticalLine(coords) {
    drawLine(
      coords.x + 0.5,
      coords.y1 > coords.y2 ? coords.y2 : coords.y1,
      coords.x + 0.5,
      coords.y2 > coords.y1 ? coords.y2 : coords.y1,
    );
  }

  function drawHorizontalLine(coords) {
    drawLine(
      coords.x1 > coords.x2 ? coords.x2 : coords.x1,
      coords.y + 0.5,
      coords.x2 > coords.x1 ? coords.x2 : coords.x1,
      coords.y + 0.5,
    );
  }

  function drawLine(x1, y1, x2, y2) {
    ctx.save();
    ctx.lineWidth = aligningLineWidth;
    ctx.strokeStyle = aligningLineColor;
    ctx.beginPath();
    ctx.moveTo(((x1 + viewportTransform[4]) * zoom), ((y1 + viewportTransform[5]) * zoom));
    ctx.lineTo(((x2 + viewportTransform[4]) * zoom), ((y2 + viewportTransform[5]) * zoom));
    ctx.stroke();
    ctx.restore();
  }

  function isInRange(value1, value2) {
    value1 = Math.round(value1);
    value2 = Math.round(value2);
    for (let i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
      if (i === value2) {
        return true;
      }
    }
    return false;
  }

  var verticalLines = [];
  var horizontalLines = [];

  canvas.on('mouse:down', function() {
    viewportTransform = canvas.viewportTransform;
    zoom = canvas.getZoom();
  });

  canvas.on('object:moving', function(e) {
    var activeObject = e.target;
    let renderHelpLines = !(activeObject.hasOwnProperty('iconId'));
    var canvasObjects = canvas.getObjects();
    var activeObjectCenter = activeObject.getCenterPoint();
    var activeObjectLeft = activeObjectCenter.x;
    var activeObjectTop = activeObjectCenter.y;
    var activeObjectBoundingRect = activeObject.getBoundingRect();
    var activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3];
    var activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0];
    var horizontalInTheRange = false;
    var verticalInTheRange = false;
    var transform = canvas._currentTransform;

    if (!transform || !renderHelpLines) return;

    for (let i = canvasObjects.length; i--;) {

      if (canvasObjects[i] === activeObject) continue;

      var objectCenter = canvasObjects[i].getCenterPoint();
      var objectLeft = objectCenter.x;
      var objectTop = objectCenter.y;
      var objectBoundingRect = canvasObjects[i].getBoundingRect();
      var objectHeight = objectBoundingRect.height / viewportTransform[3];
      var objectWidth = objectBoundingRect.width / viewportTransform[0];

      // Snap by the horizontal center line
      if (isInRange(objectLeft, activeObjectLeft)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft,
          y1: (objectTop < activeObjectTop) ?
            (objectTop - objectHeight / 2 - aligningLineOffset) :
            (objectTop + objectHeight / 2 + aligningLineOffset),
          y2: (activeObjectTop > objectTop) ?
            (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset) :
            (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset),
        });
        activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center');
      }

      // Snap by the left edge
      if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft - objectWidth / 2,
          y1: (objectTop < activeObjectTop) ?
            (objectTop - objectHeight / 2 - aligningLineOffset) :
            (objectTop + objectHeight / 2 + aligningLineOffset),
          y2: (activeObjectTop > objectTop) ?
            (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset) :
            (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset),
        });
        activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), 'center', 'center');
      }

      // Snap by the right edge
      if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft + objectWidth / 2,
          y1: (objectTop < activeObjectTop) ?
            (objectTop - objectHeight / 2 - aligningLineOffset) :
            (objectTop + objectHeight / 2 + aligningLineOffset),
          y2: (activeObjectTop > objectTop) ?
            (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset) :
            (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset),
        });
        activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
      }

      // Snap by the vertical center line
      if (isInRange(objectTop, activeObjectTop)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop,
          x1: (objectLeft < activeObjectLeft) ?
            (objectLeft - objectWidth / 2 - aligningLineOffset) :
            (objectLeft + objectWidth / 2 + aligningLineOffset),
          x2: (activeObjectLeft > objectLeft) ?
            (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset) :
            (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset),
        });
        activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
      }

      // Snap by the top edge
      if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop - objectHeight / 2,
          x1: (objectLeft < activeObjectLeft) ?
            (objectLeft - objectWidth / 2 - aligningLineOffset) :
            (objectLeft + objectWidth / 2 + aligningLineOffset),
          x2: (activeObjectLeft > objectLeft) ?
            (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset) :
            (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset),
        });
        activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
      }

      // Snap by the bottom edge
      if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop + objectHeight / 2,
          x1: (objectLeft < activeObjectLeft) ?
            (objectLeft - objectWidth / 2 - aligningLineOffset) :
            (objectLeft + objectWidth / 2 + aligningLineOffset),
          x2: (activeObjectLeft > objectLeft) ?
            (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset) :
            (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset),
        });
        activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
      }
    }

    if (!horizontalInTheRange) {
      horizontalLines.length = 0;
    }

    if (!verticalInTheRange) {
      verticalLines.length = 0;
    }
  });

  canvas.on('before:render', function() {
    canvas.clearContext(ctx);
  });

  canvas.on('after:render', function(e) {
    if (canvas.showHelplines) {
      for (let i = verticalLines.length; i--;) {
        drawVerticalLine(verticalLines[i]);
      }
      for (let i = horizontalLines.length; i--;) {
        drawHorizontalLine(horizontalLines[i]);
      }
    }
    verticalLines.length = horizontalLines.length = 0;
  });

  canvas.on('mouse:up', function() {
    verticalLines.length = horizontalLines.length = 0;
    canvas.renderAll();
  });
}

export { initObjectAlignmentHelplines };
