import { bus } from '../utils/bus'
import httpClient from '../utils/httpClient'

export default {
  methods: {
    updateCreationCanvas: function (redirect, location, status, saveContent) {
      this.$emit('loading', true)

      let magazinepage = this.$store.getters.currentMagazinepage

      if (!this.$refs.fabric.$parent.spineActive) {
        this.$refs.fabric.deselectObject()
        if (magazinepage.status !== 3) {
          this.activateSidePanel(this.getSectionIndexByName('templates'))
        }
      }

      if (this.editorType === 'spread') {
        if (this.spineVisible) {
          this.updateCreationCanvasSpreadWithSpine(
            redirect,
            location,
            status,
            saveContent
          )
        } else {
          this.updateCreationCanvasSpread(
            redirect,
            location,
            status,
            saveContent
          )
        }
      } else {
        this.updateCreationCanvasSingle(redirect, location, status, saveContent)
      }
    },
    updateCreationCanvasSingle: function (
      redirect,
      location,
      status,
      saveContent
    ) {
      this.canvas.setOverlayImage(null, () => {
        this.canvas.requestRenderAll()

        setTimeout(() => {
          Promise.all([this.saveMagazinepage(status, saveContent)]).then(() => {
            if (redirect) {
              window.location.href = location
            } else {
              this.setPageStatus()
              this.$emit('loading', false)
            }
          })
        }, 250)
      })
    },
    updateCreationCanvasSpread: function (
      redirect,
      location,
      status,
      saveContent
    ) {
      this.canvas.setOverlayImage(null, () => {
        this.canvas.requestRenderAll()

        this.canvasExtra.setOverlayImage(null, () => {
          this.canvasExtra.requestRenderAll()

          setTimeout(() => {
            Promise.all([
              this.saveMagazinepage(status, saveContent),
              this.saveMagazinepageExtra(status, saveContent),
            ]).then(() => {
              if (redirect) {
                window.location.href = location
              } else {
                this.setPageStatus()
                this.$emit('loading', false)
              }
            })
          }, 250)
        })
      })
    },
    updateCreationCanvasSpreadWithSpine: function (
      redirect,
      location,
      status,
      saveContent
    ) {
      this.canvas.setOverlayImage(null, () => {
        this.canvas.requestRenderAll()

        this.canvasExtra.setOverlayImage(null, () => {
          this.canvasExtra.requestRenderAll()

          setTimeout(() => {
            Promise.all([
              this.saveMagazinepage(status, saveContent),
              this.saveMagazinepageExtra(status, saveContent),
              this.saveMagazinespine(status, saveContent),
            ]).then(() => {
              if (redirect) {
                window.location.href = location
              } else {
                this.setPageStatus()
                this.$emit('loading', false)
              }
            })
          }, 250)
        })
      })
    },
    saveMagazinepage: function (status, saveContent) {
      let magazine = this.$store.getters.currentMagazine
      let magazinepage = this.$store.getters.currentMagazinepage

      let data = {}
      data.magazine_id = magazine.id
      data.content = this.$refs.fabric.getCanvasContentJSON('canvas')
      data.contentBase64 = this.$refs.fabric.getCanvasContentBase64('canvas')
      data.status = status
      data.saveContent = saveContent

      return httpClient
        .put('/canvas/' + magazinepage.id, data)
        .then((response) => {
          this.$refs.fabric.implementOverlayImage()
          magazinepage.status = data.status
          magazinepage.content = data.content
          this.$store.commit('setCurrentMagazinepage', magazinepage)
        })
    },
    saveMagazinepageExtra: function (status, saveContent) {
      let magazine = this.$store.getters.currentMagazine
      let magazinepageExtra = this.$store.getters.currentMagazinepageExtra

      let data = {}
      data.magazine_id = magazine.id
      data.content = this.$refs.fabric.getCanvasContentJSON('extra')
      data.contentBase64 = this.$refs.fabric.getCanvasContentBase64('extra')
      data.status = status
      data.saveContent = saveContent

      return httpClient
        .put('/canvas/' + magazinepageExtra.id, data)
        .then((response) => {
          this.$refs.fabric.implementOverlayImageExtra()
          magazinepageExtra.status = data.status
          magazinepageExtra.content = data.content
          this.$store.commit('setCurrentMagazinepageExtra', magazinepageExtra)
        })
    },
    saveMagazinespine: function (status, saveContent) {
      let magazine = this.$store.getters.currentMagazine
      let magazinespine = this.$store.getters.currentMagazinespine

      let data = {}
      data.magazine_id = magazine.id
      data.content = this.$refs.fabric.getCanvasContentJSON('spine')
      data.contentBase64 = this.$refs.fabric.getCanvasContentBase64('spine')
      data.status = status
      data.saveContent = saveContent

      return httpClient
        .put(
          process.env.VUE_APP_URL_API +
            '/editor/creation/' +
            magazine.id +
            '/spine',
          data
        )
        .then((response) => {
          magazinespine.status = data.status
          magazinespine.content = data.content
          this.$store.commit('setCurrentMagazinespine', magazinespine)
        })
    },
    saveCanvas: function () {
      let redirect = false
      let location = ''
      let status = this.$store.getters.currentMagazinepage.status
      let saveContent = true
      this.updateCreationCanvas(redirect, location, status, saveContent)
    },
    saveCanvasAndRedirect: function (location) {
      let redirect = true
      let status = this.$store.getters.currentMagazinepage.status
      let saveContent = true
      this.updateCreationCanvas(redirect, location, status, saveContent)
    },
    submitCanvas: function () {
      let redirect = false
      let location = ''
      let status = 2
      let saveContent = true
      this.updateCreationCanvas(redirect, location, status, saveContent)
    },
    approveCanvas: function () {
      let redirect = false
      let location = ''
      let status = 3
      let saveContent = true
      this.updateCreationCanvas(redirect, location, status, saveContent)
    },
    releaseCanvas: function () {
      let redirect = true
      let location = ''
      let status = 1
      let saveContent = false
      this.updateCreationCanvas(redirect, location, status, saveContent)
    },
  },
  mounted() {
    bus.$on('CreationCanvasUpdated', (canvas) => {})
  },
}
