<template>
  <div>
    <a
      class="btn btn-ter btn-sma btn-help"
      v-show="visible"
      :class="{ active: active }"
      @click="active = active ? false : true"
      v-b-tooltip.hover.top.v-light="{
        title: Lang.get('subheader.help.tooltip'),
      }"
    >
      <img src="/images/info.png" alt="" style="width: 18px; height: 18px; display: inline-block" />
    </a>
    <div id="help-menu-items" v-show="active">
      <a href="#" @click="openTour()">
        {{ Lang.get('subheader.help.tour') }}
      </a>
      <a href="https://www.youtube.com/c/JilsterNLMagzine" target="_blank" @click="active = false">
        {{ Lang.get('subheader.help.youtube') }}
      </a>
      <a href="#" v-show="canvasVersion === 'linda'" @click="openTourVideo()"
        >{{ Lang.get('subheader.help.tutorial') }}
      </a>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import utilities from '../mixins/utilities.js'
import Lang from '../utils/lang'

export default {
  mixins: [utilities],
  props: { canvasVersion: String },
  data: function () {
    return {
      Lang: Lang,
      visible: false,
      active: false,
    }
  },
  methods: {
    openTour() {
      this.$parent.$parent.$refs.tourModal.$refs.tour.show()
      this.active = false
    },
    openTourVideo() {
      this.$parent.$parent.$refs.tourVideoModal.openModal()
      this.active = false
    },
  },
  mounted() {
    bus.$on('canvasInitialised', () => {
      let tourFinished = localStorage.getItem('editor-tour-finished')
      if (tourFinished) {
        this.visible = true
      }
    })
    bus.$on('tourFinished', () => {
      this.visible = true
    })
  },
}
</script>
