<template>
  <div>
    <div class="row">
      <div class="col-lg-3 pr-0">
        <div class="form-group form-group-properties">
          <label class="mb-4"
            ><strong>{{ Lang.get('subheader.items.transform') }}</strong></label
          >
          <span class="label-inline">{{ Lang.get('subheader.labels.x') }}</span>
          <input
            v-model="left"
            class="form-control"
            type="number"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
      <div class="col-lg-3 pr-0">
        <div class="form-group form-group-properties">
          <label class="mb-4"><strong>&nbsp;</strong></label>
          <span class="label-inline">{{ Lang.get('subheader.labels.y') }}</span>
          <input
            v-model="top"
            class="form-control"
            type="number"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group form-group-properties">
          <label class="mb-4"><strong>&nbsp;</strong></label>
          <input
            v-model="angle"
            class="form-control"
            type="number"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
          <span class="label-rotation">{{ Lang.get('sidepanel.labels.rotation') }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 pr-0">
        <div class="form-group form-group-properties">
          <span class="label-inline">{{ Lang.get('subheader.labels.w') }}</span>
          <input
            v-model="width"
            class="form-control"
            type="number"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
      <div class="col-lg-3 pr-0">
        <div class="form-group form-group-properties">
          <span class="label-inline">{{ Lang.get('subheader.labels.h') }}</span>
          <input
            v-model="height"
            class="form-control"
            type="number"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
    </div>

    <div class="form-group form-group-range-input">
      <label>{{ Lang.get('subheader.items.transparency') }}</label>
      <div class="row">
        <div class="col">
          <input v-model="transparency" type="range" min="0" max="100" step="1" />
          <input
            type="number"
            class="form-control"
            v-model="transparency"
            min="0"
            max="100"
            step="1"
            @focus="setInputFocussed()"
            @blur="setInputBlurred()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import utilities from '../mixins/utilities.js'
import Lang from '../utils/lang'

export default {
  mixins: [utilities],
  data: function () {
    return {
      Lang: Lang,
      object: {},
      objectType: '',
      muteWatchers: false,
      left: 0,
      top: 0,
      width: 0,
      height: 0,
      angle: 0,
      opacity: 0,
      transparency: 0,
    }
  },
  watch: {
    object: function () {
      if (!this.muteWatchers) {
        if (Object.keys(this.object).length > 0) {
          this.getProperties()
        }
      }
    },
    left: function () {
      if (!this.muteWatchers) {
        this.setLeft()
      }
    },
    top: function () {
      if (!this.muteWatchers) {
        this.setTop()
      }
    },
    width: function () {
      if (!this.muteWatchers) {
        if (this.width < 1) {
          this.width = 1
        } else {
          this.setWidth()
        }
      }
    },
    height: function () {
      if (!this.muteWatchers) {
        if (this.height < 1) {
          this.height = 1
        } else {
          this.setHeight()
        }
      }
    },
    angle: function () {
      if (!this.muteWatchers) {
        this.setAngle()
      }
    },
    opacity: function () {
      this.setTransparency()
    },
    transparency() {
      if (!this.muteWatchers) {
        this.setOpacity()
      }
    },
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
  },
  methods: {
    getProperties() {
      if (this.object && Object.keys(this.object).length) {
        this.left = Math.round(this.object.left)
        this.top = Math.round(this.object.top)
        this.width = Math.round(this.object.getScaledWidth())
        this.height = Math.round(this.object.getScaledHeight())
        if (this.object.stroke !== null) {
          this.width -= this.object.strokeWidth
          this.height -= this.object.strokeWidth
        }
        this.angle = Math.round(this.object.angle)
        if (this.object.type === 'placeholder' && this.object.hasImage) {
          this.opacity = this.object.image.opacity
        } else {
          this.opacity = this.object.opacity
        }
      }
    },
    setLeft() {
      this.object.set('left', isNaN(parseInt(this.left)) ? 0 : parseInt(this.left))
      if (this.objectType === 'placeholder') {
        this.object.handlePlaceholderModified()
      }
      this.canvas.requestRenderAll()
    },
    setTop() {
      this.object.set('top', isNaN(parseInt(this.top)) ? 0 : parseInt(this.top))
      if (this.objectType === 'placeholder') {
        this.object.handlePlaceholderModified()
      }
      this.canvas.requestRenderAll()
    },
    setWidth() {
      this.object.set('scaleX', parseInt(this.width) / this.object.width)
      if (this.objectType === 'placeholder') {
        this.object.handlePlaceholderModified()
      }
      this.canvas.requestRenderAll()
    },
    setHeight() {
      if (this.object.type == 'line') {
        this.object.set('strokeWidth', parseInt(this.height))
      } else {
        this.object.set('scaleY', parseInt(this.height) / this.object.height)
        if (this.objectType === 'placeholder') {
          this.object.handlePlaceholderModified()
        }
      }
      this.canvas.requestRenderAll()
    },
    setAngle() {
      this.object.set('centeredRotation', true)
      this.object.rotate(this.angle)
      if (this.objectType === 'placeholder') {
        this.object.handlePlaceholderRotating()
        this.object.handlePlaceholderModified()
      }
      this.canvas.requestRenderAll()
    },
    setTransparency() {
      this.transparency = this.convertOpacityToTransparency(this.opacity)
    },
    setOpacity() {
      if (this.object.type === 'placeholder' && this.object.hasImage) {
        this.object.image.opacity = this.convertTransparencyToOpacity(this.transparency)
      } else if (this.object.type !== 'placeholder') {
        this.object.opacity = this.convertTransparencyToOpacity(this.transparency)
      }
      this.canvas.requestRenderAll()
    },
    setInputFocussed: function () {
      bus.$emit('inputFocussed', true)
    },
    setInputBlurred: function () {
      bus.$emit('inputFocussed', false)
    },
  },
  mounted() {
    this.object = this.$parent.$parent.activeObject
    this.objectType = this.$parent.$parent.activeObjectType
    bus.$on('activeObjectTypeChanged', (activeObjectType) => {
      this.objectType = activeObjectType
      this.object = activeObjectType !== '' ? this.canvas.getActiveObject() : {}
    })
    bus.$on('canvas:object:moving', (e) => {
      this.muteWatchers = true
      this.getProperties()
    })
    bus.$on('canvas:object:scaling', (e) => {
      this.muteWatchers = true
      this.getProperties()
    })
    bus.$on('canvas:object:rotating', (e) => {
      this.muteWatchers = true
      this.getProperties()
    })
    bus.$on('canvas:object:modified', (e) => {
      setTimeout(() => (this.muteWatchers = false), 500)
      this.getProperties()
    })
    bus.$on('strokeWidthChanged', (e) => {
      this.muteWatchers = true
      setTimeout(() => (this.muteWatchers = false), 500)
    })
    bus.$on('strokeChanged', (e) => {
      this.muteWatchers = true
      setTimeout(() => (this.muteWatchers = false), 500)
    })
  },
}
</script>
