var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"id":"sidepanel-nav"}},[_c('ul',{staticClass:"nav"},[_vm._l((_vm.$parent.sidePanelItems),function(sidePanelItem){return (
        sidePanelItem.name !== 'spine' ||
        (sidePanelItem.name == 'spine' && _vm.$parent.spineActive)
      )?_c('li',{key:sidePanelItem.name,staticClass:"nav-item",class:{
        active:
          _vm.$parent.sidePanelActive &&
          _vm.$parent.sidePanelHighlighted == sidePanelItem.name,
        disabled:
          _vm.$parent.sidePanelLocked ||
          (sidePanelItem.name !== 'spine' && _vm.$parent.spineActive),
      },on:{"click":function($event){return _vm.$parent.activateSidePanel(sidePanelItem.index, true)}}},[_c('a',{staticClass:"nav-link"},[_c('div',{class:'icon ' + sidePanelItem.navButton.icon}),_vm._v(" "+_vm._s(_vm.Lang.get(sidePanelItem.navButton.labelLangKey))+" ")])]):_vm._e()}),(_vm.$parent.sidePanelActive)?_c('li',{staticClass:"nav-item",on:{"click":function($event){_vm.$parent.sidePanelActive = false}}},[_vm._m(0)]):_vm._e(),(!_vm.$parent.sidePanelActive && !_vm.$parent.sidePanelLocked)?_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.$parent.activateSidePanel()}}},[_vm._m(1)]):_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link"},[_c('div',{staticClass:"icon icon-sidebar-close"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link"},[_c('div',{staticClass:"icon icon-sidebar-open"})])
}]

export { render, staticRenderFns }