<template>
  <b-container fluid id="maintenance">
    <div class="jilster_maintenace_mask-container">
      <div class="jilster_maintenace_mask"></div>
    </div>
    <h1>{{ Lang.get('maintenance.header') }}</h1>
    <p>{{ Lang.get('maintenance.text') }}</p>
    <button @click="goToWebsite()">{{ Lang.get('maintenance.button') }}</button>
  </b-container>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'MaintenanceMode',
  data: function () {
    return { Lang: Lang }
  },
  methods: {
    goToWebsite() {
      window.open('https://jilster.app', '_blank')
    },
  },
}
</script>
