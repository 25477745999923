/*
    JIL | Compatible with fabric.js v5.2.1 (2022-02-21)
*/

;(function () {
  'use strict'

  /**
   * Columntextbox class, based on Textbox. The Columntextbox allows the
   * text to be divided into multiple columns.
   * @class fabric.Columntextbox
   * @extends fabric.Textbox
   * @mixes fabric.Observable
   * @return {fabric.Columntextbox} thisArg
   */
  fabric.Columntextbox = fabric.util.createClass(fabric.Textbox, fabric.Observable, {
    /**
     * Type of an object
     * @type String
     * @default
     */
    type: 'columntextbox',

    /**
     * The number of columns.
     * @type Number
     * @default
     */
    columns: 1,

    /**
     * The width of the gap in between the columns, in pixels.
     * @type Number
     * @default
     */
    columnGap: 15,

    /**
     * The width of the columns, in pixels.
     * @type Number
     */
    columnWidth: null,

    /**
     * Properties which when set cause object to change dimensions
     * @type Object
     * @private
     */
    _dimensionAffectingProps: fabric.Text.prototype._dimensionAffectingProps.concat(['width', 'columns', 'columnGap']),

    initialize: function (text, options) {
      this.callSuper('initialize', text, options)
    },

    /**
     * @private
     * @override
     */
    initDimensions: function () {
      this.callSuper('initDimensions')
      this.columnWidth = Math.floor(this.width / this.columns) - ((this.columns - 1) * this.columnGap) / this.columns
    },

    /** JIL | From /src/shapes/textbox.class.js
     * Gets lines of text to render in the Textbox. This function calculates
     * text wrapping on the fly every time it is called.
     * @param {String} text text to split
     * @returns {Array} Array of lines in the Textbox.
     * @override
     */
    _splitTextIntoLines: function (text) {
      var newText = fabric.Text.prototype._splitTextIntoLines.call(this, text),
        graphemeLines = this._wrapText(newText.lines, this.columnWidth),
        lines = new Array(graphemeLines.length)
      for (var i = 0; i < graphemeLines.length; i++) {
        lines[i] = graphemeLines[i].join('')
      }
      newText.lines = lines
      newText.graphemeLines = graphemeLines
      return newText
    },

    /** JIL | From /src/shapes/text.class.js
     * calculate text box height
     */
    calcTextHeight: function () {
      var lineHeight,
        height = 0,
        maxHeight = 0,
        linesPerColumn = Math.ceil(this._textLines.length / this.columns),
        currentColumn = 0,
        previousColumn = -1

      for (var i = 0, len = this._textLines.length; i < len; i++) {
        if (i >= linesPerColumn) {
          currentColumn = Math.floor(i / linesPerColumn)
        }
        if (currentColumn != previousColumn) {
          height = 0
          previousColumn = currentColumn
        }
        lineHeight = this.getHeightOfLine(i)
        height += i === len - 1 ? lineHeight / this.lineHeight : lineHeight
        if (height > maxHeight) {
          maxHeight = height
        }
      }
      return maxHeight
    },

    /** JIL | From /src/mixins/itext_click_behavior.mixin.js
     * Returns index of a character corresponding to where an object was clicked
     * @param {Event} e Event object
     * @return {Number} Index of a character
     */
    getSelectionStartFromPointer: function (e) {
      var mouseOffset = this.getLocalPointer(e),
        prevWidth = 0,
        width = 0,
        height = 0,
        charIndex = 0,
        lineIndex = 0,
        lineLeftOffset,
        line,
        columnIndex = Math.max(Math.floor(mouseOffset.x / (this.columnWidth + this.columnGap)), 0),
        linesPerColumn = Math.ceil(this._textLines.length / this.columns)

      for (var i = 0, len = this._textLines.length; i < len; i++) {
        if (height <= mouseOffset.y) {
          height += this.getHeightOfLine(i) * this.scaleY
          lineIndex = i + linesPerColumn * columnIndex
          if (lineIndex > len - 1) {
            lineIndex = len - 1
          }
        } else {
          break
        }
      }
      for (var k = 0; k < lineIndex; k++) {
        charIndex += this._textLines[k].length + 1
      }
      lineLeftOffset = this._getLineLeftOffset(lineIndex)
      lineLeftOffset += columnIndex * (this.columnWidth + this.columnGap)
      width = lineLeftOffset * this.scaleX
      line = this._textLines[lineIndex]
      for (var j = 0, jlen = line.length; j < jlen; j++) {
        prevWidth = width
        width += this.__charBounds[lineIndex][j].kernedWidth * this.scaleX
        if (width <= mouseOffset.x) {
          charIndex++
        } else {
          break
        }
      }
      return this._getNewSelectionStartFromOffset(mouseOffset, prevWidth, width, charIndex, jlen)
    },

    /** JIL | From /src/shapes/text.class.js
     * @private
     * @param {Number} lineIndex index text line
     * @return {Number} Line left offset
     */
    _getLineLeftOffset: function (lineIndex) {
      var lineWidth = this.getLineWidth(lineIndex)
      if (this.textAlign === 'center') {
        return (this.columnWidth - lineWidth) / 2
      }
      if (this.textAlign === 'right') {
        return this.columnWidth - lineWidth
      }
      if (this.textAlign === 'justify-center' && this.isEndOfWrapping(lineIndex)) {
        return (this.width - lineWidth) / 2
      }
      if (this.textAlign === 'justify-right' && this.isEndOfWrapping(lineIndex)) {
        return this.width - lineWidth
      }
      return 0
    },

    /** JIL | From /src/shapes/itext.class.js
     * @private
     * @override
     */
    _getCursorBoundariesOffsets: function (position) {
      if (this.cursorOffsetCache && 'top' in this.cursorOffsetCache) {
        return this.cursorOffsetCache
      }
      var cursorPosition = this.get2DCursorLocation(position),
        lineLeftOffset,
        lineIndex = cursorPosition.lineIndex,
        charIndex = cursorPosition.charIndex,
        topOffset = 0,
        leftOffset = 0,
        boundaries,
        linesPerColumn = Math.ceil(this._textLines.length / this.columns),
        columnIndex = Math.floor(lineIndex / linesPerColumn),
        firstLineInColumn = linesPerColumn * columnIndex

      for (var i = firstLineInColumn; i < lineIndex; i++) {
        topOffset += this.getHeightOfLine(i)
      }

      lineLeftOffset = this._getLineLeftOffset(lineIndex)
      lineLeftOffset += columnIndex * this.columnWidth + columnIndex * this.columnGap
      var bound = this.__charBounds[lineIndex][charIndex]
      bound && (leftOffset = bound.left)
      if (this.charSpacing !== 0 && charIndex === this._textLines[lineIndex].length) {
        leftOffset -= this._getWidthOfCharSpacing()
      }
      boundaries = {
        top: topOffset,
        left: lineLeftOffset + (leftOffset > 0 ? leftOffset : 0),
      }
      this.cursorOffsetCache = boundaries
      return this.cursorOffsetCache
    },

    /** JIL | From /src/shapes/itext.class.js
     * Renders text selection
     * @param {Object} boundaries Object with left/top/leftOffset/topOffset
     * @param {CanvasRenderingContext2D} ctx transformed context to draw on
     * @override
     */
    renderSelection: function (boundaries, ctx) {
      var selectionStart = this.inCompositionMode ? this.hiddenTextarea.selectionStart : this.selectionStart,
        selectionEnd = this.inCompositionMode ? this.hiddenTextarea.selectionEnd : this.selectionEnd,
        isJustify = this.textAlign.indexOf('justify') !== -1,
        start = this.get2DCursorLocation(selectionStart),
        end = this.get2DCursorLocation(selectionEnd),
        startLine = start.lineIndex,
        endLine = end.lineIndex,
        startChar = start.charIndex < 0 ? 0 : start.charIndex,
        endChar = end.charIndex < 0 ? 0 : end.charIndex,
        linesPerColumn = Math.ceil(this._textLines.length / this.columns),
        columnTopOffset = boundaries.topOffset

      for (var i = startLine; i <= endLine; i++) {
        var lineOffset = this._getLineLeftOffset(i) || 0,
          lineHeight = this.getHeightOfLine(i),
          realLineHeight = 0,
          boxStart = 0,
          boxEnd = 0,
          currentColumn = Math.floor(i / linesPerColumn),
          nextColumn = Math.floor((i + 1) / linesPerColumn),
          words = this.textLines[i].split(this._reSpaceAndTab)

        lineOffset += currentColumn * this.columnWidth + currentColumn * this.columnGap

        if (i === startLine) {
          boxStart = this.__charBounds[startLine][startChar].left
        }
        if (i >= startLine && i < endLine) {
          boxEnd = isJustify && !this.isEndOfWrapping(i) ? this.columnWidth : this.getLineWidth(i) || 5 // WTF is this 5?
        } else if (i === endLine) {
          if (endChar === 0) {
            boxEnd = this.__charBounds[endLine][endChar].left
          } else {
            var charSpacing = this._getWidthOfCharSpacing()
            boxEnd =
              this.__charBounds[endLine][endChar - 1].left + this.__charBounds[endLine][endChar - 1].width - charSpacing
          }
        }
        realLineHeight = lineHeight
        if (this.lineHeight < 1 || (i === endLine && this.lineHeight > 1)) {
          lineHeight /= this.lineHeight
        }
        if (this.inCompositionMode) {
          ctx.fillStyle = this.compositionColor || 'black'
          ctx.fillRect(
            boundaries.left + lineOffset + boxStart,
            boundaries.top + columnTopOffset + lineHeight,
            boxEnd - boxStart,
            1
          )
        } else {
          ctx.fillStyle = this.selectionColor
          ctx.fillRect(
            boundaries.left + lineOffset + boxStart,
            boundaries.top + columnTopOffset,
            boxEnd - boxStart,
            lineHeight
          )
        }

        if (nextColumn === currentColumn) {
          columnTopOffset += realLineHeight
        } else {
          columnTopOffset = boundaries.topOffset
          if (startLine > 0) {
            for (var j = startLine - 1; j >= 0; j--) {
              if (Math.floor(j / linesPerColumn) !== Math.floor(startLine / linesPerColumn)) {
                break
              }
              columnTopOffset -= this.getHeightOfLine(j)
            }
          }
        }
      }
    },

    /** JIL | From /src/shapes/text.class.js
     * @private
     * @param {CanvasRenderingContext2D} ctx Context to render on
     * @override
     */
    _renderTextDecoration: function (ctx, type) {
      if (!this[type] && !this.styleHas(type)) {
        return
      }
      var heightOfLine,
        size,
        _size,
        lineLeftOffset,
        dy,
        _dy,
        line,
        lastDecoration,
        leftOffset = this._getLeftOffset(),
        topOffset = this._getTopOffset(),
        top,
        boxStart,
        boxWidth,
        charBox,
        currentDecoration,
        maxHeight,
        currentFill,
        lastFill,
        charSpacing = this._getWidthOfCharSpacing(),
        linesPerColumn = Math.ceil(this._textLines.length / this.columns)

      for (var i = 0, len = this._textLines.length; i < len; i++) {
        var currentColumn = Math.floor(i / linesPerColumn),
          nextColumn = Math.floor((i + 1) / linesPerColumn)
        heightOfLine = this.getHeightOfLine(i)
        if (!this[type] && !this.styleHas(type, i)) {
          topOffset += heightOfLine
          continue
        }
        line = this._textLines[i]
        maxHeight = heightOfLine / this.lineHeight
        lineLeftOffset = this._getLineLeftOffset(i)
        lineLeftOffset += currentColumn * this.columnWidth + currentColumn * this.columnGap
        boxStart = 0
        boxWidth = 0
        lastDecoration = this.getValueOfPropertyAt(i, 0, type)
        lastFill = this.getValueOfPropertyAt(i, 0, 'fill')
        top = topOffset + maxHeight * (1 - this._fontSizeFraction)
        size = this.getHeightOfChar(i, 0)
        dy = this.getValueOfPropertyAt(i, 0, 'deltaY')
        for (var j = 0, jlen = line.length; j < jlen; j++) {
          charBox = this.__charBounds[i][j]
          currentDecoration = this.getValueOfPropertyAt(i, j, type)
          currentFill = this.getValueOfPropertyAt(i, j, 'fill')
          _size = this.getHeightOfChar(i, j)
          _dy = this.getValueOfPropertyAt(i, j, 'deltaY')
          if (
            (currentDecoration !== lastDecoration || currentFill !== lastFill || _size !== size || _dy !== dy) &&
            boxWidth > 0
          ) {
            ctx.fillStyle = lastFill
            lastDecoration &&
              lastFill &&
              ctx.fillRect(
                leftOffset + lineLeftOffset + boxStart,
                top + this.offsets[type] * size + dy,
                boxWidth,
                this.fontSize / 15
              )
            boxStart = charBox.left
            boxWidth = charBox.width
            lastDecoration = currentDecoration
            lastFill = currentFill
            size = _size
            dy = _dy
          } else {
            boxWidth += charBox.kernedWidth
          }
        }
        ctx.fillStyle = currentFill
        currentDecoration &&
          currentFill &&
          ctx.fillRect(
            leftOffset + lineLeftOffset + boxStart,
            top + this.offsets[type] * size + dy,
            boxWidth - charSpacing,
            this.fontSize / 15
          )
        if (nextColumn === currentColumn) {
          topOffset += heightOfLine
        } else {
          topOffset = this._getTopOffset()
        }
      }
      // if there is text background color no
      // other shadows should be casted
      this._removeShadow(ctx)
    },

    /** JIL | From /src/shapes/text.class.js
     * Measure a single line given its index. Used to calculate the initial
     * text bouding box. The values are calculated and stored in __lineWidths cache.
     * @private
     * @param {Number} lineIndex line number
     * @return {Number} Line width
     * @override
     */
    getLineWidth: function (lineIndex) {
      if (this.__lineWidths[lineIndex]) {
        return this.__lineWidths[lineIndex] === -1 ? this.columnWidth : this.__lineWidths[lineIndex]
      }

      var width,
        line = this._textLines[lineIndex],
        lineInfo

      if (line === '') {
        width = 0
      } else {
        lineInfo = this.measureLine(lineIndex)
        width = lineInfo.width
      }
      this.__lineWidths[lineIndex] = width
      return width
    },

    /** JIL | From /src/shapes/textbox.class.js
     * Wraps a line of text using the width of the Textbox and a context.
     * @param {Array} line The grapheme array that represent the line
     * @param {Number} lineIndex
     * @param {Number} desiredWidth width you want to wrap the line to
     * @param {Number} reservedSpace space to remove from wrapping for custom functionalities
     * @returns {Array} Array of line(s) into which the given text is wrapped to.
     * @override
     */
    _wrapLine: function (_line, lineIndex, desiredWidth, reservedSpace) {
      var lineWidth = 0,
        splitByGrapheme = this.splitByGrapheme,
        graphemeLines = [],
        line = [],
        // spaces in different languges?
        words = splitByGrapheme ? fabric.util.string.graphemeSplit(_line) : _line.split(this._wordJoiners),
        word = '',
        offset = 0,
        infix = splitByGrapheme ? '' : ' ',
        wordWidth = 0,
        infixWidth = 0,
        largestWordWidth = 0,
        lineJustStarted = true,
        additionalSpace = splitByGrapheme ? 0 : this._getWidthOfCharSpacing(),
        reservedSpace = reservedSpace || 0

      desiredWidth -= reservedSpace
      for (var i = 0; i < words.length; i++) {
        // i would avoid resplitting the graphemes
        word = fabric.util.string.graphemeSplit(words[i])
        wordWidth = this._measureWord(word, lineIndex, offset)
        offset += word.length

        lineWidth += infixWidth + wordWidth - additionalSpace

        if (lineWidth >= desiredWidth && !lineJustStarted) {
          graphemeLines.push(line)
          line = []
          lineWidth = wordWidth
          lineJustStarted = true
        } else {
          lineWidth += additionalSpace
        }

        if (!lineJustStarted) {
          line.push(infix)
        }
        line = line.concat(word)

        infixWidth = this._measureWord([infix], lineIndex, offset)
        offset++
        lineJustStarted = false
        // keep track of largest word
        if (wordWidth > largestWordWidth) {
          largestWordWidth = wordWidth
        }
      }

      i && graphemeLines.push(line)

      if (largestWordWidth * this.columns + reservedSpace > this.dynamicMinWidth) {
        this.dynamicMinWidth =
          this.columns * (largestWordWidth - additionalSpace + reservedSpace + this.columnGap) - this.columnGap
      }

      return graphemeLines
    },

    /** JIL | From /src/shapes/text.class.js
     * @private
     * @override
     * @param {CanvasRenderingContext2D} ctx Context to render on
     * @param {String} method Method name ("fillText" or "strokeText")
     */
    _renderTextCommon: function (ctx, method) {
      ctx.save()
      var lineHeights = 0,
        left = this._getLeftOffset(),
        top = this._getTopOffset(),
        offsets = this._applyPatternGradientTransform(ctx, method === 'fillText' ? this.fill : this.stroke),
        linesPerColumn = Math.ceil(this._textLines.length / this.columns),
        currentColumn = 0,
        previousColumn = -1
      for (var i = 0, len = this._textLines.length; i < len; i++) {
        var heightOfLine = this.getHeightOfLine(i),
          maxHeight = heightOfLine / this.lineHeight,
          leftOffset = this._getLineLeftOffset(i)
        if (i >= linesPerColumn) {
          currentColumn = Math.floor(i / linesPerColumn)
          leftOffset += currentColumn * this.columnWidth + currentColumn * this.columnGap
          if (currentColumn != previousColumn) {
            lineHeights = 0
          }
        }

        this._renderTextLine(
          method,
          ctx,
          this._textLines[i],
          left + leftOffset - offsets.offsetX,
          top + lineHeights + maxHeight - offsets.offsetY,
          i
        )
        lineHeights += heightOfLine
        previousColumn = currentColumn
      }
      ctx.restore()
    },

    /** JIL | From /src/shapes/text.class.js
     * Enlarge space boxes and shift the others
     * @override
     */
    enlargeSpaces: function () {
      var diffSpace, currentLineWidth, numberOfSpaces, accumulatedSpace, line, charBound, spaces
      for (var i = 0, len = this._textLines.length; i < len; i++) {
        if (this.textAlign !== 'justify' && (i === len - 1 || this.isEndOfWrapping(i))) {
          continue
        }
        accumulatedSpace = 0
        line = this._textLines[i]
        currentLineWidth = this.getLineWidth(i)
        if (currentLineWidth < this.columnWidth && (spaces = this.textLines[i].match(this._reSpacesAndTabs))) {
          numberOfSpaces = spaces.length
          diffSpace = (this.columnWidth - currentLineWidth) / numberOfSpaces
          for (var j = 0, jlen = line.length; j <= jlen; j++) {
            charBound = this.__charBounds[i][j]
            if (this._reSpaceAndTab.test(line[j])) {
              charBound.width += diffSpace
              charBound.kernedWidth += diffSpace
              charBound.left += accumulatedSpace
              accumulatedSpace += diffSpace
            } else {
              charBound.left += accumulatedSpace
            }
          }
        }
      }
    },

    /** JIL | From /src/mixins/itext_behavior.mixin.js
     * remove and reflow a style block from start to end.
     * @param {Number} start linear start position for removal (included in removal)
     * @param {Number} end linear end position for removal ( excluded from removal )
     */
    removeStyleFromTo: function (start, end) {
      var cursorStart = this.get2DCursorLocation(start, true),
        cursorEnd = this.get2DCursorLocation(end, true),
        lineStart = cursorStart.lineIndex,
        charStart = cursorStart.charIndex,
        lineEnd = cursorEnd.lineIndex,
        charEnd = cursorEnd.charIndex,
        i,
        styleObj
      if (lineStart !== lineEnd) {
        // step1 remove the trailing of lineStart
        if (this.styles[lineStart]) {
          for (i = charStart; i < this._unwrappedTextLines[lineStart].length; i++) {
            delete this.styles[lineStart][i]
          }
        }
        // step2 move the trailing of lineEnd to lineStart if needed
        if (this.styles[lineEnd]) {
          for (i = charEnd; i < this._unwrappedTextLines[lineEnd].length; i++) {
            styleObj = this.styles[lineEnd][i]
            if (styleObj) {
              this.styles[lineStart] || (this.styles[lineStart] = {})
              this.styles[lineStart][charStart + i - charEnd] = styleObj
            }
          }
        }
        // step3 detects lines will be completely removed.
        for (i = lineStart + 1; i <= lineEnd; i++) {
          delete this.styles[i]
        }
        // step4 shift remaining lines.
        this.shiftLineStyles(lineEnd, lineStart - lineEnd)
      } else {
        // remove and shift left on the same line
        if (this.styles[lineStart]) {
          styleObj = this.styles[lineStart]
          var diff = charEnd - charStart,
            numericChar,
            _char
          for (i = charStart; i < charEnd; i++) {
            delete styleObj[i]
          }
          for (_char in this.styles[lineStart]) {
            numericChar = parseInt(_char, 10)
            if (numericChar >= charEnd) {
              styleObj[numericChar - diff] = styleObj[_char]
              delete styleObj[_char]
            }
          }
        }
      }
    },

    toObject: function (propertiesToInclude) {
      return this.callSuper('toObject', ['columns', 'columnWidth', 'columnGap'].concat(propertiesToInclude))
    },
  })

  /**
   * Returns fabric.Columntextbox instance from an object representation
   * @static
   * @memberOf fabric.Columntextbox
   * @param {Object} object Object to create an instance from
   * @param {Function} [callback] Callback to invoke when an fabric.Columntextbox instance is created
   */
  fabric.Columntextbox.fromObject = function (object, callback) {
    return fabric.Object._fromObject('Columntextbox', object, callback, 'text')
  }
})(typeof exports !== 'undefined' ? exports : this)
