<template>
  <div ref="zoomControls" id="zoom-controls" v-show="visible && !$parent.$parent.locked">
    <div
      class="zoom-handle"
      @mouseover="handleHovered = true"
      @mouseleave="handleHovered = false"
      @mousedown="dragMouseDown"
      :class="{ hovered: handleHovered }"
    >
      <div class="icon icon-menu-vertical" v-if="!handleHovered"></div>
      <div class="icon icon-drag" v-if="handleHovered"></div>
    </div>
    <div
      class="icon icon-zoom-out float-left"
      @click.prevent="zoomCanvas('out')"
      v-b-tooltip.hover.top="{ title: Lang.get('canvas.controls.zoom-out') }"
    ></div>
    <span class="zoom-percentage unselectable"> {{ Math.round($parent.canvasScale * 100) }}% </span>
    <div
      class="icon icon-zoom-in float-right"
      @click.prevent="zoomCanvas('in')"
      v-b-tooltip.hover.top="{ title: Lang.get('canvas.controls.zoom-in') }"
    ></div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'ZoomControls',
  data: function () {
    return {
      Lang: Lang,
      visible: false,
      handleHovered: false,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      boundaries: {
        minX: 0,
        maxX: 0,
        minY: 0,
        maxY: 0,
      },
    }
  },
  methods: {
    setInitialPosition: function (systemMessageVisible) {
      let workspaceRef = this.$parent.$parent.$refs.workspace
      let fabricRef = this.$parent.$parent.$refs.fabric
      let editorType = this.$parent.$parent.editorType
      let activePagePosition = this.$parent.$parent.activePagePosition
      let spineActive = this.$parent.$parent.spineActive
      let spineVisible = this.$parent.$parent.spineVisible
      if (this.$refs.zoomControls) {
        if (editorType === 'page') {
          this.$refs.zoomControls.style.top = workspaceRef.clientHeight - 37 + 'px'
          this.$refs.zoomControls.style.left = workspaceRef.clientWidth / 2 + fabricRef.canvasWidth / 2 + 9 + 'px'
        } else {
          if (activePagePosition === 'left') {
            this.$refs.zoomControls.style.top = workspaceRef.clientHeight - 44 + 'px'
            this.$refs.zoomControls.style.left = workspaceRef.clientWidth / 2 - fabricRef.canvasWidth / 2 - 58 + 'px'
          } else {
            if (!spineVisible) {
              this.$refs.zoomControls.style.top = workspaceRef.clientHeight - 44 + 'px'
              this.$refs.zoomControls.style.left = workspaceRef.clientWidth / 2 + fabricRef.canvasWidth / 2 - 58 + 'px'
            } else {
              this.$refs.zoomControls.style.top = workspaceRef.clientHeight - 44 + 'px'
              this.$refs.zoomControls.style.left =
                workspaceRef.clientWidth / 2 + fabricRef.spineWidth + fabricRef.canvasWidth / 2 - 58 + 'px'
            }
          }
        }

        if (systemMessageVisible) {
          this.$refs.zoomControls.style.top = parseInt(this.$refs.zoomControls.style.top.replace('px', '')) - 44 + 'px'
          this.boundaries.maxY = this.$parent.$parent.$refs.workspace.clientHeight - 76
        } else {
          this.boundaries.maxY = this.$parent.$parent.$refs.workspace.clientHeight - 32
        }
      }

      this.visible = !spineActive
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()

      if (this.checkBoundaryExceeded('left')) {
        this.$refs.zoomControls.style.left = this.boundaries.minX + 1 + 'px'
      } else if (this.checkBoundaryExceeded('right')) {
        this.$refs.zoomControls.style.left = this.boundaries.maxX - 1 + 'px'
      } else if (this.checkBoundaryExceeded('top')) {
        this.$refs.zoomControls.style.top = this.boundaries.minY + 1 + 'px'
      } else if (this.checkBoundaryExceeded('bottom')) {
        this.$refs.zoomControls.style.top = this.boundaries.maxY - 1 + 'px'
      } else {
        this.positions.movementX = this.positions.clientX - event.clientX
        this.positions.movementY = this.positions.clientY - event.clientY
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY
        this.$refs.zoomControls.style.top = this.$refs.zoomControls.offsetTop - this.positions.movementY + 'px'
        this.$refs.zoomControls.style.left = this.$refs.zoomControls.offsetLeft - this.positions.movementX + 'px'
      }
    },
    checkBoundaryExceeded: function (boundary) {
      let exceeded = false
      switch (boundary) {
        case 'left':
          exceeded = this.$refs.zoomControls.offsetLeft < this.boundaries.minX
          break
        case 'right':
          exceeded = this.$refs.zoomControls.offsetLeft >= this.boundaries.maxX
          break
        case 'top':
          exceeded = this.$refs.zoomControls.offsetTop < this.boundaries.minY
          break
        case 'bottom':
          exceeded = this.$refs.zoomControls.offsetTop >= this.boundaries.maxY
          break
      }
      return exceeded
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    zoomCanvas: function (direction) {
      this.$parent.zoomCanvas(direction)
    },
  },
  mounted() {
    this.boundaries.minX = -350
    this.boundaries.maxX = this.$parent.$parent.$refs.workspace.clientWidth - 106
    this.boundaries.minY = 0
    this.boundaries.maxY = this.$parent.$parent.$refs.workspace.clientHeight - 32
    bus.$on('zoomControlsVisible', (systemMessageVisible) => {
      this.setInitialPosition(systemMessageVisible)
    })
  },
}
</script>
