<template>
  <div class="wrapper">
    <div class="content content-non-scrollable">
      <div class="sidepanel-tabs">
        <a class="active">{{ Lang.get('sidepanel.tabs.spine') }}</a>
      </div>
      <div class="row swatch-tabs">
        <div
          class="col-6 swatch-tab"
          :class="{ 'swatch-tab-active': property == 'background' }"
          @click="property = 'background'"
        >
          <div class="swatch" :style="{ 'background-color': canvas.backgroundColor }"></div>
          {{ Lang.get('sidepanel.labels.background') }}
        </div>
        <div
          class="col-6 swatch-tab"
          v-if="magazine.canvasVersion === 'linda' || magazine.canvasVersion === 'glossy'"
          :class="{ 'swatch-tab-active': property == 'text' }"
          @click="property = 'text'"
        >
          <div class="swatch" :style="{ 'background-color': object.fill }"></div>
          {{ Lang.get('sidepanel.labels.text') }}
        </div>
      </div>
      <transition mode="out-in">
        <div v-if="property == 'background' && initialised" key="background">
          <edit-color
            color-subject="canvas"
            color-property="backgroundColor"
            color-canvas-type="spine"
            show-remove-button="false"
            show-preview-swatch="false"
          >
          </edit-color>
        </div>
        <div v-if="property == 'text' && initialised" key="text">
          <edit-color
            color-subject="object"
            color-property="fill"
            color-canvas-type="spine"
            show-remove-button="false"
            show-preview-swatch="false"
          >
          </edit-color>
        </div>
      </transition>
    </div>
    <mdb-modal size="sm" class="modal-spinetext modal-legacy" centered :show="promptSpineTextVisible">
      <mdb-modal-header>
        <h1>{{ Lang.get('modals.spine.edit-text') }}</h1>
      </mdb-modal-header>
      <mdb-modal-body>
        <input type="text" class="form-control" :value="spineText" ref="spinetext" />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="sec btn-sma" @click.native="destroyModal()">{{ Lang.get('modals.buttons.cancel') }}</mdb-btn>
        <mdb-btn color="pri btn-sma" @click.native="changeSpineText()"
          ><div class="icon icon-ok2"></div>
          {{ Lang.get('modals.buttons.save') }}</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import resources from '../mixins/resources.js'
import Lang from '../utils/lang'
import EditColor from './EditColor.vue'

export default {
  mixins: [resources],
  data() {
    return {
      Lang: Lang,
      property: 'background',
      object: {},
      initialised: false,
      spineText: '',
      promptSpineTextVisible: false,
    }
  },
  watch: {
    canvas: function () {
      var _this = this
      this.initialised = false
      setTimeout(() => {
        this.canvas.getObjects().forEach(function (object) {
          _this.object = object
          _this.spineText = object.text
        })

        _this.initialised = true
        this.startListeners()
      }, 1000)
    },
  },
  computed: {
    canvas() {
      return this.$store.getters.canvasSpine
    },
    magazine() {
      return this.$store.getters.currentMagazine
    },
  },
  methods: {
    startListeners() {
      this.canvas.on('selection:created', () => {
        this.initialiseModal()
      })
    },
    initialiseModal() {
      if (this.magazine.canvasVersion === 'linda' || this.magazine.canvasVersion === 'glossy') {
        this.promptSpineTextVisible = true
      }
    },
    destroyModal() {
      this.promptSpineTextVisible = false
      this.canvas.discardActiveObject().requestRenderAll()
    },
    changeSpineText() {
      this.spineText = this.$refs.spinetext.value

      if (Object.keys(this.object).length) {
        this.object.set('text', this.spineText)
      }

      this.$parent.$refs.fabric.optimiseSpine()

      this.destroyModal()
    },
  },
  mounted() {
    bus.$on('canvasInitialised', () => {
      this.initialiseModal()
    })
  },
}
</script>
