<template>
  <div>
    <b-modal
      ref="choosePage"
      centered
      size="lg"
      modal-class="modal-choose-page"
      no-close-on-backdrop
      no-close-on-escape
      hide-header
      hide-footer
    >
      <div class="icon icon-close" @click="closeModal()"></div>
      <span class="header">{{ Lang.get('modals.page.choose_a_page_to_edit') }}</span>
      <div
        class="form-group"
        v-show="roleForUser < 3 && (magazine.canvasVersion === 'linda' || magazine.canvasVersion === 'glossy')"
      >
        <div class="btn-group btn-group-toggle" role="group">
          <button
            type="button"
            class="btn"
            :class="{ active: overviewType === 'spreads' }"
            @click="overviewType = 'spreads'"
          >
            <div class="icon icon-spread"></div>
          </button>
          <button
            type="button"
            class="btn"
            :class="{ active: overviewType === 'pages' }"
            @click="overviewType = 'pages'"
          >
            <div class="icon icon-page"></div>
          </button>
        </div>
      </div>
      <div class="button-bar" v-show="selectedMagazinepage_id !== ''">
        <a class="btn btn-acc btn-med" @click.prevent="openPage()">
          {{ Lang.get('modals.page.open_page') }}
        </a>
      </div>
      <transition mode="out-in">
        <div
          class="section-scrollable overview-pages"
          :class="'section-' + magazine.canvasVersion"
          v-if="overviewType === 'pages'"
          key="pages"
        >
          <div
            class="item"
            v-for="(magazinepage, i) in magazinepages"
            :key="magazinepage.id"
            @click="selectPage(magazinepage, null)"
            :class="{
              'item-active': magazinepage.id === selectedMagazinepage_id,
            }"
          >
            <div class="item-inner">
              <div class="item-inner-wrapper">
                <img
                  class="fluid page-thumbnail"
                  :src="getPageThumbnail(magazinepage)"
                  @error="getPlaceholderThumbnail($event)"
                  v-if="magazinepage.status > 0 && magazinepage.status < 4"
                />
                <div class="page-thumbnail-initialising" v-if="magazinepage.status > 3">
                  <div class="page-thumbnail-initialising-inner">
                    <div class="progress-bar">
                      <span class="progress"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-inner-page-number" v-show="magazinepage.id !== activeMagazinepage.id">
                <div v-if="magazinepage.status === 2" class="status-submitted icon icon-locker-filled"></div>
                <div v-if="magazinepage.status === 3" class="status-approved icon icon-ok"></div>
                <span v-if="magazinepage.type === 2 || magazinepage.type === 3">{{ magazinepage.number }}</span>
                <span v-if="magazinepage.type === 1">{{ Lang.get('sidepanel.misc.cover-short') }}</span>
                <span v-if="magazinepage.type === 4">{{ Lang.get('sidepanel.misc.backcover-short') }}</span>
              </div>
              <div class="item-inner-page-number" v-show="magazinepage.id === activeMagazinepage.id">
                <div v-if="activeMagazinepage.status === 2" class="status-submitted icon icon-locker-filled"></div>
                <div v-if="activeMagazinepage.status === 3" class="status-approved icon icon-ok"></div>
                <span v-if="magazinepage.type === 2 || magazinepage.type === 3">{{ magazinepage.number }}</span>
                <span v-if="magazinepage.type === 1">{{ Lang.get('sidepanel.misc.cover-short') }}</span>
                <span v-if="magazinepage.type === 4">{{ Lang.get('sidepanel.misc.backcover-short') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="section-scrollable overview-spreads"
          :class="'section-' + magazine.canvasVersion"
          v-if="overviewType === 'spreads'"
          key="spreads"
        >
          <div
            class="item"
            v-for="(magazinepages, i) in spreads"
            :key="i"
            @click="selectPage(magazinepages[0], magazinepages[1])"
            :class="{
              'item-active': magazinepages[0].id === selectedMagazinepage_id,
            }"
          >
            <div class="item-inner" v-for="(magazinepage, j) in magazinepages">
              <div class="item-inner-wrapper">
                <img
                  class="fluid page-thumbnail"
                  :src="getPageThumbnail(magazinepage)"
                  @error="getPlaceholderThumbnail($event)"
                  v-if="magazinepage.status > 0 && magazinepage.status < 4"
                />
                <div class="page-thumbnail-initialising" v-if="magazinepage.status > 3">
                  <div class="page-thumbnail-initialising-inner">
                    <div class="progress-bar">
                      <span class="progress"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-inner-page-number" v-show="magazinepage.id !== activeMagazinepage.id">
                <div v-if="magazinepage.status === 2" class="status-submitted icon icon-locker-filled"></div>
                <div v-if="magazinepage.status === 3" class="status-approved icon icon-ok"></div>
                <span v-if="magazinepage.type === 2 || magazinepage.type === 3">{{ magazinepage.number }}</span>
                <span v-if="magazinepage.type === 1">{{ Lang.get('sidepanel.misc.cover-short') }}</span>
                <span v-if="magazinepage.type === 4">{{ Lang.get('sidepanel.misc.backcover-short') }}</span>
              </div>
              <div class="item-inner-page-number" v-show="magazinepage.id === activeMagazinepage.id">
                <div v-if="activeMagazinepage.status === 2" class="status-submitted icon icon-locker-filled"></div>
                <div v-if="activeMagazinepage.status === 3" class="status-approved icon icon-ok"></div>
                <span v-if="magazinepage.type === 2 || magazinepage.type === 3">{{ magazinepage.number }}</span>
                <span v-if="magazinepage.type === 1">{{ Lang.get('sidepanel.misc.cover-short') }}</span>
                <span v-if="magazinepage.type === 4">{{ Lang.get('sidepanel.misc.backcover-short') }}</span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'ChoosePageModal',
  data: function () {
    return {
      Lang: Lang,
      overviewType: 'pages',
      selectedMagazinepage_id: '',
      selectedExtrapage_id: '',
    }
  },
  computed: {
    magazinepages() {
      return this.$store.getters.currentMagazine.canvasses
    },
    magazine() {
      return this.$store.getters.currentMagazine
    },
    activeMagazinepage() {
      return this.$store.getters.currentMagazinepage
    },
    roleForUser() {
      return this.$store.getters.roleForUser
    },
    spreads() {
      var spreads = []
      var spread = []

      this.magazinepages.forEach((magazinepage, i) => {
        if (i % 2) {
          spread = []
          spread.push(magazinepage)
        } else {
          if (i === 0) {
            spread.push(this.magazinepages[this.magazinepages.length - 1])
          }
          spread.push(magazinepage)
          spreads.push(spread)
        }
      })

      return spreads
    },
  },
  methods: {
    closeModal() {
      this.$refs.choosePage.hide()
    },
    getPageThumbnail(magazinepage) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_magazinepages/' +
        this.magazine.id +
        '/png_thumbnail/' +
        magazinepage.id +
        '.png'
      )
    },
    getPlaceholderThumbnail(e) {
      e.target.src = '/images/page-placeholder.png'
      e.target.className = 'placeholder page-thumbnail'
    },
    selectPage(magazinepage, extrapage) {
      if (this.selectedMagazinepage_id === magazinepage.id) {
        this.selectedMagazinepage_id = ''
        this.selectedExtrapage_id = ''
      } else {
        this.selectedMagazinepage_id = magazinepage.id
        if (this.overviewType === 'spreads') {
          this.selectedExtrapage_id = extrapage.id
        } else {
          this.selectedExtrapage_id = ''
        }
      }
    },
    openPage() {
      if (this.overviewType === 'pages') {
        window.location.href = '/' + this.magazine.id + '/' + this.selectedMagazinepage_id
      } else {
        window.location.href =
          '/' + this.magazine.id + '/' + this.selectedMagazinepage_id + '/' + this.selectedExtrapage_id + '/left'
      }
    },
  },
}
</script>
