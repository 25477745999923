<template>
  <div
    id="warnings"
    class="alarms-icon-wrapper float-right"
    v-show="warningsActive"
    v-b-tooltip.hover.left.v-warning.html="tooltipWarnings"
  >
    <div class="icon" :class="warningIconClass"></div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  data: function () {
    return {
      Lang: Lang,
      warningsActive: false,
      warningIconClass: 'icon-warning',
      warningTextMargin: false,
      warningImageQuality: false,
      warningImageOutsideCanvas: false,
      tooltipWarnings: { title: '' },
    }
  },
  methods: {
    checkWarningsActive: function () {
      if (
        this.warningTextMargin ||
        this.warningImageQuality
        // || this.warningImageOutsideCanvas
      ) {
        this.warningsActive = true
        if (this.warningTextMargin) {
          this.warningIconClass = 'icon-menu-text'
        }
        // else {
        //   this.warningIconClass = 'icon-menu-photo'
        // }
        this.updateTooltipWarnings()
      } else {
        this.warningsActive = false
        this.hideWarnings()
      }
    },
    hideWarnings: function () {
      this.tooltipWarnings = { title: '' }
      this.$root.$emit('bv::hide::tooltip', 'warnings')
    },
    updateTooltipWarnings: function () {
      setTimeout(() => {
        let warnings = '<ul>'
        if (this.warningTextMargin) {
          warnings += '<li>' + Lang.get('canvas.warnings.text-margin') + '</li>'
        }
        if (this.warningImageQuality) {
          warnings += '<li>' + Lang.get('canvas.warnings.image-quality') + '</li>'
        }
        // if (this.warningImageOutsideCanvas) {
        //   warnings +=
        //     '<li>' + Lang.get('canvas.warnings.image-outside-canvas') + '</li>'
        // }
        warnings += '</ul>'
        this.tooltipWarnings = { title: warnings }
        this.$root.$emit('bv::show::tooltip', 'warnings')
      }, 500)
    },
  },
  mounted() {
    bus.$on('warningTextMargin', (warningTextMargin) => {
      this.warningTextMargin = warningTextMargin
      this.checkWarningsActive()
    })
    bus.$on('warningImageQuality', (warningImageQuality) => {
      this.warningImageQuality = warningImageQuality
      this.checkWarningsActive()
    })
    bus.$on('warningImageOutsideCanvas', (warningImageOutsideCanvas) => {
      this.warningImageOutsideCanvas = warningImageOutsideCanvas
      this.checkWarningsActive()
    })
    bus.$on('activeObjectTypeChanged', (activeObjectType) => {
      if (activeObjectType === 'image') {
        this.warningTextMargin = false
      }
      if (activeObjectType === 'text') {
        this.warningImageQuality = false
        this.warningImageOutsideCanvas = false
      }
      if (activeObjectType === '') {
        this.hideWarnings()
      }
    })
  },
}
</script>
