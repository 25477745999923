<template>
  <div class="wrapper">
    <div class="spinner" v-show="loading">
      <mdb-spinner small multicolor />
    </div>
    <div class="content content-with-tabs">
      <div class="sidepanel-tabs">
        <a :class="{ active: activeTab == 'templates' }" @click="activeTab = 'templates'">{{
          Lang.get('sidepanel.tabs.templates')
        }}</a>
        <a :class="{ active: activeTab == 'magazines' }" @click="activeTab = 'magazines'">{{
          Lang.get('sidepanel.tabs.magazines')
        }}</a>
      </div>
      <transition mode="out-in">
        <div
          class="section-scrollable"
          v-if="activeTab == 'templates' && activeTabTemplatesContent == 'templates'"
          key="templates"
        >
          <div
            class="item"
            :class="'item-' + canvasVersion"
            v-for="(template, i) in templates"
            :key="template.id"
            @click="showTemplate($event, i)"
          >
            <img class="fluid" :src="getCoverThumbnail(template)" @error="getPlaceholderThumbnail($event)" />
            <div class="pagecount">
              {{ template.pageCount }}
              {{ Lang.get('sidepanel.misc.pagecount') }}
            </div>
          </div>
        </div>
        <div
          class="section-scrollable"
          v-if="activeTab == 'templates' && activeTabTemplatesContent == 'template'"
          key="template"
        >
          <div class="item item-back" :class="'item-' + canvasVersion" @click="showTemplates()">
            <div class="icon icon-chevron-left"></div>
            Back to overview
          </div>
          <div
            class="item"
            v-for="(templatepage, i) in templatepages"
            :key="templatepage.id"
            :class="[{ 'item-cover': i == 0 }, 'item-' + canvasVersion]"
            @click="confirmUseTemplatepage(templatepage)"
          >
            <img class="fluid" :src="getPageThumbnail(templatepage)" @error="getPlaceholderThumbnail($event)" />
          </div>
        </div>
        <div
          class="section-scrollable"
          v-if="activeTab == 'magazines' && activeTabMagazinesContent == 'magazines'"
          key="magazines"
        >
          <div
            class="item"
            :class="'item-' + canvasVersion"
            v-for="(magazine, i) in magazines"
            :key="magazine.id"
            @click="showMagazine($event, i)"
          >
            <img class="fluid" :src="getCoverThumbnail(magazine)" @error="getPlaceholderThumbnail($event)" />
            <div class="pagecount">
              {{ magazine.pageCount }}
              {{ Lang.get('sidepanel.misc.pagecount') }}
            </div>
          </div>
        </div>
        <div
          class="section-scrollable"
          v-if="activeTab == 'magazines' && activeTabMagazinesContent == 'magazine'"
          key="magazine"
        >
          <div class="item item-back" :class="'item-' + canvasVersion" @click="showMagazines()">
            <div class="icon icon-chevron-left"></div>
            Back to overview
          </div>
          <div
            class="item"
            v-for="(magazinepage, i) in magazinepages"
            :key="magazinepage.id"
            :class="[{ 'item-cover': i == 0 }, 'item-' + canvasVersion]"
            @click="confirmUseMagazinepage(magazinepage)"
          >
            <img class="fluid" :src="getPageThumbnail(magazinepage)" @error="getPlaceholderThumbnail($event)" />
          </div>
        </div>
      </transition>
    </div>
    <mdb-modal size="md" centered :show="confirmUseTemplatepageVisible" class="modal-legacy">
      <mdb-modal-header>
        <mdb-modal-title>
          {{ Lang.get('modals.templates.use_template_page_header') }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        {{ Lang.get('modals.templates.use_template_page_body') }}
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="sec btn-med" @click.native="confirmUseTemplatepageVisible = false">{{
          Lang.get('modals.buttons.cancel')
        }}</mdb-btn>
        <mdb-btn color="pri btn-med" @click.native="useTemplatepage()">{{ Lang.get('modals.buttons.change') }}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal size="md" centered :show="confirmUseMagazinepageVisible" class="modal-legacy">
      <mdb-modal-header>
        <mdb-modal-title>
          {{ Lang.get('modals.templates.use_magazine_page_header') }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        {{ Lang.get('modals.templates.use_magazine_page_body') }}
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="sec btn-med" @click.native="confirmUseMagazinepageVisible = false">{{
          Lang.get('modals.buttons.cancel')
        }}</mdb-btn>
        <mdb-btn color="pri btn-med" @click.native="useMagazinepage()">{{ Lang.get('modals.buttons.change') }}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import resources from '../mixins/resources.js'
import Lang from '../utils/lang'

export default {
  mixins: [resources],
  data: function () {
    return {
      Lang: Lang,
      loading: false,
      activeTab: 'templates',
      activeTabTemplatesContent: 'templates',
      activeTabMagazinesContent: 'magazines',
      template: {},
      magazine: {},
      selectedTemplatepage: {},
      selectedMagazinepage: {},
      confirmUseTemplatepageVisible: false,
      confirmUseMagazinepageVisible: false,
    }
  },
  watch: {
    template: function () {
      if (this.template.hasOwnProperty('id')) {
        this.loading = true
        this.getTemplatepages(this.template.id)
      }
    },
    magazine: function () {
      if (this.magazine.hasOwnProperty('id')) {
        this.loading = true
        this.getMagazinepages(this.magazine.id)
      }
    },
    confirmUseTemplatepageVisible: function () {
      if (!this.confirmUseTemplatepageVisible) {
        this.$store.commit('setTemplatepageContent', '')
      }
    },
    confirmUseMagazinepageVisible: function () {
      if (!this.confirmUseMagazinepageVisible) {
        this.$store.commit('setMagazinepageContent', '')
      }
    },
  },
  computed: {
    templates() {
      return this.$store.getters.templates
    },
    templatepages() {
      return this.$store.getters.templatepages
    },
    templatepageContent() {
      return this.$store.getters.templatepageContent
    },
    magazines() {
      return this.$store.getters.magazines
    },
    magazinepages() {
      return this.$store.getters.magazinepages
    },
    magazinepageContent() {
      return this.$store.getters.magazinepageContent
    },
    canvasVersion() {
      return this.$store.getters.currentMagazine.canvasVersion
    },
  },
  methods: {
    showTemplate(event, i) {
      this.loading = true
      this.template = this.templates[i]
      this.activeTabTemplatesContent = 'template'
    },
    showTemplates(event, i) {
      this.template = {}
      this.$store.commit('setTemplatepages', [])
      this.activeTabTemplatesContent = 'templates'
    },
    confirmUseTemplatepage(templatepage) {
      this.selectedTemplatepage = templatepage
      this.confirmUseTemplatepageVisible = true
      this.getTemplatepageContent(this.template.id, this.selectedTemplatepage.id)
    },
    useTemplatepage() {
      this.$parent.loading = true
      this.$parent.$refs.fabric.setCanvasContent(this.templatepageContent)
      this.selectedTemplatepage = {}
      this.$store.commit('setTemplatepageContent', '')
      this.confirmUseTemplatepageVisible = false
    },
    showMagazine(event, i) {
      this.loading = true
      this.magazine = this.magazines[i]
      this.activeTabMagazinesContent = 'magazine'
    },
    showMagazines(event, i) {
      this.magazine = {}
      this.$store.commit('setMagazinepages', [])
      this.activeTabMagazinesContent = 'magazines'
    },
    confirmUseMagazinepage(magazinepage) {
      this.selectedMagazinepage = magazinepage
      this.confirmUseMagazinepageVisible = true
      this.getMagazinepageContent(this.magazine.id, this.selectedMagazinepage.id)
    },
    useMagazinepage() {
      this.$parent.loading = true
      this.$parent.$refs.fabric.setCanvasContent(this.magazinepageContent)
      this.selectedMagazinepage = {}
      this.$store.commit('setMagazinepageContent', '')
      this.confirmUseMagazinepageVisible = false
    },
    getCoverThumbnail(magazine) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_magazinepages/' +
        magazine.id +
        '/png_thumbnail/' +
        magazine.cover_id +
        '.png'
      )
    },
    getPageThumbnail(magazinepage) {
      return (
        'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' +
        process.env.VUE_APP_ENV +
        '_magazinepages/' +
        magazinepage.magazine_id +
        '/png_thumbnail/' +
        magazinepage.id +
        '.png'
      )
    },
    getPlaceholderThumbnail(e) {
      e.target.src = '/images/page-placeholder.png'
      e.target.className = 'placeholder'
    },
  },
  created() {
    bus.$on('templatepagesRetrieved', (id) => {
      this.loading = false
    })
    bus.$on('magazinepagesRetrieved', (id) => {
      this.loading = false
    })
    bus.$on('canvasInitialised', () => {
      this.$parent.loading = false
    })
  },
}
</script>
