<template>
  <div id="sub-header-bar" :class="{ 'sub-header-bar-spine': $parent.spineActive }" v-cloak>
    <div id="sub-header-bar-left" v-if="$parent.spineActive">
      <a class="btn btn-spine-close" v-on:click="$parent.$refs.fabric.switchSpreadPage('left')">
        <div class="icon icon-chevron-left"></div>
        {{ Lang.get('subheader.buttons.exit-spine-editor') }}
      </a>
    </div>
    <div id="sub-header-bar-left" v-if="!$parent.spineActive">
      <span
        v-for="subheaderBarItem in $parent.subheaderBarItems"
        :key="subheaderBarItem.name"
        class="d-inline-block"
        tabindex="0"
        v-b-tooltip.bottom="{
          title:
            subheaderBarItem.name === 'crop' &&
            $parent.getSubheaderBarItemClass(subheaderBarItem) &&
            warningImageOutsideCanvas
              ? Lang.get('canvas.warnings.image-outside-canvas')
              : '',
        }"
        title="Disabled tooltip"
      >
        <a
          class="btn btn-ter btn-sma"
          :class="$parent.getSubheaderBarItemClass(subheaderBarItem)"
          v-on:click="$parent.activateWorkspacebarItem(subheaderBarItem.name, $event)"
        >
          <div
            class="preview-swatch"
            v-if="
              subheaderBarItem.hasOwnProperty('swatch') &&
              $parent.currentSidePanelItem !== 'background' &&
              $parent.currentSidePanelItem !== 'background-edit' &&
              $parent.activeObjectType !== 'text'
            "
            :class="{
              'preview-swatch-empty': $parent.activeObject[subheaderBarItem.swatch] == null,
            }"
            :style="$parent.getSwatchStyle($parent.activeObject[subheaderBarItem.swatch])"
          ></div>
          <div
            class="preview-swatch"
            v-if="
              subheaderBarItem.hasOwnProperty('swatch') &&
              $parent.activeObjectType === 'text' &&
              subheaderBarItem.swatch === 'stroke' &&
              $parent.currentSidePanelItem !== 'background' &&
              $parent.currentSidePanelItem !== 'background-edit'
            "
            :class="{
              'preview-swatch-empty': $parent.activeObject[subheaderBarItem.swatch] == null,
            }"
            :style="$parent.getSwatchStyle($parent.activeObject[subheaderBarItem.swatch])"
          ></div>
          <div
            class="preview-swatch"
            v-if="
              subheaderBarItem.hasOwnProperty('swatch') &&
              $parent.activeObjectType === 'text' &&
              subheaderBarItem.swatch === 'fill' &&
              $parent.currentSidePanelItem !== 'background' &&
              $parent.currentSidePanelItem !== 'background-edit'
            "
            :class="{
              'preview-swatch-empty': $parent.activeObject[subheaderBarItem.swatch] == null,
            }"
            :style="$parent.getTextFillSwatchStyle($parent.activeObject)"
          ></div>
          <div
            class="preview-swatch"
            v-if="
              ($parent.currentSidePanelItem === 'background' || $parent.currentSidePanelItem === 'background-edit') &&
              subheaderBarItem.swatch === 'backgroundColor'
            "
            :style="$parent.getBackgroundColorSwatchStyle()"
          ></div>
          {{ Lang.get(subheaderBarItem.labelLangKey) }}

          <!-- <span class="crop-tooltip"> Test tooltip </span> -->
        </a>
      </span>
    </div>
    <div id="sub-header-bar-right">
      <help-menu :canvas-version="$store.getters.currentMagazine.canvasVersion"></help-menu>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'TopBar',
  data: function () {
    return { Lang: Lang, warningImageOutsideCanvas: false }
  },

  mounted() {
    bus.$on('warningImageOutsideCanvas', (value) => {
      this.warningImageOutsideCanvas = value
    })
  },
}
</script>
