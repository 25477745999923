var sections = [
  {
    index: 0,
    name: 'templates',
    navButton: {
      labelLangKey: 'sidepanel.items.templates',
      icon: 'icon-menu-template',
    },
  },
  {
    index: 1,
    name: 'background',
    navButton: {
      labelLangKey: 'sidepanel.items.background',
      icon: 'icon-menu-background',
    },
    subheaderBarItems: [
      {
        name: 'color',
        labelLangKey: 'subheader.items.color',
        swatch: 'backgroundColor',
      },
    ],
  },
  {
    index: 2,
    name: 'background-edit',
    activeNavButton: 'background',
    subheaderBarItems: [
      {
        name: 'color',
        labelLangKey: 'subheader.items.color',
        swatch: 'backgroundColor',
      },
    ],
  },
  {
    index: 3,
    name: 'text',
    navButton: {
      labelLangKey: 'sidepanel.items.text',
      icon: 'icon-menu-text',
    },
  },
  {
    index: 4,
    name: 'text-edit',
    activeNavButton: 'text',
    objectType: 'text',
    subheaderBarItems: [
      { name: 'text', labelLangKey: 'subheader.items.properties' },
      { name: 'color', labelLangKey: 'subheader.items.color', swatch: 'fill' },
      {
        name: 'border',
        labelLangKey: 'subheader.items.stroke',
        swatch: 'stroke',
      },
      { name: 'shadow', labelLangKey: 'subheader.items.shadow' },
    ],
  },
  {
    index: 5,
    name: 'elements',
    navButton: {
      labelLangKey: 'sidepanel.items.elements',
      icon: 'icon-menu-elements',
    },
  },
  {
    index: 6,
    name: 'shapes-edit',
    activeNavButton: 'elements',
    objectType: 'shape',
    subheaderBarItems: [
      { name: 'properties', labelLangKey: 'subheader.items.properties' },
      { name: 'color', labelLangKey: 'subheader.items.color', swatch: 'fill' },
      {
        name: 'border',
        labelLangKey: 'subheader.items.stroke',
        swatch: 'stroke',
      },
      { name: 'shadow', labelLangKey: 'subheader.items.shadow' },
    ],
  },
  {
    index: 7,
    name: 'placeholders-edit',
    activeNavButton: 'elements',
    objectType: 'placeholder',
    subheaderBarItems: [
      { name: 'properties', labelLangKey: 'subheader.items.properties' },
      { name: 'filters', labelLangKey: 'subheader.items.filters' },
      {
        name: 'border',
        labelLangKey: 'subheader.items.stroke',
        swatch: 'stroke',
      },
      { name: 'shadow', labelLangKey: 'subheader.items.shadow' },
    ],
  },
  {
    index: 8,
    name: 'images',
    navButton: {
      labelLangKey: 'sidepanel.items.images',
      icon: 'icon-folder',
    },
  },
  {
    index: 9,
    name: 'images-edit',
    activeNavButton: 'images',
    objectType: 'image',
    subheaderBarItems: [
      { name: 'properties', labelLangKey: 'subheader.items.properties' },
      { name: 'filters', labelLangKey: 'subheader.items.filters' },
      { name: 'crop', labelLangKey: 'subheader.items.crop' },
      {
        name: 'border',
        labelLangKey: 'subheader.items.stroke',
        swatch: 'stroke',
      },
      { name: 'shadow', labelLangKey: 'subheader.items.shadow' },
    ],
  },
  {
    index: 10,
    name: 'overview',
    canvasVersion: 'linda',
    navButton: {
      labelLangKey: 'sidepanel.items.overview',
      icon: 'icon-overview',
    },
  },
  {
    index: 11,
    name: 'overview',
    canvasVersion: 'glossy',
    navButton: {
      labelLangKey: 'sidepanel.items.overview',
      icon: 'icon-overview',
    },
  },
  {
    index: 12,
    name: 'spine',
    navButton: {
      labelLangKey: 'sidepanel.items.spine',
      icon: 'icon-portrait',
    },
  },
]

export default sections
