<template>
  <li :class="{ editing: editing }">
    <div class="spinner-wrapper" v-show="loading">
      <mdb-spinner small multicolor />
    </div>
    <div class="imagedirectory-icon" @click="openImageDirectory()">
      <div class="icon icon-folder"></div>
    </div>
    <div class="imagedirectory-title">
      <span v-show="!editing" @click="openImageDirectory()">{{
        imageDirectory.title
      }}</span>
      <input
        v-show="editing"
        type="text"
        v-model="title"
        ref="imageDirectoryTitle"
      />
    </div>
    <div class="imagedirectory-actions" v-show="!editing">
      <div
        class="icon icon-delete"
        @click="deleteImageDirectory()"
        v-if="isDeletable"
        v-b-tooltip.hover.top.v-secondary="{
          title: Lang.get('sidepanel.controls.imagedirectory-delete'),
        }"
      ></div>
      <div
        class="icon icon-edit2"
        @click="editing = true"
        v-b-tooltip.hover.top.v-secondary="{
          title: Lang.get('sidepanel.controls.imagedirectory-rename'),
        }"
      ></div>
      <div
        class="icon icon-upload"
        @click="openImageDirectory()"
        v-b-tooltip.hover.top.v-secondary="{
          title: Lang.get('sidepanel.controls.imagedirectory-open'),
        }"
      ></div>
    </div>
    <a class="btn" @click="updateImageDirectory()" v-show="editing">{{
      Lang.get('sidepanel.controls.imagedirectory-save')
    }}</a>
  </li>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  props: {
    imageDirectory: Object,
    index: Number,
  },
  data() {
    return {
      Lang: Lang,
      title: '',
      editing: false,
      loading: false,
      isDeletable: false,
    }
  },
  watch: {
    editing: function () {
      setTimeout(() => {
        if (this.editing) {
          this.$refs.imageDirectoryTitle.focus()
        }
      }, 100)
    },
  },
  computed: {
    magazine() {
      return this.$store.getters.currentMagazine
    },
  },
  methods: {
    updateImageDirectory() {
      this.loading = true
      this.$parent.updateImageDirectory(this.index, this.title)
    },
    deleteImageDirectory() {
      this.loading = true
      this.$parent.deleteImageDirectory(this.index)
    },
    openImageDirectory() {
      this.$parent.openImageDirectory(this.index)
    },
  },
  mounted() {
    this.title = this.imageDirectory.title
    this.isDeletable =
      parseInt(this.imageDirectory.id) !==
      parseInt(this.magazine.defaultImagedirectory_id)
  },
  created() {
    bus.$on('imageDirectoryUpdated', (id) => {
      if (this.imageDirectory.id == id) {
        this.editing = false
        this.loading = false
      }
    })
    bus.$on('imageDirectoryCreated', (id) => {
      if (this.imageDirectory.id == id) {
        this.editing = true
      }
    })
  },
}
</script>
