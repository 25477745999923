<template>
  <div>

    <!-- Presets -->
    <div class="filter-presets">
      <div class="filter-preset filter-preset-none" @click="usePreset('none')">
        <img src="/images/filters/none.png">
        <span class="name">{{ Lang.get('sidepanel.labels.filters.none') }}</span>
      </div>
      <div class="filter-preset filter-preset-grayscale" @click="usePreset('grayscale')">
        <img src="/images/filters/grayscale.png">
        <span class="name">{{ Lang.get('sidepanel.labels.filters.grayscale') }}</span>
      </div>
      <div class="filter-preset filter-preset-sepia" @click="usePreset('sepia')">
        <img src="/images/filters/sepia.png">
        <span class="name">{{ Lang.get('sidepanel.labels.filters.sepia') }}</span>
      </div>
      <div class="filter-preset filter-preset-vintage" @click="usePreset('vintage')">
        <img src="/images/filters/vintage.png">
        <span class="name">{{ Lang.get('sidepanel.labels.filters.vintage') }}</span>
      </div>
      <div class="filter-preset filter-preset-technicolour" @click="usePreset('technicolour')">
        <img src="/images/filters/technicolour.png">
        <span class="name">{{ Lang.get('sidepanel.labels.filters.technicolour') }}</span>
      </div>
      <div class="filter-preset filter-preset-brownie" @click="usePreset('brownie')">
        <img src="/images/filters/brownie.png">
        <span class="name">{{ Lang.get('sidepanel.labels.filters.brownie') }}</span>
      </div>
      <div class="filter-preset filter-preset-kodachrome" @click="usePreset('kodachrome')">
        <img src="/images/filters/kodachrome.png">
        <span class="name">{{ Lang.get('sidepanel.labels.filters.kodachrome') }}</span>
      </div>
      <div class="filter-preset filter-preset-polaroid" @click="usePreset('polaroid')">
        <img src="/images/filters/polaroid.png">
        <span class="name">{{ Lang.get('sidepanel.labels.filters.polaroid') }}</span>
      </div>
    </div>

    <!-- Brightness -->
    <span class="filter-slider-label">{{ Lang.get('sidepanel.labels.filters.brightness') }}</span>
    <input type="range" class="mb-3" min="-0.999855" max="0.999855" step="0.003921" v-model="brightness"></input>

    <!-- Contrast -->
    <span class="filter-slider-label">{{ Lang.get('sidepanel.labels.filters.contrast') }}</span>
    <input type="range" class="mb-3" min="-0.999855" max="0.999855" step="0.003921" v-model="contrast"></input>

    <!-- Hue -->
    <span class="filter-slider-label">{{ Lang.get('sidepanel.labels.filters.hue') }}</span>
    <input type="range" class="range-hue mb-3" min="-0.999855" max="0.999855" step="0.003921" v-model="hue"></input>

    <!-- Saturation -->
    <span class="filter-slider-label">{{ Lang.get('sidepanel.labels.filters.saturation') }}</span>
    <input type="range" class="mb-3" min="-0.999855" max="0.999855" step="0.003921" v-model="saturation"></input>

    <!-- Blur -->
    <span class="filter-slider-label">{{ Lang.get('sidepanel.labels.filters.blur') }}</span>
    <input type="range" class="mb-3" min="0" max="1" step="0.01" v-model="blur"></input>

  </div>
</template>

<script>
import { bus } from '../utils/bus';
import utilities from '../mixins/utilities.js';
import Lang from '../utils/lang';

export default {
  mixins: [utilities],
  data: function() {
    return {
      Lang: Lang,
      object: {},
      brightness: 0,
      contrast: 0,
      hue: 0,
      saturation: 0,
      blur: 0,
      filterList: ['brightness', 'contrast', 'hue', 'saturation', 'blur', 'grayscale', 'sepia', 'vintage', 'technicolour', 'brownie', 'kodachrome', 'polaroid'],
    };
  },
  watch: {
    object: function() {
      if (Object.keys(this.object).length > 0) {
        this.getProperties();
      }
    },
    brightness: function() {
      let filterIndex = this.filterList.indexOf('brightness');
      if (typeof this.object.filters[filterIndex] === 'undefined') {
        let filter = new fabric.Image.filters.Brightness({ brightness: this.brightness });
        this.setFilter(filterIndex, filter);
      }
      else {
        this.setFilterValue(filterIndex, 'brightness', this.brightness);
      }
    },
    contrast: function() {
      let filterIndex = this.filterList.indexOf('contrast');
      if (typeof this.object.filters[filterIndex] === 'undefined') {
        let filter = new fabric.Image.filters.Contrast({ contrast: this.contrast });
        this.setFilter(filterIndex, filter);
      }
      else {
        this.setFilterValue(filterIndex, 'contrast', this.contrast);
      }
    },
    hue: function() {
      let filterIndex = this.filterList.indexOf('hue');
      if (typeof this.object.filters[filterIndex] === 'undefined') {
        let filter = new fabric.Image.filters.HueRotation({ rotation: this.hue });
        this.setFilter(filterIndex, filter);
      }
      else {
        this.setFilterValue(filterIndex, 'rotation', this.hue);
      }
    },
    saturation: function() {
      let filterIndex = this.filterList.indexOf('saturation');
      if (typeof this.object.filters[filterIndex] === 'undefined') {
        let filter = new fabric.Image.filters.Saturation({ saturation: this.saturation });
        this.setFilter(filterIndex, filter);
      }
      else {
        this.setFilterValue(filterIndex, 'saturation', this.saturation);
      }
    },
    blur: function() {
      let filterIndex = this.filterList.indexOf('blur');
      if (typeof this.object.filters[filterIndex] === 'undefined') {
        let filter = new fabric.Image.filters.Blur({ blur: this.blur });
        this.setFilter(filterIndex, filter);
      }
      else {
        this.setFilterValue(filterIndex, 'blur', this.blur);
      }
    },
  },
  computed: { canvas() { return this.$store.getters.canvas; } },
  methods: {
    getProperties() {
      if (this.object.filters.length) {
        this.object.filters.map((filter) => {
          if (filter.hasOwnProperty('type')) {
            switch (filter.type) {
              case 'Brightness':
                this.brightness = filter.brightness;
                break;
              case 'Contrast':
                this.contrast = filter.contrast;
                break;
              case 'HueRotation':
                this.hue = filter.rotation;
                break;
              case 'Saturation':
                this.saturation = filter.saturation;
                break;
              case 'Blur':
                this.blur = filter.blur;
                break;
            }
          }
        });
      }
    },
    setFilter(filterIndex, filter) {
      this.object.filters[filterIndex] = filter;
      this.object.applyFilters();
      this.canvas.requestRenderAll();
    },
    setFilterValue(filterIndex, filterName, filterValue) {
      this.object.filters[filterIndex][filterName] = filterValue;
      this.object.applyFilters();
      this.canvas.requestRenderAll();
    },
    usePreset(preset) {
      this.object.filters = [];
      this.object.applyFilters();
      if (preset !== 'none') {
        var filterIndex = this.filterList.indexOf(preset);
        switch (preset) {
          case 'grayscale':
            var filter = new fabric.Image.filters.Grayscale();
            break;
          case 'sepia':
            var filter = new fabric.Image.filters.Sepia();
            break;
          case 'vintage':
            var filter = new fabric.Image.filters.Vintage();
            break;
          case 'technicolor':
            var filter = new fabric.Image.filters.Technicolor();
            break;
          case 'brownie':
            var filter = new fabric.Image.filters.Brownie();
            break;
          case 'kodachrome':
            var filter = new fabric.Image.filters.Kodachrome();
            break;
          case 'polaroid':
            var filter = new fabric.Image.filters.Polaroid();
            break;
        }
        this.object.filters[filterIndex] = filter;
        this.object.applyFilters();
      }
      this.canvas.requestRenderAll();
      this.canvas.fire('object:modified');
    },
    setObject() {
      let activeObject = this.canvas.getActiveObject();

      
      if(activeObject && activeObject.type === 'placeholder' && activeObject.hasImage) {
        this.object = activeObject.image;
      } else {
        this.object = activeObject;
      }
    }
  },
  mounted() {
    this.setObject();
    bus.$on('activeObjectTypeChanged', (activeObjectType) => {
      if(activeObjectType !== '') {
        this.setObject();
      } else {
          this.object = {};
      }
    });
  },
};
</script>
